import React, { useState } from "react";
import {
  FormControl,
  Grid,
  IconButton,
  TextField,
  Checkbox,
  Box,
} from "@mui/material";
import Select from "react-select";
import CloseIcon from "@mui/icons-material/Close";

const daysOptions = [
  { value: "Monday", label: "Monday" },
  { value: "Tuesday", label: "Tuesday" },
  { value: "Wednesday", label: "Wednesday" },
  { value: "Thursday", label: "Thursday" },
  { value: "Friday", label: "Friday" },
  { value: "Saturday", label: "Saturday" },
  { value: "Sunday", label: "Sunday" },
];

interface Timing {
  day: string;
  startTime: string;
  endTime: string;
}

interface TimingSelectProps {
  timings: any[];
  onTimingChange: (index: number, field: any, value: string) => void;
  onRemove: (index: number) => void;
  availableDays: string[];
  index: number;
  other?: boolean;
  isBusiness?: boolean;
}

const TimingSelect: React.FC<TimingSelectProps> = ({
  timings,
  onTimingChange,
  onRemove,
  availableDays,
  index,
  other,
  isBusiness = false,
}) => {
  const [isChecked, setIsChecked] = useState<boolean>(false);
  return (
    <Grid container spacing={2} sx={{ display: "flex", alignItems: "center" }}>
      <Grid item xs={12} sm={4}>
        <FormControl fullWidth>
          <Select
            value={daysOptions.find(
              (option) =>
                option.value.toLowerCase() === timings[index].day.toLowerCase()
            )}
            options={daysOptions.filter(
              (option) =>
                !availableDays.some(
                  (day) => day.toLowerCase() === option.value.toLowerCase()
                ) || option.value === timings[index].day
            )}
            onChange={(selectedOption) =>
              onTimingChange(index, "day", selectedOption?.value ?? "")
            }
            placeholder="Select day"
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={2}>
        <TextField
          label="Start Time"
          name="startTime"
          value={timings[index].startTime}
          onChange={(e) => onTimingChange(index, "startTime", e.target.value)}
          placeholder="Enter start time"
          fullWidth
          variant="outlined"
        />
      </Grid>
      <Grid item xs={12} sm={2}>
        <TextField
          label="End Time"
          name="endTime"
          value={timings[index].endTime}
          onChange={(e) => onTimingChange(index, "endTime", e.target.value)}
          placeholder="Enter end time"
          fullWidth
          variant="outlined"
        />
      </Grid>
      {
        <Grid item xs={12} sm={3} display={"flex"}>
          <TextField
            label="Other"
            name="other"
            value={isBusiness ? timings[index].others : timings[index].other}
            onChange={(e) =>
              onTimingChange(
                index,
                `${isBusiness ? "others" : "other"}`,
                e.target.value
              )
            }
            placeholder="Other values"
            fullWidth
            variant="outlined"
          />
          <Checkbox
            checked={isChecked}
            onChange={() => {
              if (!isChecked) {
                onTimingChange(
                  index,
                  `${isBusiness ? "others" : "other"}`,
                  "24 Hour Open"
                );
                setIsChecked(true);
              } else {
                setIsChecked(false);
              }
            }}
          />
          <Box>24 Hours Open</Box>
        </Grid>
      }
      <Grid item xs={12} sm={1}>
        <IconButton aria-label="delete" onClick={() => onRemove(index)}>
          <CloseIcon />
        </IconButton>
      </Grid>
    </Grid>
  );
};

export default TimingSelect;
