import React, { useEffect, useState } from "react";
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Typography,
  Link,
  CircularProgress,
} from "@mui/material";
import OverviewComponent from "./OverView";
import styles from "./BusinessProfile.module.css";
import ImageGallery from "./ImageGallery";
import { useQuery } from "@apollo/client";
import { GET_BUSINESS_PROFILE } from "src/graphql/query";
import backButton from "../../Images/backButton.svg";
import { ROUTES } from "src/constant";
import { useNavigate } from "react-router";
import Loader from "src/components/Loader";
const BusinessProfileView: React.FC = () => {
  const id = window.location.pathname.split("/")[2];
  const navigate = useNavigate();
  const [businessData, setBusinessData] = useState<any>(null);
  const { refetch: refetchBusinessProfile, loading } =
    useQuery(GET_BUSINESS_PROFILE);

  const fetchData = async () => {
    try {
      const res = await refetchBusinessProfile({ _id: id });
      setBusinessData(res?.data?.getBusinessProfile?.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [id, refetchBusinessProfile]);

  if (loading) {
    return <Loader />;
  }

  if (!businessData && !loading) {
    return <Box textAlign="center" mt={4}></Box>;
  }

  const {
    businessName,
    brandName,
    brandLogo,
    bgImage,
    overviews,
    subCategory,
    mobileNumber,
    timings,
    address,
    galleries,
    businessEmail,
  } = businessData;

  return (
    <Box p={4} maxWidth={1200} marginX="auto">
      <Box display={"flex"} justifyContent={"start"} alignItems={"center"}>
        {/* <img
          src={backButton}
          alt="backButton"
          height={25}
          width={25}
          style={{ marginRight: "12px", cursor: "pointer" }}
          onClick={() => navigate(-1)}
        /> */}
        <Typography variant="h4" className={styles.primaryColor}>
          {businessName}
        </Typography>
      </Box>
      <Card
        className={styles.card}
        sx={{ mb: 4, boxShadow: "0 4px 20px rgba(0,0,0,0.1)", borderRadius: 3 }}
      >
        <CardMedia
          component="img"
          height="300"
          image={bgImage || "/default-bg.jpg"}
          alt={businessName}
          sx={{
            objectFit: "cover",
            borderRadius: "3px 3px 0 0",
          }}
        />
        <CardContent sx={{ p: 3 }}>
          <Typography
            variant="h4"
            gutterBottom
            sx={{ fontWeight: 700, color: "#1976d2" }}
          >
            {businessName}
          </Typography>
          <Typography variant="h6" gutterBottom sx={{ color: "#555" }}>
            {brandName}
          </Typography>
          <Box display="flex" alignItems="center" mb={2}>
            <Box
              height={80}
              width={80}
              mr={3}
              sx={{
                borderRadius: "50%",
                overflow: "hidden",
                border: "2px solid #1976d2",
              }}
            >
              <CardMedia
                component="img"
                image={brandLogo || "/default-logo.png"}
                alt={brandName}
                sx={{ width: "100%", height: "100%", objectFit: "cover" }}
              />
            </Box>
            <Box>
              <Typography
                variant="body2"
                sx={{ fontSize: "1rem", color: "#333" }}
              >
                {subCategory}
              </Typography>
              <Typography
                variant="body2"
                sx={{ fontSize: "1rem", color: "#555" }}
              >
                Mobile: {mobileNumber}
              </Typography>
              {businessEmail && (
                <Typography
                  variant="body2"
                  sx={{ fontSize: "1rem", color: "#555" }}
                >
                  Email: {businessEmail}
                </Typography>
              )}
            </Box>
          </Box>
        </CardContent>
      </Card>

      {galleries?.length > 0 && (
        <Box mb={4}>
          <Typography
            variant="h5"
            gutterBottom
            sx={{ fontWeight: 600, color: "#1976d2" }}
          >
            Gallery
          </Typography>
          <ImageGallery galleries={galleries} />
        </Box>
      )}

      <Box mb={4}>
        <Typography
          variant="h5"
          gutterBottom
          sx={{ fontWeight: 600, color: "#1976d2" }}
        >
          Timings
        </Typography>
        <Grid container spacing={3}>
          {timings[0]?.timings?.map((timing: any, index: number) => (
            <Grid item xs={12} sm={6} key={timing.day + index}>
              <Card
                variant="outlined"
                sx={{
                  borderRadius: 2,
                  boxShadow: "0 2px 10px rgba(0,0,0,0.05)",
                }}
              >
                <CardContent>
                  <Typography variant="h6" sx={{ color: "#333" }}>
                    {timing.day}
                  </Typography>
                  <Typography variant="body2" sx={{ color: "#777" }}>
                    {timing.startTime} - {timing.endTime}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>

      {address?.addressses?.length > 0 && (
        <Box mb={4}>
          <Typography
            variant="h5"
            gutterBottom
            sx={{ fontWeight: 600, color: "#1976d2" }}
          >
            Address
          </Typography>
          {address.addressses.map((addr: any, index: number) => (
            <Box key={index} mb={2}>
              <Typography
                variant="body1"
                sx={{ fontSize: "1rem", color: "#555" }}
              >
                {addr.address}
              </Typography>
              <Link
                href={addr.googleMapLink}
                target="_blank"
                rel="noopener"
                sx={{ color: "#1976d2", fontWeight: 500, fontSize: "1rem" }}
              >
                View on Google Maps
              </Link>
            </Box>
          ))}
        </Box>
      )}

      <Box mb={4}>
        <Typography
          variant="h4"
          gutterBottom
          sx={{ fontWeight: 700, color: "#1976d2" }}
        >
          Overview
        </Typography>
        <OverviewComponent overview={overviews} />
      </Box>
    </Box>
  );
};

export default BusinessProfileView;
