import React, { useState } from "react";
import { Box, IconButton, Menu, MenuItem, Tooltip } from "@mui/material";
import DeleteSweepIcon from "@mui/icons-material/DeleteSweep";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import MoreVertIcon from "@mui/icons-material/MoreVert";

interface ActionMenuProps {
  isSuperAdmin: boolean;
  setOpenDeleteMultipleModel: (open: boolean) => void;
  setOpenDeleteAllModel: (open: boolean) => void;
  setImageModal?: (open: boolean) => void;
  setExcelModel?: (open: boolean) => void;
  type?: string;
}

function ActionMenu({
  isSuperAdmin,
  setOpenDeleteMultipleModel,
  setOpenDeleteAllModel,
  setImageModal,
  setExcelModel,
  type,
}: ActionMenuProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box>
      <Tooltip title="Actions">
        <IconButton onClick={handleMenuOpen}>
          <MoreVertIcon />
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        {isSuperAdmin && (
          <>
            <MenuItem
              onClick={() => {
                setOpenDeleteMultipleModel(true);
                handleMenuClose();
              }}
            >
              <IconButton style={{ color: "#ff0059" }}>
                <DeleteSweepIcon fontSize="small" />
              </IconButton>
              Multiple Delete
            </MenuItem>
            <MenuItem
              onClick={() => {
                setOpenDeleteAllModel(true);
                handleMenuClose();
              }}
            >
              <IconButton style={{ color: "#ff0000" }}>
                <DeleteForeverIcon fontSize="small" />
              </IconButton>
              All Delete
            </MenuItem>
          </>
        )}
        {type && type === "business" && (
          <>
            <MenuItem
              onClick={() => {
                setImageModal && setImageModal(true);
                handleMenuClose();
              }}
            >
              <IconButton>
                <DriveFolderUploadIcon fontSize="small" />
              </IconButton>
              Upload Image Excel
            </MenuItem>
            <MenuItem
              onClick={() => {
                setExcelModel && setExcelModel(true);
                handleMenuClose();
              }}
            >
              <IconButton>
                <UploadFileIcon fontSize="small" />
              </IconButton>
              Upload Excel
            </MenuItem>
          </>
        )}
      </Menu>
    </Box>
  );
}

export default ActionMenu;
