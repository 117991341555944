import React from "react";
import { TextField, Box, Grid, Typography } from "@mui/material";
import PincodeSelectDropDown from "./PincodeSelectDropDown";
import { SingleValue } from "react-select";
import { PincodeOption } from "./Utils";

export interface Address {
  address_1: string;
  address_2: string;
  pinCode: string;
  location: string;
  landmark: string;
}

interface AddressInputProps {
  address: Address;
  setAddress: React.Dispatch<React.SetStateAction<{ address: Address }>>;
  error?: string;
  setValues: React.Dispatch<React.SetStateAction<any>>;
  pincode: string;
}

const AddressInput: React.FC<AddressInputProps> = ({
  address,
  setAddress,
  error,
  setValues,
  pincode,
}) => {
  const handleAddressChange = (field: keyof Address, value: string) => {
    setAddress((prev) => ({
      ...prev,
      address: {
        ...prev?.address,
        [field]: value,
      },
    }));
  };

  return (
    <Box>
      <Typography variant="subtitle1">Address:</Typography>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            label="Address Line 1"
            value={address?.address_1}
            onChange={(e) => handleAddressChange("address_1", e.target.value)}
            fullWidth
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Address Line 2"
            value={address?.address_2}
            onChange={(e) => handleAddressChange("address_2", e.target.value)}
            fullWidth
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Pincode"
            value={pincode}
            onChange={(e) =>
              setValues((prev: any) => ({
                ...prev,
                pincode: e.target.value,
              }))
            }
            fullWidth
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Location"
            value={address?.location}
            onChange={(e) => handleAddressChange("location", e.target.value)}
            fullWidth
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Landmark"
            value={address?.landmark}
            onChange={(e) => handleAddressChange("landmark", e.target.value)}
            fullWidth
            variant="outlined"
          />
        </Grid>
      </Grid>
      {!!error && <Typography color="error">{error}</Typography>}
    </Box>
  );
};

export default AddressInput;
