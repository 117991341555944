import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Box,
  Chip,
} from "@mui/material";
import { Business } from "./types";
import CustomButton from "src/components/CustomButton";

// Function to create an image from initials
export const createInitialsImage = (name: any) => {
  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");

  // Get initials
  const initials = name
    .split(" ")
    .map((word: any) => word[0])
    .join("")
    .toUpperCase();

  // Set canvas dimensions
  canvas.width = 500;
  canvas.height = 150;

  if (context) {
    // Fill canvas background
    context.fillStyle = "#007BFF"; // Change this to your desired background color
    context?.fillRect(0, 0, canvas.width, canvas.height);

    // Set text properties
    context.fillStyle = "#FFFFFF"; // Change this to your desired text color
    context.font = "bold 60px Arial"; // Change font and size as needed
    context.textAlign = "center";
    context.textBaseline = "middle";

    // Draw initials on canvas
    context.fillText(initials, canvas.width / 2, canvas.height / 2);
  }

  // Convert canvas to data URL
  return canvas.toDataURL();
};

interface BusinessCardProps {
  business: Business;
  onViewDetails: (business: Business) => void;
}

const BusinessCard: React.FC<BusinessCardProps> = ({
  business,
  onViewDetails,
}) => {
  const [imageSrc, setImageSrc] = useState("");

  useEffect(() => {
    if (business?.brandLogo) {
      setImageSrc(business.brandLogo);
    } else if (business?.businessName) {
      setImageSrc(createInitialsImage(business.businessName));
    } else {
      setImageSrc(`http://placehold.it/150x150/${business?.businessName}`);
    }
  }, [business]);

  return (
    <Card
      sx={{
        width: 300,
        height: "auto",
        margin: 2,
        boxShadow: 3,
        transition: "transform 0.2s",
        "&:hover": {
          transform: "scale(1.05)",
        },
        borderRadius: 8,
      }}
    >
      <CardMedia
        component="img"
        height={140}
        image={imageSrc}
        alt={`${business?.businessName} Logo`}
        sx={{
          objectFit: "cover",
          borderTopLeftRadius: 8,
          borderTopRightRadius: 8,
        }}
      />
      <CardContent>
        <Typography
          gutterBottom
          variant="h6"
          component="div"
          sx={{ fontWeight: "bold" }}
        >
          {business.businessName}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          <strong>Contact:</strong> {business.mobileNumber}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          <strong>Status:</strong> {business.status}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          <strong>Rating:</strong> {business.averageRating} ⭐
        </Typography>
        {business.status && (
          <Chip
            label={business.status}
            color={business.status === "Approved" ? "success" : "warning"}
            sx={{ mt: 1 }}
          />
        )}
        <Box mt={2}>
          <CustomButton
            variant="contained"
            color="primary"
            fullWidth
            onClick={() => onViewDetails(business)}
            sx={{
              borderRadius: 5,
            }}
          >
            View Details
          </CustomButton>
        </Box>
      </CardContent>
    </Card>
  );
};

export default BusinessCard;
