import React from "react";
import {
  Dialog,
  DialogActions,
  DialogTitle,
  Box,
  FormControl,
  Typography,
} from "@mui/material";
import CustomButton from "src/components/CustomButton";
import CategorySelectDropDown from "src/components/Common/CategorySelectDropDown";
import SubCategoryDropDown from "src/components/Common/SubCategoryDropDown";

interface CategoryOption {
  value: string;
  label: string;
}
interface SubCategoryOption {
  value: string;
  label: string;
}

interface ModalProps {
  open: boolean;
  handleClose: () => void;
  selectedCategory?: string;
  selectedSubCategory?: string;
  setSelectedCategory?: (category: string) => void;
  setSelectedSubCategory?: (subCategory: string) => void;
  handleSave?: () => void;
  isEditMode?: boolean;
}

const Modal: React.FC<ModalProps> = ({
  open,
  handleClose,
  selectedCategory = "",
  selectedSubCategory = "",
  setSelectedCategory,
  setSelectedSubCategory,
  handleSave,
  isEditMode = false,
}) => {
  const handleCategoryChange = (selectedOption: CategoryOption | null) => {
    if (setSelectedCategory) {
      setSelectedCategory(selectedOption?.value || "");
    }
    if (setSelectedSubCategory) {
      setSelectedSubCategory("");
    }
  };

  const handleSubCategoryChange = (
    selectedOption: SubCategoryOption | null
  ) => {
    if (setSelectedSubCategory) {
      setSelectedSubCategory(selectedOption?.value || "");
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        <Typography variant="h6" sx={{ color: "#00C5B9", textAlign: "center" }}>
          {isEditMode ? "Edit Entry" : "Create New Entry"}
        </Typography>
      </DialogTitle>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
          p: 3,
          borderRadius: "8px",
          height: "300px",
        }}
      >
        <FormControl fullWidth sx={{ zIndex: "99999" }}>
          <CategorySelectDropDown
            selectedCategory={selectedCategory}
            handleCategoryChange={handleCategoryChange}
          />
        </FormControl>

        <FormControl fullWidth sx={{ zIndex: "9999" }}>
          <SubCategoryDropDown
            handleSubCategoryChange={handleSubCategoryChange}
            selectedCategory={selectedCategory}
            selectedSubCategory={selectedSubCategory}
          />
        </FormControl>
      </Box>

      <DialogActions sx={{ p: 3, justifyContent: "space-between" }}>
        <CustomButton onClick={handleClose} variant="outlined">
          Cancel
        </CustomButton>
        <CustomButton onClick={handleSave} variant="contained">
          {isEditMode ? "Update" : "Save"}
        </CustomButton>
      </DialogActions>
    </Dialog>
  );
};

export default Modal;
