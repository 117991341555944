import { useQuery } from "@apollo/client";
import {
  Box,
  Container,
  Divider,
  Link,
  Typography,
  Grid,
  Paper,
} from "@mui/material";
import { useEffect, useState } from "react";
import ErrorComponent from "src/components/ErrorComponent";
import Loader from "src/components/Loader";
import { GET_BUSINESS_BY_CODE } from "src/graphql/query";
import backButton from "../../Images/backButton.svg";
import { useNavigate } from "react-router";
import { ROUTES } from "src/constant";

function BusinessSuperDetails() {
  const id =
    window?.location?.pathname &&
    window?.location?.pathname?.split("/")?.slice(2, 3)[0];

  const { loading, error, data } = useQuery(GET_BUSINESS_BY_CODE, {
    variables: { code: id },
    skip: !id,
  });

  const [moredetails, setMoredetails] = useState<any>(null);
  const navigate = useNavigate();
  useEffect(() => {
    if (data) {
      setMoredetails(
        data.getBusinessProfilebyCode?.data?.overviews?.[0]?.otherOptions ?? []
      );
    }
  }, [data]);

  if (loading) return <Loader />;
  if (error) return <ErrorComponent />;

  const {
    Name = ["Not available"],
    Full_Address = ["Not available"],
    City = ["Not available"],
    State = ["Not available"],
    Zip = ["Not available"],
    Phone_1 = ["Not available"],
    Website = ["Not available"],
    Reviews_count = [0],
    Average_rating = [0],
    Hours = ["Not available"],
    Image_URL = ["https://via.placeholder.com/150"],
    GMB_URL = ["#"],
    Review_URL = ["#"],
    Description = ["No description available."],
    Crowd = ["Unknown"],
    Pricing = ["Not available"],
    Sub_Title = ["Not available"],
    Summary = ["Not available"],
    Domain = ["Not available"],
    First_category = ["Not available"],
    Second_category = ["Not available"],
    Claimed_google_my_business = ["Not available"],
    Price = ["Not available"],
    Business_Status = moredetails?.["Business Status"] || ["Not available"],
    Latitude = ["Not available"],
    Longitude = ["Not available"],
    Coordinates = ["Not available"],
    Plus_Code = ["Not available"],
    Place_ID = ["Not available"],
    Menu_Link = moredetails?.["Menu Link"] || ["Not available"],
    CID = ["Not available"],
    Google_Knowledge_URL = ["#"],
    Kgmid = ["Not available"],
    Uuid = ["Not available"],
    Favicon = ["https://via.placeholder.com/150"],
    Meta_Description = moredetails?.["Meta Description"] || ["Not available"],
    Meta_Keywords = moredetails?.["Meta Keywords"] || ["Not available"],
  } = moredetails || {};

  return (
    <Container maxWidth="lg" sx={{ padding: 4 }}>
      <Box display={"flex"} alignItems={"center"}>
        <img
          src={backButton}
          alt="backButton"
          height={25}
          width={25}
          style={{ marginRight: "12px", cursor: "pointer" }}
          onClick={() => navigate(ROUTES.BUSINESS)}
        />
        <Typography variant="h4" gutterBottom>
          {Name[0]}
        </Typography>
      </Box>

      <Box display="flex" alignItems="center" mb={2}>
        <img
          src={Image_URL[0]}
          alt={Name[0]}
          style={{
            width: 150,
            height: 150,
            marginRight: 16,
            borderRadius: "8px",
            boxShadow: "0 2px 8px rgba(0, 0, 0, 0.2)",
          }}
        />
        <Typography variant="body1">
          {Full_Address[0]}, {City[0]}, {State[0]} {Zip[0]}
        </Typography>
      </Box>

      <Divider sx={{ margin: "16px 0" }} />

      <Typography variant="h5" gutterBottom>
        Overview
      </Typography>
      <Typography variant="body1" paragraph>
        {Description[0]}
      </Typography>

      <Grid container spacing={2} sx={{ mb: 4 }}>
        <Grid item xs={12} sm={6}>
          <Paper elevation={3} sx={{ padding: 2 }}>
            <Typography variant="body1">
              <strong>Phone:</strong> {Phone_1[0]}
            </Typography>
            <Typography variant="body1">
              <strong>Website:</strong>{" "}
              {Website[0] ? (
                <Link
                  href={Website[0]}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {Website[0]}
                </Link>
              ) : (
                "Not available"
              )}
            </Typography>
            <Typography variant="body1">
              <strong>Reviews:</strong> {Reviews_count[0]} ({Average_rating[0]}{" "}
              ★)
            </Typography>
            <Typography variant="body1">
              <strong>Crowd Level:</strong> {Crowd[0]}
            </Typography>
            <Typography variant="body1">
              <strong>Business Status:</strong> {Business_Status[0]}
            </Typography>
            <Typography variant="body1">
              <strong>Claimed Google My Business:</strong>{" "}
              {Claimed_google_my_business[0]}
            </Typography>
            <Typography variant="body1">
              <strong>Price Range:</strong> {Price[0]}
            </Typography>
          </Paper>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Paper elevation={3} sx={{ padding: 2 }}>
            <Typography variant="h6" gutterBottom>
              Additional Information
            </Typography>
            <Typography variant="body1">
              <strong>Sub Title:</strong> {Sub_Title}
            </Typography>
            <Typography variant="body1">
              <strong>Summary:</strong> {Summary}
            </Typography>
            <Typography variant="body1">
              <strong>Domain:</strong> {Domain}
            </Typography>
            <Typography variant="body1">
              <strong>First Category:</strong> {First_category}
            </Typography>
            <Typography variant="body1">
              <strong>Second Category:</strong> {Second_category}
            </Typography>
            <Typography variant="body1">
              <strong>Latitude:</strong> {Latitude}
            </Typography>
            <Typography variant="body1">
              <strong>Longitude:</strong> {Longitude}
            </Typography>
            <Typography variant="body1">
              <strong>Place ID:</strong> {Place_ID}
            </Typography>
            <Typography variant="body1">
              <strong>Plus Code:</strong> {Plus_Code}
            </Typography>
            <Typography variant="body1">
              <strong>CID:</strong> {CID}
            </Typography>
            <Typography variant="body1">
              <strong>Google Knowledge URL:</strong>{" "}
              <Link
                href={Google_Knowledge_URL}
                target="_blank"
                rel="noopener noreferrer"
              >
                More Info
              </Link>
            </Typography>
          </Paper>
        </Grid>
      </Grid>

      <Divider sx={{ margin: "16px 0" }} />

      <Typography variant="h5" gutterBottom>
        Useful Links
      </Typography>
      <Box display="flex" flexDirection="column">
        <Link href={GMB_URL[0]} target="_blank" rel="noopener noreferrer">
          Google My Business
        </Link>
        <Link href={Review_URL[0]} target="_blank" rel="noopener noreferrer">
          Read Reviews
        </Link>
        {Menu_Link[0] && (
          <Link href={Menu_Link[0]} target="_blank" rel="noopener noreferrer">
            View Menu
          </Link>
        )}
      </Box>
    </Container>
  );
}

export default BusinessSuperDetails;
