import React, { useState, useEffect, useRef } from "react";
import { TextField, Box, IconButton, Typography, Tooltip } from "@mui/material";
import { useMutation } from "@apollo/client";
import { ADD_PINCODE_MUTATION, UPDATE_PINCODE } from "src/graphql/mutations";
import CustomButton from "src/components/CustomButton";
import { toast } from "react-toastify";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import * as yup from "yup";
import backButton from "../../Images/backButton.svg";
import { uploadImage } from "src/components/Common/Utils";
import { PincodePageProps } from "./pincode";

const validationSchema = yup.object().shape({
  areaName: yup.string().required("Area Name is required"),
  latitude: yup
    .number()
    .required("Latitude is required")
    .typeError("Latitude must be a number"),
  longitude: yup
    .number()
    .required("Longitude is required")
    .typeError("Longitude must be a number"),
});

const PincodeModal: React.FC<PincodePageProps> = ({
  refetchPincodes,
  handleClose,
  initialPinCodeObj = {
    areaName: "",
    pinCode: [],
    metaTitle: "",
    metaDescription: "",
    metaKeywords: "",
    imageUrl: "",
    latitude: "",
    longitude: "",
    polygonCoordinates: [],
  },
  pinCodeObj = {
    areaName: "",
    metaTitle: "",
    metaDescription: "",
    metaKeywords: "",
    imageUrl: "",
    pinCode: [],
    latitude: "",
    longitude: "",
    polygonCoordinates: [],
    _id: "",
    __typename: "",
  },
  setPinCodeObj = () => {},
  isEditPincode = false,
}) => {
  const [errors, setErrors] = useState<any>({});
  const [addPinCodeMutation] = useMutation(ADD_PINCODE_MUTATION);
  const [updatePinCodeMutation] = useMutation(UPDATE_PINCODE);
  const [previewImage, setPreviewImage] = useState<string | null>(null);
  const [imageFile, setImageFile] = useState<File | null>(null);
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (isEditPincode && pinCodeObj) {
      setPinCodeObj(pinCodeObj);
      setPreviewImage(pinCodeObj?.imageUrl ?? null);
      if (pinCodeObj?.coordinates) {
        setPinCodeObj((prev: any) => ({
          ...prev,
          latitude: pinCodeObj?.coordinates[0].latitude,
          longitude: pinCodeObj?.coordinates[0].longitude,
        }));
      }

      if (pinCodeObj?.location?.coordinates) {
        const formattedCoordinates = pinCodeObj?.location?.coordinates[0]?.map(
          ([longitude, latitude]: [number, number]) => ({ longitude, latitude })
        );
        setPinCodeObj((prev: any) => ({
          ...prev,
          polygonCoordinates: formattedCoordinates,
        }));
      }
    } else {
      setPinCodeObj(initialPinCodeObj);
    }
  }, []);

  const handlePolygonChange = (index: any, coord: any, type: any) => {
    const newPolygon = [...pinCodeObj?.polygonCoordinates] as any;
    newPolygon[index][type] = coord;
    setPinCodeObj((prev: any) => ({
      ...prev,
      polygonCoordinates: newPolygon,
    }));
  };

  const addPolygonPoint = () => {
    setPinCodeObj((prev: any) => ({
      ...prev,
      polygonCoordinates: [
        ...(prev?.polygonCoordinates || []),
        { longitude: "", latitude: "" },
      ],
    }));
  };

  const removePolygonPoint = (index: number) => {
    const newPolygon = pinCodeObj?.polygonCoordinates?.filter(
      (_, i) => i !== index
    );
    setPinCodeObj((prev: any) => ({
      ...prev,
      polygonCoordinates: newPolygon,
    }));
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files ? e.target.files[0] : null;
    setImageFile(file);
    setPreviewImage(file ? URL.createObjectURL(file) : null);
  };

  const handleImageRemove = () => {
    setPinCodeObj((prev: any) => ({ ...prev, imageUrl: "" }));
    setImageFile(null);
    setPreviewImage(null);
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const coordinates = pinCodeObj?.polygonCoordinates?.map((point: any) => [
      parseFloat(point.longitude),
      parseFloat(point.latitude),
    ]);

    if (coordinates?.length > 0) {
      const firstPoint = coordinates[0];
      coordinates.push(firstPoint);
    }

    try {
      let imageUrl = pinCodeObj?.imageUrl;
      // Perform validation before submitting
      await validationSchema.validate(pinCodeObj, { abortEarly: false });
      setErrors({});

      if (imageFile) {
        const response = await uploadImage(
          imageFile,
          "pincode",
          pinCodeObj?.areaName
        );
        if (response?.uploadImage?.url) {
          imageUrl = response?.uploadImage?.url;
        } else {
          throw new Error("Image upload failed.");
        }
      }

      setPinCodeObj((prev: any) => ({ ...prev, imageUrl: imageUrl }));

      const { _id, __typename, polygonCoordinates, ...restData } = pinCodeObj;
      const { data } = isEditPincode
        ? await updatePinCodeMutation({
            variables: {
              _id: _id,
              input: {
                ...restData,
                imageUrl,
                location: { type: "Polygon", coordinates: [coordinates] },
              },
            },
          })
        : await addPinCodeMutation({
            variables: {
              input: {
                ...restData,
                imageUrl,
                location: { type: "Polygon", coordinates: [coordinates] },
              },
            },
          });

      refetchPincodes();
      if (
        data?.addPinCode?.statusCode === 200 ||
        data?.updatePinCode?.statusCode === 200
      ) {
        toast.success(
          data?.addPinCode?.message ||
            data?.updatePinCode?.message ||
            "Pincode saved successfully!"
        );
        setPinCodeObj(initialPinCodeObj);
        handleClose();
      }
    } catch (err: any) {
      // Handle errors properly
      if (err?.inner) {
        const validationErrors: any = {};
        err.inner.forEach((validationError: any) => {
          validationErrors[validationError.path] = validationError.message;
        });
        setErrors(validationErrors);
      } else {
        toast.error(err?.message || "An unexpected error occurred.");
      }
    }
  };

  return (
    <Box padding={4}>
      <Box style={{ display: "flex", alignItems: "center" }}>
        <img
          src={backButton}
          alt="backButton"
          height={25}
          width={25}
          style={{ marginRight: "12px", cursor: "pointer" }}
          onClick={handleClose}
        />
        <Typography
          variant="h4"
          gutterBottom
          style={{ color: "#00C5B9" }}
          marginTop={2}
        >
          {isEditPincode ? "Update Pincode" : "Create Pincode"}
        </Typography>
      </Box>

      <Box>
        <TextField
          name="areaName"
          margin="dense"
          label="Area Name"
          fullWidth
          required
          value={pinCodeObj?.areaName || ""}
          onChange={(e) =>
            setPinCodeObj({ ...pinCodeObj, areaName: e.target.value })
          }
          error={!!errors.areaName}
          helperText={errors.areaName}
        />
        <TextField
          name="metaTitle"
          margin="dense"
          label="Meta Title"
          fullWidth
          value={pinCodeObj?.metaTitle || ""}
          onChange={(e) =>
            setPinCodeObj({ ...pinCodeObj, metaTitle: e.target.value })
          }
        />
        <TextField
          name="metaDescription"
          margin="dense"
          label="Meta Description"
          fullWidth
          value={pinCodeObj?.metaDescription || ""}
          onChange={(e) =>
            setPinCodeObj({ ...pinCodeObj, metaDescription: e.target.value })
          }
        />
        <TextField
          name="metaKeywords"
          margin="dense"
          label="Meta Keywords"
          fullWidth
          value={pinCodeObj?.metaKeywords || ""}
          onChange={(e) =>
            setPinCodeObj({ ...pinCodeObj, metaKeywords: e.target.value })
          }
        />
        <TextField
          name="latitude"
          margin="dense"
          label="Latitude"
          fullWidth
          required
          value={pinCodeObj?.latitude || ""}
          onChange={(e) =>
            setPinCodeObj({ ...pinCodeObj, latitude: e.target.value })
          }
          error={!!errors.latitude}
          helperText={errors.latitude}
        />
        <TextField
          name="longitude"
          margin="dense"
          label="Longitude"
          fullWidth
          required
          value={pinCodeObj?.longitude || ""}
          onChange={(e) =>
            setPinCodeObj({ ...pinCodeObj, longitude: e.target.value })
          }
          error={!!errors.longitude}
          helperText={errors.longitude}
        />
        <Box mt={3} display="flex" alignItems="center" flexDirection="column">
          <Typography variant="subtitle1" fontWeight={500} mb={2}>
            Upload Image
          </Typography>

          {/* Tooltip for better UX guidance */}
          <Tooltip title="Click to upload or drag and drop an image" arrow>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              width="100%"
              maxWidth="300px"
              height="200px"
              border="2px dashed #B0BEC5"
              borderRadius="8px"
              p={3}
              onClick={() => fileInputRef.current?.click()}
              sx={{
                "&:hover": {
                  backgroundColor: "#f7f7f7",
                  cursor: "pointer",
                },
              }}
            >
              <Typography variant="body2" color="textSecondary">
                {previewImage
                  ? "Change Image"
                  : "Drag and Drop or Click to Upload Image"}
              </Typography>

              {/* File input hidden, triggered by clicking the box */}
              <input
                type="file"
                accept="image/*"
                ref={fileInputRef}
                onChange={handleFileChange}
                style={{ display: "none" }}
              />
            </Box>
          </Tooltip>

          {/* Image preview if uploaded */}
          {previewImage && (
            <Box display="flex" alignItems="center" mt={2}>
              <img
                src={previewImage}
                alt="Preview"
                width="150"
                height="150"
                style={{
                  borderRadius: "10px",
                  objectFit: "cover",
                  border: "1px solid #B0BEC5",
                  boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                }}
              />
              <IconButton
                onClick={handleImageRemove}
                color="error"
                sx={{ ml: 2 }}
              >
                <RemoveCircleIcon />
              </IconButton>
            </Box>
          )}

          {/* Error message for image upload */}
          {!!errors?.imageUrl && (
            <Typography color="error" variant="subtitle2" mt={1}>
              {errors?.imageUrl}
            </Typography>
          )}
        </Box>
        <Box
          mt={2}
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Typography fontWeight={500}>Polygon Coordinates</Typography>
          {pinCodeObj?.polygonCoordinates?.map((point: any, index: number) => (
            <Box display="flex" gap={2} key={index} alignItems="center" my={2}>
              <TextField
                label="Longitude"
                value={point.longitude || ""}
                onChange={(e) =>
                  handlePolygonChange(index, e.target.value, "longitude")
                }
                margin="dense"
              />
              <TextField
                label="Latitude"
                value={point.latitude || ""}
                onChange={(e) =>
                  handlePolygonChange(index, e.target.value, "latitude")
                }
                margin="dense"
              />
              <Box>
                <CustomButton
                  onClick={() => removePolygonPoint(index)}
                  variant="outlined"
                  color="error"
                >
                  Remove
                </CustomButton>
              </Box>
            </Box>
          ))}
          <Box>
            <CustomButton onClick={addPolygonPoint} variant="outlined">
              Add Polygon Point
            </CustomButton>
          </Box>
        </Box>
      </Box>

      <Box display="flex" justifyContent="flex-end" mt={4}>
        <CustomButton
          onClick={handleSubmit}
          variant="contained"
          color="primary"
        >
          {isEditPincode ? "Update" : "Create"}
        </CustomButton>
      </Box>
    </Box>
  );
};

export default PincodeModal;
