import React, { useEffect, useState, useCallback } from "react";
import {
  Box,
  TextField,
  Typography,
  Divider,
  IconButton,
  Checkbox,
  Tooltip,
  InputAdornment,
} from "@mui/material";
import { useQuery, useMutation } from "@apollo/client";
import { debounce } from "src/utils/debounce";
import CustomTable from "src/components/CustomTable";
import Loader from "src/components/Loader";
import ErrorComponent from "src/components/ErrorComponent";
import DeleteIcon from "@mui/icons-material/Delete";
import { GET_TAGS } from "src/graphql/query";
import { DELETE_TAG } from "src/graphql/mutations";
import DeleteModel from "src/components/Common/DeleteModel";
import { toast } from "react-toastify";
import { ClearIcon } from "@mui/x-date-pickers";
import { getRole, hasPermissionPage } from "src/components/Common/Utils";
import {
  DELETE_ALL_TAGS,
  DELETE_MULTIPLE_TAGS,
} from "src/graphql/DeleteMutation";
import ActionMenu from "../Business/ActionMenu";

interface Tag {
  _id: string;
  key: string;
  value: string;
  subCategoryId: string | null;
}

const Tags: React.FC = () => {
  const [list, setList] = useState<Tag[]>([]);
  const [openDeleteModel, setOpenDeleteModel] = useState<boolean>(false);
  const [deleteTagId, setDeleteTagId] = useState<string | null>(null);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [pagination, setPagination] = useState({ page: 0, pageSize: 50 });
  const [total, setTotal] = useState(0);
  const [deleteTag] = useMutation(DELETE_TAG);
  const [openDeleteMultipleModel, setOpenDeleteMultipleModel] =
    useState<boolean>(false);
  const [openDeleteAllModel, setOpenDeleteAllModel] = useState<boolean>(false);
  const [DeleteMultipleTags] = useMutation(DELETE_MULTIPLE_TAGS);
  const [DeleteAllTags] = useMutation(DELETE_ALL_TAGS);
  const [selectedRows, setSelectedRows] = useState([]);
  const isSuperAdmin = getRole() === "SuperAdmin";
  const COLUMNS = [
    ...(isSuperAdmin
      ? [
          {
            headerName: (
              <Box display={"flex"} alignItems={"center"}>
                <Checkbox
                  checked={
                    selectedRows.length === list.length && list.length > 0
                  }
                  indeterminate={
                    selectedRows.length > 0 && selectedRows.length < list.length
                  }
                  onChange={(event) => handleSelectAll(event.target.checked)}
                />
              </Box>
            ),
            field: "select",
            flex: 0.5,
            sortable: false,
            filterable: false,
            disableClickEventBubbling: true,
            renderCell: ({ row }: any) => (
              <Checkbox
                checked={selectedRows?.includes(row?._id as never)}
                onChange={() => handleRowSelect(row._id)}
              />
            ),
            headerClassName: "no-hover",
          },
        ]
      : []),
    { headerName: "Key", field: "key", flex: 1 },
    { headerName: "Value", field: "value", flex: 1 },
    { headerName: "SubCategory ID", field: "subCategoryId", flex: 1 },
    ...[
      hasPermissionPage("Tags", "delete") && [
        {
          headerName: "Actions",
          flex: 1,
          renderCell: ({ row }: { row: Tag }) => (
            <div>
              <Tooltip title="Delete">
                <IconButton
                  onClick={() => {
                    setDeleteTagId(row._id);
                    setOpenDeleteModel(true);
                  }}
                  aria-label="delete"
                  style={{ color: "#00C5B9" }}
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </div>
          ),
        },
      ],
    ],
  ];
  const handleRowSelect = (id: any) => {
    setSelectedRows((prevSelectedRows: any) =>
      prevSelectedRows.includes(id)
        ? prevSelectedRows.filter((rowId: any) => rowId !== id)
        : [...prevSelectedRows, id]
    );
  };
  const handleSelectAll = (isSelected: boolean) => {
    if (isSelected) {
      const allRowIds = list.map((row: any) => row._id);
      setSelectedRows(allRowIds as any);
    } else {
      setSelectedRows([]);
    }
  };
  const { loading, error, data, refetch } = useQuery(GET_TAGS, {
    variables: {
      page: pagination.page + 1,
      limit: pagination.pageSize,
    },
  });
  const debouncedRefetch = useCallback(
    debounce((term: string) => {
      setSearchTerm(term);
      refetch({
        search: term,
        page: pagination.page + 1,
        limit: pagination.pageSize,
      });
    }, 1000),
    [refetch, pagination.page, pagination.pageSize]
  );

  useEffect(() => {
    if (data?.getTags?.data) {
      setList(data.getTags.data);
      setTotal(data.getTags.count);
    }
  }, [data]);

  const handleMultipleDelete = async () => {
    try {
      const res = await DeleteMultipleTags({
        variables: {
          _id: selectedRows,
        },
      });
      if (res?.data?.deleteMultipleTags?.statusCode === 200) {
        toast.success(res?.data?.deleteMultipleTags?.message);
        refetch();
      } else {
        throw new Error(res?.data?.deleteMultipleTags?.message);
      }
    } catch (error: any) {
      console.error(error);
      toast.error(error?.message);
    } finally {
      setOpenDeleteMultipleModel(false);
    }
  };

  const handleAllDelete = async () => {
    try {
      const res = await DeleteAllTags();
      if (res?.data?.deleteAllTags?.statusCode === 200) {
        toast.success(res?.data?.deleteAllTags?.message);
        refetch();
      } else {
        throw new Error(res?.data?.deleteAllTags?.message);
      }
    } catch (error: any) {
      console.error(error);
      toast.error(error?.message);
    } finally {
      setOpenDeleteAllModel(false);
    }
  };
  const handleDelete = async () => {
    if (!deleteTagId) return;
    try {
      const response = await deleteTag({
        variables: { _id: deleteTagId },
      });
      if (response.data?.deleteTag?.statusCode === 200) {
        toast.success(response.data.deleteTag.message);
      } else {
        toast.error("Failed to delete tag");
      }
      refetch();
      setOpenDeleteModel(false);
    } catch (error) {
      console.error("Error deleting tag:", error);
      toast.error("An error occurred while deleting the tag");
    }
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchTerm(value);
    debouncedRefetch(value);
  };

  const handlePageChange = (newPage: number) => {
    setPagination((prev) => ({ ...prev, page: newPage }));
    refetch({
      search: searchTerm,
      page: newPage + 1,
      limit: pagination.pageSize,
    });
  };

  const handlePageSizeChange = (newPageSize: number) => {
    setPagination((prev) => ({ ...prev, pageSize: newPageSize }));
    refetch({
      search: searchTerm,
      page: pagination.page + 1,
      limit: newPageSize,
    });
  };

  const handleCloseDeleteModel = () => {
    setOpenDeleteModel(false);
    setDeleteTagId(null);
  };
  const handleClearSearch = () => {
    setSearchTerm("");
    setPagination((prev) => ({ ...prev, page: 1 }));
    refetch({ search: "", page: 1, limit: pagination.pageSize });
  };
  if (loading) return <Loader />;
  if (error) return <ErrorComponent />;

  return (
    <Box p={2}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
        paddingRight={4}
      >
        <Typography variant="h4" style={{ color: "#00C5B9" }}>
          Tags
        </Typography>
        <Box display="flex" alignItems="center" gap={2}>
          <Box width={200}>
            <TextField
              label="Search"
              variant="outlined"
              size="small"
              value={searchTerm}
              onChange={handleSearchChange}
              InputProps={{
                endAdornment: searchTerm && (
                  <InputAdornment position="end">
                    <IconButton onClick={handleClearSearch}>
                      <ClearIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <ActionMenu
            isSuperAdmin={isSuperAdmin}
            setOpenDeleteMultipleModel={setOpenDeleteMultipleModel}
            setOpenDeleteAllModel={setOpenDeleteAllModel}
          />
        </Box>
      </Box>
      <Divider />
      <Box mt={2}>
        <CustomTable
          columns={COLUMNS}
          data={list}
          paginationModel={{
            page: pagination.page,
            pageSize: pagination.pageSize,
          }}
          totalCount={total}
          onPageChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
        />
      </Box>

      {openDeleteModel && (
        <DeleteModel
          open={openDeleteModel}
          onClose={handleCloseDeleteModel}
          onConfirm={handleDelete}
          message="Are you sure you want to delete this tag?"
        />
      )}
      {openDeleteMultipleModel && (
        <DeleteModel
          open={openDeleteMultipleModel}
          onClose={() => {
            setOpenDeleteMultipleModel(false);
          }}
          onConfirm={handleMultipleDelete}
          message={`Are you sure you want to delete ${
            selectedRows?.length ?? 0
          } tags?`}
        />
      )}
      {openDeleteAllModel && (
        <DeleteModel
          open={openDeleteAllModel}
          onClose={() => {
            setOpenDeleteAllModel(false);
          }}
          onConfirm={handleAllDelete}
          message="Are you sure you want to delete all tags?"
        />
      )}
    </Box>
  );
};

export default Tags;
