import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Box,
  Grid,
  Typography,
} from "@mui/material";
import { useMutation } from "@apollo/client";
import { CREATE_CATEGORY, UPDATE_CATEGORY } from "src/graphql/mutations";
import CustomButton from "src/components/CustomButton";
import Select from "react-select";
import { toast } from "react-toastify";
import { Category } from "./index";
import { uploadImage } from "src/components/Common/Utils";
import JsonValidator from "src/components/JsonValidator/JsonValidator";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import styles from "./Category.module.css";

interface CategoryModalProps {
  open: boolean;
  handleClose: () => void;
  category?: Category | null;
  refetchCategory: any;
  isEditMode: boolean;
}

const CategoryModal: React.FC<CategoryModalProps> = ({
  open,
  handleClose,
  category,
  refetchCategory,
  isEditMode,
}) => {
  const [imageFile, setImageFile] = useState<File | null>(null);
  const [existingImageUrl, setExistingImageUrl] = useState<string | null>(
    category?.imageUrl || null
  );
  const [isValidJson, setIsValidJson] = useState<boolean | null>(null);
  const [createCategory] = useMutation(CREATE_CATEGORY);
  const [updateCategory] = useMutation(UPDATE_CATEGORY);

  const options = [
    { value: "FOREVERYONE", label: "FOR EVERYONE" },
    { value: "FORBUSINESS", label: "FOR BUSINESS" },
  ];

  useEffect(() => {
    if (isEditMode) {
      setExistingImageUrl(category?.imageUrl ?? null);
    }
  }, [isEditMode, category]);

  const initialValues = {
    categoryName: category?.categoryName ?? "",
    description: category?.description ?? "",
    overviews: category?.overviews ?? [],
    categoryType:
      options.find((option) => option.value === category?.categoryType) || null,
  };

  const validationSchema = Yup.object({
    categoryName: Yup.string().required("Category Name is required"),
    description: Yup.string().required("Description is required"),
    categoryType: Yup.object().nullable().required("Category Type is required"),
  });

  const handleSubmit = async (values: typeof initialValues) => {
    let imageUrl = existingImageUrl;

    if (imageFile) {
      const res = await uploadImage(
        imageFile,
        "category",
        values?.categoryName
      );
      imageUrl = res.uploadImage.url;
    }
    if (!imageUrl) {
      return toast.error("Please upload image");
    }
    const variables = {
      input: {
        categoryName: values.categoryName,
        description: values.description,
        categoryType: values.categoryType?.value,
        overviews: values.overviews,
        imageUrl,
      },
    };

    try {
      let res;
      if (isEditMode && category) {
        res = await updateCategory({
          variables: {
            id: category._id,
            input: variables.input,
          },
        });
      } else {
        res = await createCategory({ variables });
      }
      if (res?.errors) {
        throw new Error(res.errors as any);
      } else {
        toast.success(
          res?.data?.addCategory?.message || "Category saved successfully!"
        );

        handleClose();
      }
    } catch (error: any) {
      toast.error(error?.message);
      console.error("Error creating/updating category:", error);
    }
  };

  const inputClickHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setImageFile(e.target.files ? e.target.files[0] : null);
    setExistingImageUrl(null);
  };

  const removeImageHandler = () => {
    setImageFile(null);
    setExistingImageUrl(null);
  };

  return (
    <Dialog open={open} onClose={handleClose} className={styles.dialog}>
      <DialogTitle className={styles.primaryColor}>
        {category ? "Update Category" : "Create Category"}
      </DialogTitle>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ values, setFieldValue, errors, touched }) => (
          <Form>
            <DialogContent>
              <Box width={"full"}>
                <JsonValidator
                  isValidJson={isValidJson}
                  jsonInput={values.overviews}
                  setIsValidJson={setIsValidJson}
                  setJsonInput={(newJson) =>
                    setFieldValue("overviews", newJson)
                  }
                />
              </Box>

              <Field
                as={TextField}
                name="categoryName"
                label="Category Name"
                margin="dense"
                fullWidth
                error={touched.categoryName && Boolean(errors.categoryName)}
                helperText={touched.categoryName && errors.categoryName}
                variant="outlined"
              />

              <Field
                as={TextField}
                name="description"
                label="Description"
                margin="dense"
                fullWidth
                multiline
                rows={4}
                error={touched.description && Boolean(errors.description)}
                helperText={touched.description && errors.description}
                variant="outlined"
              />

              <Box className={styles.selectdropdown}>
                <Select
                  value={values.categoryType}
                  onChange={(option) => setFieldValue("categoryType", option)}
                  options={options}
                  placeholder="Select category type"
                />
                {touched.categoryType && errors.categoryType && (
                  <Typography color="error" className={styles.errorText}>
                    {errors.categoryType}
                  </Typography>
                )}
              </Box>

              <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} md={4}>
                  {existingImageUrl ? (
                    <Box
                      width={200}
                      my={2.5}
                      borderRadius={1}
                      border={"2px dashed #00C5B9 "}
                      overflow={"hidden"}
                      position={"relative"}
                    >
                      <img
                        src={existingImageUrl}
                        alt="category"
                        height={"100%"}
                        width={"100%"}
                      />

                      <CustomButton
                        size="small"
                        variant="contained"
                        color="secondary"
                        onClick={removeImageHandler}
                        style={{ position: "relative", zIndex: 999999 }}
                      >
                        Remove
                      </CustomButton>
                    </Box>
                  ) : (
                    <label
                      htmlFor="category-image-upload"
                      className={styles.uploadLabel}
                    >
                      <Box className={styles.categoryImage}>
                        {imageFile ? (
                          <img
                            src={URL.createObjectURL(imageFile)}
                            alt="category"
                            className={styles.uploadedImg}
                          />
                        ) : (
                          <Typography
                            color="primary"
                            className={styles.uploadText}
                          >
                            Upload Image
                          </Typography>
                        )}
                      </Box>
                    </label>
                  )}
                  <input
                    type="file"
                    onChange={inputClickHandler}
                    className={styles.fileInput}
                    id="category-image-upload"
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Box width={100}>
                <CustomButton
                  onClick={handleClose}
                  variant="outlined"
                  className={styles.cancelButton}
                >
                  Cancel
                </CustomButton>
              </Box>
              <Box width={100}>
                <CustomButton
                  type="submit"
                  variant="contained"
                  className={styles.submitButton}
                >
                  {category ? "Update" : "Create"}
                </CustomButton>
              </Box>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default CategoryModal;
