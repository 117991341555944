import {
  Box,
  Checkbox,
  Divider,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import CustomButton from "src/components/CustomButton";
import CustomTable from "src/components/CustomTable";
import ClearIcon from "@mui/icons-material/Clear";
import { useCallback, useEffect, useState } from "react";
import { debounce } from "src/utils/debounce";
import { useMutation, useQuery } from "@apollo/client";
import { GET_DEAL_ENUM } from "src/graphql/query";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Loader from "src/components/Loader";
import ErrorComponent from "src/components/ErrorComponent";
import CreateDealEnum from "./CreateDealEnum";
import DeleteModel from "src/components/Common/DeleteModel";
import { toast } from "react-toastify";
import {
  DELETE_DEAL_ENUM,
  DELETE_MULTIPLE_DEAL_ENUM,
  DELETE_ALL_DEAL_ENUM,
} from "src/graphql/DeleteMutation";
import { getRole } from "src/components/Common/Utils";
import ActionMenu from "../Business/ActionMenu";

function DealEnum() {
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [pagination, setPagination] = useState({ page: 1, pageSize: 50 });
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [total, setTotal] = useState(0);
  const [list, setList] = useState<any>([]);
  const [selectedDealEnum, setSelectedDealEnum] = useState<any>();
  const [openConfirmDialog, setOpenConfirmDialog] = useState<boolean>(false);
  const [enumToDelete, setEnumToDelete] = useState<any>(null);
  const [DeleteDealsEnum] = useMutation(DELETE_DEAL_ENUM);
  const [selectedRows, setSelectedRows] = useState([]);
  const [DeleteMultipleDealsEnum] = useMutation(DELETE_MULTIPLE_DEAL_ENUM);
  const [DeleteAllDealsEnum] = useMutation(DELETE_ALL_DEAL_ENUM);
  const [openDeleteMultipleModel, setOpenDeleteMultipleModel] =
    useState<boolean>(false);
  const [openDeleteAllModel, setOpenDeleteAllModel] = useState<boolean>(false);
  const isSuperAdmin = getRole() === "SuperAdmin";
  const COLUMNS = [
    ...(isSuperAdmin
      ? [
          {
            headerName: (
              <Box display={"flex"} alignItems={"center"}>
                <Checkbox
                  checked={
                    selectedRows.length === list.length && list.length > 0
                  }
                  indeterminate={
                    selectedRows.length > 0 && selectedRows.length < list.length
                  }
                  onChange={(event) => handleSelectAll(event.target.checked)}
                />
              </Box>
            ),
            field: "select",
            flex: 0.5,
            sortable: false,
            filterable: false,
            disableClickEventBubbling: true,
            renderCell: ({ row }: any) => (
              <Checkbox
                checked={selectedRows?.includes(row?._id as never)}
                onChange={() => handleRowSelect(row._id)}
              />
            ),
            headerClassName: "no-hover",
          },
        ]
      : []),
    { headerName: "Name", field: "name", flex: 1 },
    { headerName: "Description", field: "description", flex: 1 },
    {
      headerName: "Actions",
      flex: 1,
      renderCell: ({ row }: { row: any }) => (
        <Box>
          <IconButton
            onClick={() => handleEdit(row)}
            aria-label="edit"
            style={{ marginRight: "8px", color: "#00C5B9" }}
          >
            <EditIcon />
          </IconButton>
          <IconButton
            onClick={() => handleDelete(row)}
            aria-label="delete"
            style={{ color: "#00C5B9" }}
          >
            <DeleteIcon />
          </IconButton>
        </Box>
      ),
    },
  ];
  const handleRowSelect = (id: any) => {
    setSelectedRows((prevSelectedRows: any) =>
      prevSelectedRows.includes(id)
        ? prevSelectedRows.filter((rowId: any) => rowId !== id)
        : [...prevSelectedRows, id]
    );
  };
  const handleSelectAll = (isSelected: boolean) => {
    if (isSelected) {
      const allRowIds = list.map((row: any) => row._id);
      setSelectedRows(allRowIds as any);
    } else {
      setSelectedRows([]);
    }
  };

  const handleMultipleDelete = async () => {
    try {
      const res = await DeleteMultipleDealsEnum({
        variables: {
          _id: selectedRows,
        },
      });
      if (res?.data?.deleteMultipleDealsEnum?.statusCode === 200) {
        toast.success(res?.data?.deleteMultipleDealsEnum?.message);
        refetch();
      } else {
        throw new Error(res?.data?.deleteMultipleDealsEnum?.message);
      }
    } catch (error: any) {
      console.error(error);
      toast.error(error?.message);
    } finally {
      setOpenDeleteMultipleModel(false);
    }
  };

  const handleAllDelete = async () => {
    try {
      const res = await DeleteAllDealsEnum();
      if (res?.data?.deleteAllDealsEnum?.statusCode === 200) {
        toast.success(res?.data?.deleteAllDealsEnum?.message);
        refetch();
      } else {
        throw new Error(res?.data?.deleteAllDealsEnum?.message);
      }
    } catch (error: any) {
      console.error(error);
      toast.error(error?.message);
    } finally {
      setOpenDeleteAllModel(false);
    }
  };

  const handleEdit = (rowData: any) => {
    setSelectedDealEnum(rowData);
    setOpenModal(true);
  };
  const handleDelete = (rowData: any) => {
    setEnumToDelete(rowData);
    setOpenConfirmDialog(true);
  };
  const { loading, error, data, refetch } = useQuery(GET_DEAL_ENUM, {
    variables: {
      page: pagination.page,
      limit: pagination.pageSize,
      search: searchTerm,
    },
  });
  useEffect(() => {
    if (data?.getDealsEnum) {
      setList(data?.getDealsEnum?.data);
      setTotal(data?.getDealsEnum?.total);
    }
  }, [data]);

  const debouncedRefetch = useCallback(
    debounce((term: string) => {
      refetch({
        search: term,
        page: 1,
        limit: pagination.pageSize,
      });
    }, 1000),
    [refetch, pagination.pageSize]
  );
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchTerm(value);
    setPagination((prev) => ({ ...prev, page: 1 }));
    debouncedRefetch(value);
  };

  const handleClearSearch = () => {
    setSearchTerm("");
    setPagination((prev) => ({ ...prev, page: 1 }));
    refetch({ search: "", page: 1, limit: pagination.pageSize });
  };
  const handlePageChange = (newPage: number) => {
    setPagination((prev) => ({ ...prev, page: newPage + 1 }));
  };
  const handlePageSizeChange = (newPageSize: number) => {
    setPagination((prev) => ({ ...prev, pageSize: newPageSize }));
  };
  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedDealEnum(undefined);
  };
  const handleConfirmDelete = async () => {
    try {
      if (enumToDelete) {
        const res = await DeleteDealsEnum({
          variables: { _id: enumToDelete._id },
        });
        if (res?.data?.deleteDealsEnum?.statusCode === 200) {
          toast.success(res?.data?.deleteDealsEnum?.message);
          setEnumToDelete(null);
          setOpenConfirmDialog(false);
          refetch();
        } else {
          throw new Error(res?.data?.deleteDealsEnum?.message);
        }
      }
    } catch (error: any) {
      toast.error(
        error?.message || "An error occurred while deleting the user."
      );
    }
  };
  if (loading) return <Loader />;
  if (error) return <ErrorComponent />;

  return (
    <Box p={2}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
        paddingRight={4}
      >
        <Typography variant="h4" style={{ color: "#00C5B9" }}>
          Deals Enums
        </Typography>

        <Box display="flex" alignItems="center" gap={2}>
          <Box width={200}>
            <TextField
              label="Search"
              variant="outlined"
              size="small"
              value={searchTerm}
              onChange={handleSearchChange}
              InputProps={{
                endAdornment: searchTerm && (
                  <InputAdornment position="end">
                    <IconButton onClick={handleClearSearch}>
                      <ClearIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <ActionMenu
            isSuperAdmin={isSuperAdmin}
            setOpenDeleteMultipleModel={setOpenDeleteMultipleModel}
            setOpenDeleteAllModel={setOpenDeleteAllModel}
          />
          <Box>
            <CustomButton
              onClick={() => setOpenModal(true)}
              variant="contained"
              className="width: 200px"
            >
              Create
            </CustomButton>
          </Box>
        </Box>
      </Box>
      <Divider />
      <Box mt={2}>
        <CustomTable
          columns={COLUMNS}
          data={list}
          paginationModel={{
            page: pagination.page - 1,
            pageSize: pagination.pageSize,
          }}
          totalCount={total}
          onPageChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
        />
      </Box>
      <CreateDealEnum
        open={openModal}
        handleClose={() => handleCloseModal()}
        refetchDeals={refetch}
        selectedDealEnum={selectedDealEnum}
      />
      <DeleteModel
        open={openConfirmDialog}
        onClose={() => setOpenConfirmDialog(false)}
        onConfirm={handleConfirmDelete}
        title="Confirm Deletion"
        message="Are you sure you want to delete this deal enum?"
      />
      {openDeleteMultipleModel && (
        <DeleteModel
          open={openDeleteMultipleModel}
          onClose={() => {
            setOpenDeleteMultipleModel(false);
          }}
          onConfirm={handleMultipleDelete}
          message={`Are you sure you want to delete ${
            selectedRows?.length ?? 0
          } deal enums?`}
        />
      )}
      {openDeleteAllModel && (
        <DeleteModel
          open={openDeleteAllModel}
          onClose={() => {
            setOpenDeleteAllModel(false);
          }}
          onConfirm={handleAllDelete}
          message="Are you sure you want to delete all deal enum?"
        />
      )}
    </Box>
  );
}

export default DealEnum;
