import { Button, ButtonProps } from "@mui/material";
import classNames from "classnames";
import React from "react";
import styles from "./CustomButton.module.scss";

// Improved typing
interface CustomButtonProps extends ButtonProps {
  className?: string;
  style?: React.CSSProperties;
}

const CustomButton: React.FC<CustomButtonProps> = ({
  variant = "contained",
  className = "",
  size = "small",
  children,
  onClick = () => {},
  style,
  ...props
}) => {
  const classCustom =
    variant === "outlined" ? styles.buttonStyleOutlined : styles.buttonStyle;
  const combinedClassName = classNames(classCustom, className);

  return (
    <Button
      variant={variant}
      className={combinedClassName}
      onClick={onClick}
      size={size}
      style={style}
      {...props}
    >
      {children}
    </Button>
  );
};

export default CustomButton;
