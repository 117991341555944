import React, { useState, useEffect } from "react";
import { Box, TextField, Typography, IconButton } from "@mui/material";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { useFormik } from "formik";
import { uploadImage } from "src/components/Common/Utils";
import { BlogModalProps } from "./SubCategory";
import CustomButton from "src/components/CustomButton";
import { useMutation, useQuery } from "@apollo/client";
import { ADD_BLOG, UPDATE_BLOG } from "src/graphql/mutations";
import CloseIcon from "@mui/icons-material/Close";
import styles from "./SubCategory.module.css";
import RichTextEditor from "src/components/Common/TextEditor";
import mammoth from "mammoth"; // For parsing .docx files
import * as pdfjsLib from "pdfjs-dist"; // For parsing .pdf files
import { useParams, useNavigate } from "react-router-dom";
import { GET_BLOG_BY_ID, GET_ALL_CATEGORIES } from "src/graphql/query";
import Loader from "src/components/Loader";
import CategorySelectDropDown from "src/components/Common/CategorySelectDropDown";
const ROUTES = {
  BLOG_ADD: "/blogs/add",
  BLOG_UPDATE: "/blogs/update/:id",
};

const BlogPage: React.FC<BlogModalProps> = () => {
  const { pathname } = window.location;
  const isEdit = pathname.startsWith("/blogs/update");
  const { id } = useParams();
  const navigate = useNavigate();

  const [imageFile, setImageFile] = useState<File | null>(null);
  const [imageUrl, setImageUrl] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [selectedCategory, setSelectedCategory] = useState<string | null>(null);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [AddBlog] = useMutation(ADD_BLOG);
  const [UpdateBlog] = useMutation(UPDATE_BLOG);
  const {
    data,
    loading: categoriesLoading,
    error,
  } = useQuery(GET_ALL_CATEGORIES);
  useEffect(() => {
    if (data?.getAllCategories?.data) {
      const options = data.getAllCategories.data.map((category: any) => ({
        value: category._id,
        label: category.categoryName,
      }));
      setCategoryOptions(options);
    }
  }, [data]);

  const { data: blogData, loading: fetchingBlog } = useQuery(GET_BLOG_BY_ID, {
    variables: { _id: id },
    skip: !isEdit || !id,
    onCompleted: (data) => {
      if (data?.getBlog?.data) {
        const {
          title,
          publishedDate,
          publishedBy,
          description,
          image,
          categoryId,
          metaTitle,
          metaDescription,
          metaKeywords,
          imageAltText,
          focus,
        } = data?.getBlog?.data;
        setImageUrl(image);
        setSelectedCategory(categoryId?._id);
        formik.setValues({
          title,
          publishedDate,
          publishedBy,
          metaTitle,
          metaDescription,
          metaKeywords,
          focus,
          imageAltText,
          description,
        });
        setLoading(false);
      }
    },
    onError: () => {
      toast.error("Failed to fetch blog details.");
      setLoading(false);
    },
  });

  useEffect(() => {
    if (pathname === ROUTES.BLOG_ADD) {
      setLoading(false);
    }
  }, [pathname]);

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0] || null;
    if (file) {
      setImageFile(file);
      setImageUrl(URL.createObjectURL(file));
    }
  };

  const handleDocumentUpload = async (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = e.target.files?.[0];
    if (file) {
      if (file.type === "application/wps-office.docx") {
        const arrayBuffer = await file.arrayBuffer();
        const result = await mammoth.convertToHtml({ arrayBuffer });
        formik.setFieldValue("description", result.value);
      } else if (file.type === "application/pdf") {
        const arrayBuffer = await file.arrayBuffer();
        const pdf = await pdfjsLib.getDocument({ data: arrayBuffer }).promise;
        let textContent = "";
        for (let i = 1; i <= pdf.numPages; i++) {
          const page = await pdf.getPage(i);
          const text = await page.getTextContent();
          text.items.forEach((item: any) => (textContent += item.str + " "));
        }
        formik.setFieldValue("description", textContent);
      } else {
        toast.error(
          "Unsupported file type. Please upload a .docx or .pdf file."
        );
      }
    }
  };

  const validationSchema = Yup.object().shape({
    title: Yup.string().required("Title is required"),
    publishedDate: Yup.date().required("Published date is required"),
    publishedBy: Yup.string().required("Published by is required"),
    description: Yup.string()
      .required("Description is required")
      .min(10, "Description should be at least 10 characters long"),
    metaTitle: Yup.string().required("Meta title is required"),
    metaDescription: Yup.string().required("Meta description is required"),
    imageAltText: Yup.string().required("Image alt text is required"),
    focus: Yup.string().required("Focus Keyword is required"),
  });
  const formik = useFormik({
    initialValues: {
      title: "",
      publishedDate: "",
      publishedBy: "",
      metaTitle: "",
      metaDescription: "",
      metaKeywords: "",
      imageAltText: "",
      focus: "",
      description: "",
    },
    validationSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      setLoading(true);
      try {
        let finalImageUrl = imageUrl;
        if (imageFile) {
          const res = await uploadImage(imageFile, "blog", values.title);
          finalImageUrl = res?.uploadImage?.url;
        }
        const variables = {
          _id: isEdit ? id : undefined,
          input: {
            ...values,
            image: finalImageUrl,
            categoryId: selectedCategory,
          },
        };

        const mutation = isEdit ? UpdateBlog : AddBlog;
        await mutation({ variables });

        toast.success(
          isEdit ? "Blog updated successfully!" : "Blog added successfully!"
        );
        navigate("/blogs");
      } catch (error: any) {
        toast.error(
          error.message || "An error occurred while saving the blog."
        );
      } finally {
        setLoading(false);
      }
    },
  });

  if (loading || fetchingBlog) {
    return <Loader />;
  }

  const handleCategoryChange = (selectedOption: any) => {
    setSelectedCategory(selectedOption?.value || null);
  };

  return (
    <Box overflow={"scroll"}>
      <Box className={styles.container}>
        <Typography variant="h4" className={styles.header}>
          {isEdit ? "Update Blog" : "Create Blog"}
        </Typography>
      </Box>

      <form onSubmit={formik.handleSubmit}>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          <TextField
            fullWidth
            label="Title"
            variant="outlined"
            name="title"
            value={formik.values.title}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.title && Boolean(formik.errors.title)}
            helperText={formik.touched.title && (formik.errors.title as string)}
          />

          <Box my={1}>
            <Typography my={1} sx={{ fontWeight: 500 }}>
              Select Category:
            </Typography>
            <CategorySelectDropDown
              handleCategoryChange={handleCategoryChange}
              selectedCategory={selectedCategory}
            />
          </Box>
          <Box my={1} sx={{ display: "flex", gap: 2 }}>
            <TextField
              fullWidth
              label="Published Date"
              variant="outlined"
              type="date"
              name="publishedDate"
              InputLabelProps={{ shrink: true }}
              value={formik.values.publishedDate}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.publishedDate &&
                Boolean(formik.errors.publishedDate)
              }
              helperText={
                formik.touched.publishedDate &&
                (formik.errors.publishedDate as string)
              }
            />
            <TextField
              fullWidth
              label="Published By"
              variant="outlined"
              name="publishedBy"
              value={formik.values.publishedBy}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.publishedBy && Boolean(formik.errors.publishedBy)
              }
              helperText={
                formik.touched.publishedBy &&
                (formik.errors.publishedBy as string)
              }
            />
          </Box>

          <Box mb={3}>
            <TextField
              label="Meta Title"
              name="metaTitle"
              value={formik?.values?.metaTitle}
              onChange={formik.handleChange}
              fullWidth
              variant="outlined"
              error={
                formik.touched.metaTitle && Boolean(formik.errors.metaTitle)
              }
              helperText={
                formik.touched.metaTitle && (formik.errors.metaTitle as string)
              }
            />
          </Box>
          <Box mb={3}>
            <TextField
              type="text-area"
              label="Meta Description"
              name="metaDescription"
              value={formik?.values?.metaDescription}
              onChange={formik.handleChange}
              fullWidth
              variant="outlined"
              multiline
              rows={4}
              error={
                formik.touched.metaDescription &&
                Boolean(formik.errors.metaDescription)
              }
              helperText={
                formik.touched.metaDescription &&
                (formik.errors.metaDescription as string)
              }
            />
          </Box>
          <Box mb={3}>
            <TextField
              type="text-area"
              label="Meta Keywords"
              name="metaKeywords"
              value={formik?.values?.metaKeywords}
              onChange={formik.handleChange}
              fullWidth
              variant="outlined"
              multiline
              rows={4}
              error={
                formik.touched.metaKeywords &&
                Boolean(formik.errors.metaKeywords)
              }
              helperText={
                formik.touched.metaKeywords &&
                (formik.errors.metaKeywords as string)
              }
            />
          </Box>
          <Box mb={3}>
            <TextField
              type="text-area"
              label="Focus Keywords"
              name="focus"
              value={formik?.values?.focus}
              onChange={formik.handleChange}
              fullWidth
              variant="outlined"
              multiline
              rows={4}
              error={formik.touched.focus && Boolean(formik.errors.focus)}
              helperText={
                formik.touched.focus && (formik.errors.focus as string)
              }
            />
          </Box>
          <Box mb={5}>
            <Typography>Description:</Typography>
            <RichTextEditor
              value={formik.values.description}
              onChange={(content: string) =>
                formik.setFieldValue("description", content)
              }
              className={styles.descriptionWrapper}
            />
            {formik.touched.description && formik.errors.description && (
              <Typography color="error">{formik.errors.description}</Typography>
            )}
          </Box>
          <Box className={styles.imageContainer}>
            <input
              type="file"
              onChange={handleImageChange}
              style={{ display: "none" }}
              id="blog-image-upload"
            />
            <label htmlFor="blog-image-upload">
              {imageUrl ? (
                <>
                  <img
                    src={imageUrl}
                    alt="Blog"
                    className={styles.imagePreview}
                  />
                  <IconButton
                    className={styles.closeIcon}
                    onClick={() => {
                      setImageUrl(null);
                      setImageFile(null);
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </>
              ) : (
                <Typography color="primary">Upload Image</Typography>
              )}
            </label>
          </Box>
          <Box mb={3}>
            <TextField
              type="text-area"
              label="Image Alt Text"
              name="imageAltText"
              value={formik?.values?.imageAltText}
              onChange={formik.handleChange}
              fullWidth
              variant="outlined"
              multiline
              rows={4}
              error={
                formik.touched.imageAltText &&
                Boolean(formik.errors.imageAltText)
              }
              helperText={
                formik.touched.imageAltText &&
                (formik.errors.imageAltText as string)
              }
            />
          </Box>
          <Box className={styles.imageContainer}>
            <input
              type="file"
              accept=".docx, .pdf"
              onChange={handleDocumentUpload}
              style={{ display: "none" }}
              id="document-upload"
            />
            <label htmlFor="document-upload">
              <Typography color="primary">Upload Document</Typography>
            </label>
          </Box>
          <Box>
            <CustomButton type="submit">
              {isEdit ? "Update Blog" : "Add Blog"}
            </CustomButton>
          </Box>
        </Box>
      </form>
    </Box>
  );
};

export default BlogPage;
