import {
  DELETE_PINCODE_ACTION,
  EDIT_PINCODE_ACTION,
  GET_PINCODE_ACTION,
  SET_PINCODE_ACTION,
} from "../types/pincode";

const {
  GET_PINCODE_ACTION_REQUEST,
  GET_PINCODE_ACTION_SUCCESS,
  GET_PINCODE_ACTION_FAILURE,
} = GET_PINCODE_ACTION;

const {
  SET_PINCODE_ACTION_REQUEST,
  SET_PINCODE_ACTION_SUCCESS,
  SET_PINCODE_ACTION_FAILURE,
} = SET_PINCODE_ACTION;

const {
  DELETE_PINCODE_ACTION_REQUEST,
  DELETE_PINCODE_ACTION_SUCCESS,
  DELETE_PINCODE_ACTION_FAILURE,
} = DELETE_PINCODE_ACTION;

const {
  EDIT_PINCODE_ACTION_REQUEST,
  EDIT_PINCODE_ACTION_SUCCESS,
  EDIT_PINCODE_ACTION_FAILURE,
} = EDIT_PINCODE_ACTION;

const initialState: any = {
  pincodeData: [],
  error: null,
  loading: false,
};

const PincodeReducer = (state = initialState, action: any): any => {
  switch (action.type) {
    case GET_PINCODE_ACTION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_PINCODE_ACTION_SUCCESS:
      return {
        ...state,
        pincodeData: action?.payload,
        error: null,
        loading: false,
      };
    case GET_PINCODE_ACTION_FAILURE:
      return {
        ...state,
        error: action.payload,
      };

    case SET_PINCODE_ACTION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case SET_PINCODE_ACTION_SUCCESS:
      return {
        ...state,
        pincodeData: [
          { ...state?.pincodeData, ...action?.payload?.addPinCode?.data },
        ],
        error: null,
        loading: false,
      };
    case SET_PINCODE_ACTION_FAILURE:
      return {
        ...state,
        error: action.payload,
      };

    case DELETE_PINCODE_ACTION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case DELETE_PINCODE_ACTION_SUCCESS:
      return {
        ...state,
        pincodeData: [
          { ...state?.pincodeData, ...action?.payload?.addPinCode?.data },
        ],
        error: null,
        loading: false,
      };
    case DELETE_PINCODE_ACTION_FAILURE:
      return {
        ...state,
        error: action.payload,
      };

    case EDIT_PINCODE_ACTION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case EDIT_PINCODE_ACTION_SUCCESS:
      return {
        ...state,
        pincodeData: [
          { ...state?.pincodeData, ...action?.payload?.addPinCode?.data },
        ],
        error: null,
        loading: false,
      };
    case EDIT_PINCODE_ACTION_FAILURE:
      return {
        ...state,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default PincodeReducer;
