import React from "react";
import styles from "./AuthLayout.module.scss";

interface Props {
  children: React.ReactNode;
}

const AuthLayout: React.FC<Props> = ({ children }) => {
  return <div className={styles.authMainConatiner}>{children}</div>;
};

export default AuthLayout;
