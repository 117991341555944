import React, { useEffect, useState } from "react";
import { Tabs, Tab, Box, Typography, Divider } from "@mui/material";
import ForEveryoneTable from "./ForEveryoneTable";
import ForBusinessTable from "./ForBusinessTable";
import { useQuery } from "@apollo/client";
import { GET_HOME_API_DATA } from "src/graphql/query";
import Loader from "src/components/Loader";
import ErrorComponent from "src/components/ErrorComponent";

const enum Type {
  FOREVERYONE = "FOREVERYONE",
  FORBUSINESS = "FORBUSINESS",
}
type TabData = {
  [key: string]: React.ReactNode;
};

const HomeApiTabs: React.FC = () => {
  const [activeTab, setActiveTab] = useState<number>(0);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  const { loading, error, data, refetch } = useQuery(GET_HOME_API_DATA, {
    variables: {
      categoryType: activeTab === 0 ? Type.FOREVERYONE : Type.FORBUSINESS,
    },
  });

  useEffect(() => {
    refetch();
  }, [activeTab]);

  const forEveryoneData = data?.getHomePageAPI?.savedCategories;
  const forBusinessData = data?.getHomePageAPI?.savedCategories;
  const tabData: TabData = {
    foreveryone: (
      <div>
        <ForEveryoneTable initialData={forEveryoneData} reload={refetch} />
      </div>
    ),
    forbusiness: (
      <div>
        <ForBusinessTable initialData={forBusinessData} reload={refetch} />
      </div>
    ),
  };

  const tabs: string[] = ["For Everyone"];

  if (loading) return <Loader />;
  if (error) return <ErrorComponent />;
  return (
    <Box sx={{ width: "100%", marginTop: "20px" }}>
      <Box p={2}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={2}
        >
          <Typography variant="h4" style={{ color: "#00C5B9" }}>
            Home Page Setting
          </Typography>
        </Box>
        <Divider />
        <Box mt={0}>
          <Tabs
            value={activeTab}
            onChange={handleTabChange}
            aria-label="home api tabs"
            variant="scrollable"
            scrollButtons="auto"
            sx={{
              borderBottom: "1px solid #e0e0e0",
            }}
          >
            {tabs.map((label, index) => (
              <Tab
                key={index}
                label={label}
                sx={{
                  textTransform: "none",
                  minWidth: 100,
                  color: activeTab === index ? "#1976d2" : "#000",
                }}
              />
            ))}
          </Tabs>
        </Box>
      </Box>

      <Box
        sx={{
          padding: "20px",
          overflowY: "scroll",
          borderRadius: "8px",
        }}
      >
        {tabData[tabs[activeTab].toLowerCase().replace(/\s/g, "")]}
      </Box>
    </Box>
  );
};

export default HomeApiTabs;
