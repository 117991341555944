import * as React from "react";
import Box from "@mui/material/Box";

const ErrorComponent: React.FC = () => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh", // Full height to center vertically
        width: "100%", // Full width to center horizontally
      }}
    >
      <h3>Failed to Load Page</h3>
    </Box>
  );
};

export default ErrorComponent;
