import React, { useEffect, useState } from "react";
import {
  Box,
  TextField,
  Typography,
  Divider,
  InputAdornment,
  IconButton,
  Grid,
  Modal,
} from "@mui/material";
import { useQuery } from "@apollo/client";
import ClearIcon from "@mui/icons-material/Clear";
import Loader from "src/components/Loader";
import ErrorComponent from "src/components/ErrorComponent";
import CustomButton from "src/components/CustomButton";
import ConfigDataModal from "./ConfigDataModal";
import { GET_CONFIG_DATA } from "src/graphql/query";
import { hasPermissionPage } from "src/components/Common/Utils";

const ConfigDataList: React.FC = () => {
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [openModal, setOpenModal] = useState(false);
  const [selectedConfig, setSelectedConfig] = useState<any>(null);
  const [openImage, setOpenImage] = useState<string | null>(null); // For lightbox

  const { loading, error, data, refetch } = useQuery(GET_CONFIG_DATA);

  useEffect(() => {
    if (data?.getConfigData?.data) {
      setSelectedConfig(data.getConfigData.data[0]);
    }
  }, [data]);

  const handleEdit = () => {
    setOpenModal(true);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const handleClearSearch = () => {
    setSearchTerm("");
  };

  if (loading) return <Loader />;
  if (error) return <ErrorComponent />;

  const handleImageClick = (imageUrl: string) => {
    setOpenImage(imageUrl); // Open the image in a modal
  };

  const renderConfigSection = (section: string, config: any) => (
    <Box p={2} border="1px solid #ddd" borderRadius="8px" mb={2}>
      <Typography variant="h6" color="primary">
        {section?.charAt(0)?.toUpperCase() + section?.slice(1)}
      </Typography>
      <Box mt={1}>
        {config?.metaTitle && (
          <Typography variant="body1">
            <strong>Title:</strong> {config.metaTitle}
          </Typography>
        )}
        {config?.focus && (
          <Typography variant="body1">
            <strong>Focus:</strong> {config.focus}
          </Typography>
        )}
        {config?.metaDescription && (
          <Typography variant="body1">
            <strong>Description:</strong> {config.metaDescription}
          </Typography>
        )}
        {config?.banners && config.banners.length > 0 && (
          <Box mt={2}>
            <Typography variant="body1">
              <strong>Banners:</strong>
            </Typography>
            <Typography variant="body1">{config?.bannerTitle}</Typography>
            <Grid container spacing={2} mt={1}>
              {config.banners.map((bannerUrl: string, index: number) => (
                <Grid item xs={12} sm={6} md={4} key={index}>
                  <Box
                    component="img"
                    src={bannerUrl}
                    alt={`Banner ${index + 1}`}
                    sx={{
                      width: "100%",
                      borderRadius: "8px",
                      cursor: "pointer",
                      transition: "transform 0.3s",
                      "&:hover": {
                        transform: "scale(1.05)",
                        boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)",
                      },
                    }}
                    onClick={() => handleImageClick(bannerUrl)}
                  />
                </Grid>
              ))}
            </Grid>
          </Box>
        )}
      </Box>
    </Box>
  );

  return (
    <Box p={2}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
      >
        <Typography variant="h4" color="primary">
          Config Settings
        </Typography>
        <Box display="flex" alignItems="center" gap={2}>
          <TextField
            label="Search"
            variant="outlined"
            size="small"
            value={searchTerm}
            onChange={handleSearchChange}
            InputProps={{
              endAdornment: searchTerm && (
                <InputAdornment position="end">
                  <IconButton onClick={handleClearSearch}>
                    <ClearIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          {hasPermissionPage("ConfigData", "update") && (
            <CustomButton
              onClick={handleEdit}
              variant="contained"
              style={{ width: "200px" }}
            >
              Edit Config
            </CustomButton>
          )}
        </Box>
      </Box>
      <Divider />

      <Box mt={2}>
        {data?.getConfigData?.data.map((configItem: any) => (
          <Box key={configItem?._id}>
            {Object.keys(configItem?.configData).map((section) =>
              renderConfigSection(section, configItem?.configData[section])
            )}
          </Box>
        ))}
      </Box>

      {/* Modal for lightbox view */}
      <Modal
        open={!!openImage}
        onClose={() => setOpenImage(null)}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          component="img"
          src={openImage ?? ""}
          alt="Enlarged view"
          sx={{
            maxWidth: "90%",
            maxHeight: "90%",
            borderRadius: "8px",
            boxShadow: "0 4px 12px rgba(0, 0, 0, 0.3)",
          }}
        />
      </Modal>

      <Box my={4}>
        <ConfigDataModal
          open={openModal}
          onClose={() => setOpenModal(false)}
          refetch={refetch}
          configData={selectedConfig}
        />
      </Box>
    </Box>
  );
};

export default ConfigDataList;
