import client from "src/apolloClient";
import { DELETE_PINCODE_MUTATION } from "src/graphql/mutations";
import { DELETE_PINCODE_ACTION } from "src/store/types/pincode";

const {
  DELETE_PINCODE_ACTION_REQUEST,
  DELETE_PINCODE_ACTION_SUCCESS,
  DELETE_PINCODE_ACTION_FAILURE,
} = DELETE_PINCODE_ACTION;

// Action Creators
export const DeletePincodeRequest = (): any => ({
  type: DELETE_PINCODE_ACTION_REQUEST,
});

export const DeletePincodeSuccess = (payload: string): any => ({
  type: DELETE_PINCODE_ACTION_SUCCESS,
  payload,
});

export const DeletePincodeFailure = (error: string): any => ({
  type: DELETE_PINCODE_ACTION_FAILURE,
  payload: error,
});

export const ACTION_DELETE_PINCODE =
  (id: any): any =>
  async (dispatch: any) => {
    dispatch(DeletePincodeRequest());
    try {
      const response = await client.mutate({
        mutation: DELETE_PINCODE_MUTATION,
        variables: { id },
      });
      if (response.data.deletePinCode.statusCode === 200) {
        return dispatch(DeletePincodeSuccess(response.data));
      } else {
        return dispatch(DeletePincodeFailure("Failed to Delete PINCODE"));
      }
    } catch (error: any) {
      return dispatch(DeletePincodeFailure(error.message));
    }
  };
