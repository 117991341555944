import { useQuery } from "@apollo/client";
import { FormControl } from "@mui/material";
import React from "react";
import Select, { SingleValue } from "react-select";
import { GET_CATEGORY } from "src/graphql/query";

// Define types for props
interface CategoryOption {
  value: string;
  label: string;
}

interface CategorySelectDropDownProps {
  selectedCategory: string | null;
  handleCategoryChange: (selectedOption: SingleValue<CategoryOption>) => void;
}

const CategorySelectDropDown: React.FC<CategorySelectDropDownProps> = ({
  selectedCategory,
  handleCategoryChange,
}) => {
  const {
    data: categoryData,
    loading,
    error,
  } = useQuery(GET_CATEGORY, {
    variables: {
      page: 1,
      limit: 500,
    },
  });

  if (loading) {
    return <p>Loading categories...</p>;
  }

  if (error) {
    return <p>Error loading categories</p>;
  }

  const categoryOptions: CategoryOption[] =
    categoryData?.getCategories?.data?.map((category: any) => ({
      value: category._id,
      label: category.categoryName,
    })) || [];

  return (
    <FormControl fullWidth sx={{ zIndex: "9999" }}>
      <Select
        value={categoryOptions.find(
          (option) => option.value === selectedCategory
        )}
        maxMenuHeight={300}
        placeholder="Select Category"
        options={categoryOptions}
        onChange={handleCategoryChange}
      />
    </FormControl>
  );
};

export default CategorySelectDropDown;
