import React, { useState, ChangeEvent } from "react";
import CustomButton from "../CustomButton";

interface JsonValidatorProps {
  isValidJson: boolean | null;
  setIsValidJson: (isValid: boolean) => void;
  jsonInput: string[];
  setJsonInput: (json: string[]) => void;
}

const JsonValidator: React.FC<JsonValidatorProps> = ({
  isValidJson,
  setIsValidJson,
  jsonInput,
  setJsonInput,
}) => {
  const [jsonText, setJsonText] = useState<string>(
    JSON.stringify(jsonInput, null, 2)
  );

  const handleInputChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const newJsonText = e.target.value;
    setJsonText(newJsonText);

    try {
      const parsedJson = JSON.parse(newJsonText);
      if (Array.isArray(parsedJson)) {
        setJsonInput(parsedJson);
        setIsValidJson(true);
      } else {
        setIsValidJson(false);
      }
    } catch (e) {
      setIsValidJson(false);
    }
  };

  const handleValidation = () => {
    try {
      const parsedJson = JSON.parse(jsonText);
      if (Array.isArray(parsedJson)) {
        setJsonInput(parsedJson);
        setIsValidJson(true);
      } else {
        setIsValidJson(false);
      }
    } catch (e) {
      setIsValidJson(false);
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        padding: "20px",
        border: "1px solid #ccc",
        borderRadius: "8px",
        backgroundColor: "#f9f9f9",
        maxWidth: "600px",
        margin: "20px auto",
      }}
    >
      <textarea
        value={jsonText}
        onChange={handleInputChange}
        placeholder="Enter your JSON array here"
        rows={10}
        style={{
          width: "100%",
          padding: "10px",
          borderRadius: "5px",
          border: "1px solid #ddd",
          fontFamily: "monospace",
          fontSize: "14px",
          resize: "vertical",
        }}
      ></textarea>
      <CustomButton
        onClick={handleValidation}
        type="button"
        style={{
          marginTop: "10px",
          padding: "8px 16px",
          borderRadius: "5px",
          border: "none",
          backgroundColor: "#007bff",
          color: "#fff",
          cursor: "pointer",
          transition: "background-color 0.3s",
        }}
        onMouseOver={(e: any) =>
          (e.currentTarget.style.backgroundColor = "#0056b3")
        }
        onMouseOut={(e: any) =>
          (e.currentTarget.style.backgroundColor = "#007bff")
        }
      >
        Validate JSON
      </CustomButton>
      {isValidJson === true && (
        <p style={{ color: "green", marginTop: "10px" }}>JSON is valid!</p>
      )}
      {isValidJson === false && (
        <p style={{ color: "red", marginTop: "10px" }}>JSON is invalid!</p>
      )}
    </div>
  );
};

export default JsonValidator;
