import * as React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

// Define the color for the loader
const LOADER_COLOR = "#197BA0";

const Loader: React.FC = () => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh", // Full height to center vertically
        width: "100%", // Full width to center horizontally
      }}
    >
      <CircularProgress sx={{ color: LOADER_COLOR }} />
    </Box>
  );
};

export default Loader;
