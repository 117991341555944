// src/components/NotFound.js
import React from "react";
import { Box, Typography, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "./constant";
import CustomButton from "./components/CustomButton";

const NotFound = () => {
  const navigate = useNavigate();

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      minHeight="100vh"
      bgcolor="#f9f9f9"
    >
      <Typography variant="h1" color="primary" gutterBottom>
        404
      </Typography>
      <Typography variant="h5" color="textSecondary" gutterBottom>
        Oops! The page you’re looking for doesn’t exist.
      </Typography>
      <Box>
        <CustomButton
          variant="contained"
          color="primary"
          onClick={() => navigate(ROUTES.EVENTS)}
        >
          Go Home
        </CustomButton>
      </Box>
    </Box>
  );
};

export default NotFound;
