import React from "react";
import { Box, Typography, List, ListItem, Divider } from "@mui/material";

export const Label: React.FC<{ name: string }> = ({ name }) => (
  <Typography variant="h6">{name?.replaceAll("_", " ")}</Typography>
);

const RadioDropdown: React.FC<{ data: any }> = ({ data }) => {
  return (
    data?.value && (
      <Box mb={2}>
        <Label name={data?.display} />
        <List>
          <ListItem>
            <Typography>{data?.value?.replaceAll("_", " ")}</Typography>
          </ListItem>
        </List>
      </Box>
    )
  );
};

const CheckText: React.FC<any> = ({ checkTextData, className }) => {
  const displayCheck = (data: any) => {
    switch (data?.type) {
      case "radio":
      case "dropdown":
        return <RadioDropdown data={data} />;
      case "checkbox":
        return (
          data?.options?.length > 0 &&
          data?.options?.some((option: any) => option?.isSelect === true) && (
            <Box mb={2}>
              <Label name={data?.display} />
              <Box>
                {data?.options?.map(
                  (option: any) =>
                    option?.isSelect && (
                      <ListItem key={option?.label}>
                        <Typography>
                          {option?.label?.replaceAll("_", " ")}
                        </Typography>
                      </ListItem>
                    )
                )}
              </Box>
              <Divider sx={{ my: 2 }} />
            </Box>
          )
        );
      default:
        return null;
    }
  };

  return (
    <Box>
      {checkTextData?.map((data: any) => {
        return <Box key={data?.display}>{displayCheck(data)}</Box>;
      })}
    </Box>
  );
};

export default CheckText;
