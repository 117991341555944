import { createStore, applyMiddleware, combineReducers } from "redux";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import { persistReducer, persistStore } from "redux-persist";
import { PersistConfig } from "redux-persist/es/types";
import thunk from "redux-thunk";

// Example reducer
import AuthReducer from "./Reducer/AuthReducer";
import logger from "redux-logger";
import PincodeReducer from "./Reducer/PincodeReducer";

const persistConfig: PersistConfig<any> = {
  key: "root",
  storage,
};

const rootReducer = combineReducers({
  authReducer: AuthReducer,
  pincodeReducer: PincodeReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);
const middlewares: any = [thunk, logger];
// const store = createStore(persistedReducer, applyMiddleware(...middlewares));

const store = createStore(persistedReducer, applyMiddleware(...middlewares));

const persistor = persistStore(store);

export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;

export { store, persistor };
