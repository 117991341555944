import React, { useState } from "react";
import { Box, IconButton, Typography, Tooltip, TextField } from "@mui/material";
import CustomTable from "src/components/CustomTable";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Modal from "./HomeModal";
import CustomButton from "src/components/CustomButton";
import { useMutation } from "@apollo/client";
import {
  DELETE_HOME,
  SAVE_SELECTED_CATEGORY,
  UPDATE_HOME,
  UPDATE_HOME_SAVE_CATEGORY_RANK,
} from "src/graphql/mutations";
import { toast } from "react-toastify";
import DeleteModel from "src/components/Common/DeleteModel";
import ToggleSwitch from "src/components/Common/ToggleSwitch";
import SaveIcon from "@mui/icons-material/Save";
import { hasPermissionPage } from "src/components/Common/Utils";

type SubCategory = {
  _id: string;
  subCategoryName: string;
  description: string | null;
};

type Category = {
  categories: any;
  _id: string;
  categoryName: string;
  subCategory: SubCategory[];
};

type ForEveryoneTableProps = {
  initialData: Category[];
  reload: () => void;
};

const enum categoryType {
  FOREVERYONE = "FOREVERYONE",
  FORBUSINESS = "FORBUSINESS",
}

const ForEveryoneTable: React.FC<ForEveryoneTableProps> = ({
  initialData,
  reload,
}) => {
  const [editMode, setEditMode] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openDeleteModel, setOpenDeleteModel] = useState<boolean>(false);
  const [deleteCategoryId, setDeleteCategoryId] = useState<string | null>(null);
  const [selectedCategory, setSelectedCategory] = useState<any>();
  const [selectedSubCategory, setSelectedSubCategory] = useState<any>();
  const [currentRowId, setCurrentRowId] = useState<string | null>(null);
  const [rankUpdates, setRankUpdates] = useState<{
    [key: string]: number | null;
  }>({});
  const [isRankEdit, setIsRankEdit] = useState<boolean>(false);
  const [UpdateSavedCategoryRanksBulk] = useMutation(
    UPDATE_HOME_SAVE_CATEGORY_RANK
  );
  const [SaveSelectedCategory] = useMutation(SAVE_SELECTED_CATEGORY);
  const [UpdateSavedSelectedCategory] = useMutation(UPDATE_HOME);
  const [DeleteSavedSelectedCategory] = useMutation(DELETE_HOME);

  // Define columns for CustomTable
  const COLUMNS = [
    { headerName: "Category Name", field: "categoryName", flex: 1 },
    { headerName: "Subcategory Name", field: "subCategoryName", flex: 1 },
    {
      headerName: "Rank",
      field: "rank",
      flex: 1,
      renderCell: ({ row }: { row: any }) => {
        const [rankValue, setRankValue] = useState(row.rank || "");
        const handleRankChange = (value: string) => {
          setRankValue(value);
          setRankUpdates((prev) => ({
            ...prev,
            [row._id]: value === "" ? null : Number(value),
          }));
        };

        return isRankEdit ? (
          <TextField
            value={rankValue}
            onChange={(e) => handleRankChange(e.target.value)}
            placeholder="Enter Rank"
            variant="outlined"
            size="small"
            style={{ marginRight: "8px" }}
          />
        ) : (
          <Typography
            display="flex"
            alignItems="center"
            style={{ height: "100%" }}
          >
            {rankValue}
          </Typography>
        );
      },
    },
    {
      headerName: "Actions",
      flex: 1,
      renderCell: ({ row }: { row: { _id: string } }) => (
        <Box>
          {hasPermissionPage("HomePageSetting", "update") && (
            <Tooltip title="Edit">
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  handleEditClick(row);
                }}
                aria-label="edit"
                style={{ marginRight: "8px", color: "#00C5B9" }}
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
          )}
          {hasPermissionPage("HomePageSetting", "delete") && (
            <Tooltip title="Delete">
              <IconButton
                aria-label="delete"
                style={{ color: "#00C5B9" }}
                onClick={(e) => {
                  e.stopPropagation();
                  setDeleteCategoryId(row._id);
                  setOpenDeleteModel(true);
                }}
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      ),
    },
  ];

  const handleDelete = async () => {
    if (!deleteCategoryId) return;
    try {
      const res = await DeleteSavedSelectedCategory({
        variables: {
          _id: deleteCategoryId,
        },
      });
      if (res?.errors) {
        toast.error("Internal Error");
      } else {
        toast.success(res?.data?.deleteSavedSelectedCategory?.message);
        reload();
        setOpenDeleteModel(false);
      }
    } catch (error) {
      toast.error("Error deleting category");
    }
  };

  const handleEditClick = (row: any) => {
    setEditMode(true);
    setOpenModal(true);
    setSelectedCategory(row.categoryId);
    setSelectedSubCategory(row.subCategoryId);
    setCurrentRowId(row?._id);
  };

  const handleClose = () => {
    setOpenModal(false);
    setSelectedCategory("");
    setSelectedSubCategory("");
  };

  const handleSave = async () => {
    const variables = {
      input: editMode
        ? {
            categoryType: categoryType.FOREVERYONE,
            category: selectedCategory,
            subCategory: selectedSubCategory,
            id: currentRowId,
          }
        : {
            categoryType: categoryType.FOREVERYONE,
            category: selectedCategory,
            subCategory: selectedSubCategory,
          },
    };
    try {
      const res = editMode
        ? await UpdateSavedSelectedCategory({ variables })
        : await SaveSelectedCategory({ variables });
      if (res?.errors) {
        throw new Error(res.errors as any);
      } else {
        toast.success(
          !editMode
            ? res?.data?.saveSelectedCategory?.message
            : res?.data?.updateSavedSelectedCategory?.message
        );
        handleClose();
        reload();
      }
    } catch (error: any) {
      toast.error(error?.message);
    }
  };

  const tableData = initialData?.map((data: any) => ({
    _id: data?._id,
    categoryName: data?.categories?.categoryName,
    subCategoryName: data?.subCategory?.subCategoryName,
    categoryId: data?.categories?._id,
    subCategoryId: data?.subCategory?._id,
    rank: data?.rank,
  }));

  const handleRankUpdate = async () => {
    const input = Object.keys(rankUpdates).map((id) => ({
      savedCategoryId: id,
      rank: rankUpdates[id],
    }));

    try {
      const response = await UpdateSavedCategoryRanksBulk({
        variables: { input: { savedCategory: input } },
      });
      if (response?.data?.updateSavedCategoryRanksBulk?.statusCode === 200) {
        toast.success(response.data.updateSavedCategoryRanksBulk.message);
        setRankUpdates({});
      } else {
        throw new Error(response.data.updateSavedCategoryRanksBulk.message);
      }
    } catch (error: any) {
      toast.error(error.message);
    } finally {
      reload();
    }
  };

  return (
    <Box>
      <Box
        display="flex"
        alignItems="end"
        justifyContent="space-between"
        gap={2}
      >
        <Typography variant="h6" sx={{ color: "#00C5B9", textAlign: "center" }}>
          {" Categories And SubCategories"}
        </Typography>

        <Box display={"flex"} justifyContent={"space-between"} gap={1}>
          <ToggleSwitch mode={isRankEdit} setMode={setIsRankEdit} />
          {isRankEdit && (
            <CustomButton
              onClick={handleRankUpdate}
              variant="contained"
              sx={{ width: 50 }}
            >
              <SaveIcon />
            </CustomButton>
          )}
          {hasPermissionPage("HomePageSetting", "add") && (
            <Box>
              <CustomButton
                onClick={() => {
                  setEditMode(false);
                  setOpenModal(true);
                }}
                variant="contained"
                className="width: 200px"
              >
                Create
              </CustomButton>
            </Box>
          )}
        </Box>
      </Box>

      <Box mt={2}>
        <CustomTable
          columns={COLUMNS}
          data={tableData}
          paginationModel={{
            page: 1,
            pageSize: 50,
          }}
          onPageSizeChange={() => {}}
          totalCount={tableData?.length}
          onPageChange={() => {}}
        />
      </Box>

      {openModal && (
        <Modal
          handleClose={handleClose}
          open={openModal}
          selectedCategory={selectedCategory}
          setSelectedCategory={setSelectedCategory}
          handleSave={handleSave}
          isEditMode={editMode}
          selectedSubCategory={selectedSubCategory}
          setSelectedSubCategory={setSelectedSubCategory}
        />
      )}

      {openDeleteModel && (
        <DeleteModel
          open={openDeleteModel}
          onClose={() => setOpenDeleteModel(false)}
          onConfirm={handleDelete}
          message="Are you sure you want to delete this category?"
        />
      )}
    </Box>
  );
};

export default ForEveryoneTable;
