import { useQuery } from "@apollo/client";
import React from "react";
import Select, { SingleValue } from "react-select";
import { GET_ALL_PINCODES, GET_PINCODES } from "src/graphql/query";
import { PincodeOption } from "./Utils";
import { FormControl } from "@mui/material";

interface PincodeSelectDropDownProps {
  selectedPincode: string | null;
  handlePincodeChange: (selectedOption: SingleValue<PincodeOption>) => void;
}

const PincodeSelectDropDown: React.FC<PincodeSelectDropDownProps> = ({
  selectedPincode,
  handlePincodeChange,
}) => {
  const { data: pincodeData, loading, error } = useQuery(GET_ALL_PINCODES);

  if (loading) {
    return <p>Loading pincodes...</p>;
  }

  if (error) {
    return <p>Error loading pincodes</p>;
  }

  const pincodeOptions: PincodeOption[] =
    pincodeData?.getAllPinCodes?.data?.map((pincode: any) => ({
      value: pincode?._id,
      label: pincode?.areaName,
    })) || [];

  return (
    <FormControl fullWidth sx={{ zIndex: "999999" }}>
      <Select
        value={
          pincodeOptions.find((option) => option.value === selectedPincode) ||
          null
        } // Handle null value
        maxMenuHeight={200}
        placeholder="Select Pincode"
        options={pincodeOptions}
        onChange={handlePincodeChange}
      />
    </FormControl>
  );
};

export default PincodeSelectDropDown;
