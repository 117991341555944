import { gql } from "@apollo/client";
export const DELETE_ALL_USER = gql`
  mutation DeleteAllUser {
    deleteAllUser {
      message
      statusCode
    }
  }
`;
export const DELETE_MULTIPLE_USER = gql`
  mutation DeleteMultipleUsers($_id: [ID!]!) {
    deleteMultipleUsers(_ids: $_id) {
      message
      deletedCount
      statusCode
    }
  }
`;

export const DELETE_MULTIPLE_BUSINESS = gql`
  mutation DeleteMultipleBusinessProfiles($_id: [ID!]!) {
    deleteMultipleBusinessProfiles(_id: $_id) {
      message
      deletedCount
      statusCode
    }
  }
`;
export const DELETE_ALL_BUSINESS = gql`
  mutation DeleteAllBusinessProfiles {
    deleteAllBusinessProfiles {
      message
      statusCode
    }
  }
`;

export const DELETE_MULTIPLE_EVENTS = gql`
  mutation DeleteMultipleEvents($_id: [ID!]!) {
    deleteMultipleEvents(_ids: $_id) {
      message
      deletedCount
      statusCode
    }
  }
`;
export const DELETE_All_EVENTS = gql`
  mutation DeleteAllEvents {
    deleteAllEvents {
      message
      statusCode
    }
  }
`;
export const DELETE_MULTIPLE_PINCODES = gql`
  mutation DeleteMultiplePinCodes($_id: [ID!]!) {
    deleteMultiplePinCodes(_ids: $_id) {
      message
      deletedCount
      statusCode
    }
  }
`;
export const DELETE_All_PINCODES = gql`
  mutation DeleteAllPinCodes {
    deleteAllPinCodes {
      message
      statusCode
    }
  }
`;
export const DELETE_MULTIPLE_DEALS = gql`
  mutation DeleteMultipleDeals($_id: [ID!]!) {
    deleteMultipleDeals(_ids: $_id) {
      message
      deletedCount
      statusCode
    }
  }
`;
export const DELETE_All_DEALS = gql`
  mutation DeleteAllDeals {
    deleteAllDeals {
      message
      statusCode
    }
  }
`;
export const DELETE_MULTIPLE_SUBCATEGORIES = gql`
  mutation DeleteMultipleSubCategory($_id: [ID!]!) {
    deleteMultipleSubCategory(_ids: $_id) {
      message
      deletedCount
      statusCode
    }
  }
`;
export const DELETE_MULTIPLE_CATEGORIES = gql`
  mutation DeleteMultipleCategories($_id: [ID!]!) {
    deleteMultipleCategories(_ids: $_id) {
      message
      deletedCount
      statusCode
    }
  }
`;
export const DELETE_ALL_CATEGORIES = gql`
  mutation DeleteAllCategories {
    deleteAllCategories {
      message
      statusCode
    }
  }
`;
export const DELETE_MULTIPLE_BLOG = gql`
  mutation DeleteMultipleBlog($_id: [ID!]!) {
    deleteMultipleBlog(_ids: $_id) {
      message
      deletedCount
      statusCode
    }
  }
`;
export const DELETE_ALL_BLOG = gql`
  mutation DeleteAllBlogs {
    deleteAllBlogs {
      message
      statusCode
    }
  }
`;
export const DELETE_MULTIPLE_FAQS = gql`
  mutation DeleteMultipleFaqs($_id: [ID!]!) {
    deleteMultipleFaqs(_ids: $_id) {
      message
      deletedCount
      statusCode
    }
  }
`;
export const DELETE_ALL_FAQS = gql`
  mutation DeleteAllFaqs {
    deleteAllFaqs {
      message
      statusCode
    }
  }
`;
export const DELETE_MULTIPLE_POPULAR_LOCATION = gql`
  mutation DeleteMultiplePopularLocations($_id: [ID!]!) {
    deleteMultiplePopularLocations(_ids: $_id) {
      message
      deletedCount
      statusCode
    }
  }
`;
export const DELETE_ALL_POPULAR_LOCATION = gql`
  mutation DeleteAllPopularLocations {
    deleteAllPopularLocations {
      message
      statusCode
    }
  }
`;
export const DELETE_MULTIPLE_TAGS = gql`
  mutation DeleteMultipleTags($_id: [ID!]!) {
    deleteMultipleTags(_ids: $_id) {
      message
      deletedCount
      statusCode
    }
  }
`;
export const DELETE_ALL_TAGS = gql`
  mutation DeleteAllTags {
    deleteAllTags {
      message
      statusCode
    }
  }
`;
export const DELETE_DEAL_ENUM = gql`
  mutation DeleteDealsEnum($_id: ID!) {
    deleteDealsEnum(_id: $_id) {
      message
      statusCode
    }
  }
`;
export const DELETE_MULTIPLE_DEAL_ENUM = gql`
  mutation DeleteMultipleDealsEnum($_id: [ID!]!) {
    deleteMultipleDealsEnum(_ids: $_id) {
      message
      deletedCount
      statusCode
    }
  }
`;
export const DELETE_ALL_DEAL_ENUM = gql`
  mutation DeleteAllDealsEnum {
    deleteAllDealsEnum {
      message
      statusCode
    }
  }
`;
export const DELETE_ALL_EVENT_ENUM = gql`
  mutation DeleteAllEventsEnum {
    deleteAllEventsEnum {
      message
      statusCode
    }
  }
`;
export const DELETE_MULTIPLE_EVENT_ENUM = gql`
  mutation DeleteMultipleEventsEnum($_id: [ID!]!) {
    deleteMultipleEventsEnum(_ids: $_id) {
      message
      deletedCount
      statusCode
    }
  }
`;
export const DELETE_EVENT_ENUM = gql`
  mutation DeleteEventsEnum($_id: ID!) {
    deleteEventsEnum(_id: $_id) {
      message
      statusCode
    }
  }
`;
export const DELETE_MULTIPLE_POPULAR_LOCATION_ENUM = gql`
  mutation DeleteMultiplePopularLocationEnum($_id: [ID!]!) {
    deleteMultiplePopularLocationEnum(_ids: $_id) {
      message
      deletedCount
      statusCode
    }
  }
`;
export const DELETE_ALL_POPULAR_LOCATION_ENUM = gql`
  mutation DeleteAllPopularLocationEnum {
    deleteAllPopularLocationEnum {
      message
      statusCode
    }
  }
`;
export const DELETE_POPULAR_LOCATION_ENUM = gql`
  mutation DeletePopularLocationEnum($_id: ID!) {
    deletePopularLocationEnum(_id: $_id) {
      message
      statusCode
      data {
        _id
        name
        description
      }
    }
  }
`;
export const DELETE_PRIVACY = gql`
  mutation DeletePrivacy($_id: ID!) {
    deletePrivacy(_id: $_id) {
      message
      statusCode
      data {
        _id
        createdAt
        updatedAt
      }
    }
  }
`;
