import React, { useState } from "react";
import { IconButton, Box, Typography, Tooltip } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { toast } from "react-toastify";
import { useMutation, useQuery } from "@apollo/client";
import {
  ADD_TOP_BUSINESS,
  DELETE_TOP_BUSINESS,
  UPDATE_TOP_BUSINESS,
} from "src/graphql/mutations";
import { useParams } from "react-router";
import { GET_BUSINESS_DATA } from "src/graphql/query";
import Loader from "src/components/Loader";
import ErrorComponent from "src/components/ErrorComponent";
import CustomTable from "src/components/CustomTable";
import CustomButton from "src/components/CustomButton";
import TopTenBusinessModal from "./TopTenBusinessModal";
import DeleteModel from "src/components/Common/DeleteModel";
import { TopBusinessProps, Business, BusinessType } from "./SubCategory";
import styles from "./SubCategory.module.css";

const TopTenBusinessTable: React.FC<TopBusinessProps> = ({
  initialBusinesses,
  subCategoryName,
  reload,
}) => {
  const [topTenBusiness, setTopTenBusiness] =
    useState<Business[]>(initialBusinesses);
  const [newBusiness, setNewBusiness] = useState<any>({
    businessProfileId: "",
    subCategoryId: "",
    rank: 0,
    title: "",
    description: "",
    pinCodeId: null,
  });
  const [editBusiness, setEditBusiness] = useState<Omit<
    Business,
    "_id"
  > | null>(null);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openDeleteModel, setOpenDeleteModel] = useState<boolean>(false);
  const [deleteBusinessId, setDeleteBusinessId] = useState<string | null>(null);
  const { id } = useParams<{ id: string }>();
  const [AddTopBusiness] = useMutation(ADD_TOP_BUSINESS);
  const [DeleteTopBusiness] = useMutation(DELETE_TOP_BUSINESS);
  const [UpdateTopBusiness] = useMutation(UPDATE_TOP_BUSINESS);
  const [pagination, setPagination] = useState({ page: 1, pageSize: 50 });
  const [businessId, setBusinessID] = useState<string>("");
  const [editMode, setEditMode] = useState<boolean>(false);

  const topTenBusinessRanks =
    initialBusinesses?.map((item: any) => item.rank) || [];
  // Define columns for CustomTable
  const COLUMNS = [
    { headerName: "Rank", field: "rank", flex: 1 },
    { headerName: "Title", field: "title", flex: 1 },
    { headerName: "Description", field: "description", flex: 1 },
    {
      headerName: "Actions",
      flex: 1,
      renderCell: ({ row }: { row: { _id: string } }) => (
        <div>
          <Tooltip title="Edit">
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                setBusinessID(row?._id);
                handleEditBusiness(row?._id);
                setEditMode(true);
                setOpenModal(true);
              }}
              aria-label="edit"
              className={styles.editIcon}
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete">
            <IconButton
              aria-label="delete"
              className={styles.deleteIcon}
              onClick={(e) => {
                e.stopPropagation();
                setDeleteBusinessId(row?._id);
                setOpenDeleteModel(true);
              }}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </div>
      ),
    },
  ];

  // Pagination handlers
  const handlePageChange = (newPage: number) => {
    setPagination((prev) => ({ ...prev, page: newPage }));
  };

  const handlePageSizeChange = (newPageSize: number) => {};

  const handleRowClick = (id: string) => {};

  // Fetch businesses
  const { loading, error, data } = useQuery(GET_BUSINESS_DATA, {
    variables: {
      primaryCategory: [`${subCategoryName}`],
    },
    notifyOnNetworkStatusChange: true,
  });

  const businessProfiles = data?.getBusinessProfiles?.data || [];
  // Handle add or update business
  const handleAddBusiness = async (values: any) => {
    const variables = {
      input: {
        businessProfileId: values.businessProfileId,
        subCategoryId: id,
        rank: `${values.rank}`,
        title: values.title,
        description: values.description,
        type: BusinessType.TopTen,
        startDate: values?.startDate,
        endDate: values?.endDate,
      },
    };
    try {
      const res = editBusiness
        ? await UpdateTopBusiness({
            variables: {
              _id: businessId,
              input: variables.input,
            },
          })
        : await AddTopBusiness({ variables });
      if (res?.errors) {
        throw new Error(res?.errors as any);
      } else {
        toast.success(res?.data?.addTopBusiness?.message);
        setNewBusiness({
          businessProfileId: "",
          subCategoryId: "",
          rank: 0,
          title: "",
          description: "",
          pinCodeId: null,
          startDate: null,
          endDate: null,
        });
        reload();
      }
    } catch (error: any) {
      toast.error(error?.message);
    }
  };

  // Handle delete business
  const handleDeleteBusiness = async () => {
    if (!deleteBusinessId) return;
    try {
      const res = await DeleteTopBusiness({
        variables: {
          _id: deleteBusinessId,
        },
      });
      if (res?.errors) {
        toast.error("Internal Error");
      } else {
        toast.success("Business deleted successfully!");
        setTopTenBusiness(
          topTenBusiness.filter((business) => business._id !== deleteBusinessId)
        );
        reload();
        setOpenDeleteModel(false);
      }
    } catch (error) {
      toast.error("Error deleting business");
    }
  };

  // Handle edit business
  const handleEditBusiness = (id: string) => {
    const businessToEdit = topTenBusiness.find(
      (business) => business._id === id
    );
    if (businessToEdit) {
      setNewBusiness({
        businessProfileId: businessToEdit?.businessProfileId,
        subCategoryId: businessToEdit.subCategoryId,
        rank: businessToEdit.rank,
        title: businessToEdit.title,
        description: businessToEdit.description,
        pinCodeId: businessToEdit.pinCodeId,
      });
    }
  };

  // Handle close modal
  const handleCloseModal = () => {
    setNewBusiness({
      businessProfileId: "",
      subCategoryId: "",
      rank: 0,
      title: "",
      description: "",
      pinCodeId: null,
    });
    setEditBusiness(null);
    setOpenModal(false);
  };

  if (loading) return <Loader />;
  if (error) return <ErrorComponent />;

  return (
    <Box>
      <Box
        display="flex"
        alignItems="end"
        justifyContent="space-between"
        gap={2}
      >
        <Typography variant="h6" className={styles.header}>
          {" Top 10"}
        </Typography>
        <Box>
          <CustomButton
            onClick={() => {
              setEditMode(false);
              setOpenModal(true);
            }}
            variant="contained"
            className="width: 200px"
          >
            Create
          </CustomButton>
        </Box>
      </Box>

      <Box mt={2}>
        <CustomTable
          columns={COLUMNS}
          data={initialBusinesses}
          paginationModel={{
            page: pagination.page - 1,
            pageSize: pagination.pageSize,
          }}
          totalCount={initialBusinesses.length}
          onPageChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
          onRowClick={handleRowClick}
        />
      </Box>

      <TopTenBusinessModal
        open={openModal}
        newBusiness={newBusiness}
        businessProfiles={businessProfiles}
        setNewBusiness={setNewBusiness}
        handleClose={handleCloseModal}
        handleAddBusiness={handleAddBusiness}
        isEditBusiness={editMode}
        usedRanks={topTenBusinessRanks}
      />

      <DeleteModel
        open={openDeleteModel}
        onClose={() => setOpenDeleteModel(false)}
        onConfirm={handleDeleteBusiness}
        message="Are you sure you want to delete this business?"
      />
    </Box>
  );
};

export default TopTenBusinessTable;
