import { Typography } from "@mui/material";
import classNames from "classnames";
import styles from "./CustomTypography.module.scss";

interface CUSTOMTYPOGRAPHYProps {
  variant?: any;
  color?: string;
  className?: string;
  children?: any;
}

const CustomTypography = ({
  variant = "body1",
  color = "textPrimary",
  className = "",
  children = () => {},
  ...props
}: CUSTOMTYPOGRAPHYProps) => {
  const variantClass = styles[variant] || "";

  const combinedClassName = classNames(variantClass, className);

  return (
    <Typography
      variant={variant}
      color={color}
      className={combinedClassName}
      {...props}
    >
      {children}
    </Typography>
  );
};

export default CustomTypography;
