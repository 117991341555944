import React, { useState } from "react";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  IconButton,
} from "@mui/material";
import CustomButton from "src/components/CustomButton";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const ResetPasswordDialog = ({ open, onClose, onSubmit }: any) => {
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleSubmit = () => {
    if (newPassword !== confirmPassword) {
      setError("Passwords do not match");
      return;
    }

    onSubmit(newPassword);
    setNewPassword("");
    setConfirmPassword("");
    setError("");
    onClose();
  };

  const handleClickShowPassword = () => {
    setShowPassword((prevState) => !prevState); // Toggle password visibility
  };

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword((prevState) => !prevState); // Toggle confirm password visibility
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>Reset Password</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label="New Password"
          type={showPassword ? "text" : "password"} // Toggle between text and password
          fullWidth
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          InputProps={{
            endAdornment: (
              <IconButton onClick={handleClickShowPassword}>
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            ),
          }}
        />
        <TextField
          margin="dense"
          label="Confirm Password"
          type={showConfirmPassword ? "text" : "password"} // Toggle between text and password
          fullWidth
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          error={!!error}
          helperText={error}
          InputProps={{
            endAdornment: (
              <IconButton onClick={handleClickShowConfirmPassword}>
                {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            ),
          }}
        />
      </DialogContent>
      <DialogActions>
        <Box>
          <CustomButton onClick={onClose} variant="outlined">
            Cancel
          </CustomButton>
        </Box>
        <Box>
          <CustomButton onClick={handleSubmit} color="primary">
            Submit
          </CustomButton>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default ResetPasswordDialog;
