import React, { useState, useEffect } from "react";
import { Box, Card, CardContent, Checkbox, Typography } from "@mui/material";
import CustomButton from "src/components/CustomButton";
import TimingSelect from "src/components/Common/TimingSelect";
import addIcon from "src/Images/Plus.svg";
import styles from "./Business.module.css";
import DynamicForm from "./DynamicForm";

function BusinessTiming({
  errors,
  addNewTimingField,
  timings,
  handleTimingChange,
  removeTimingField,
  usedDays,
  overviewJson,
  business,
  categoryId,
  setFieldValue,
  handleBack,
  handleNext,
  setTimings,
}: any) {
  const [applySameTime, setApplySameTime] = useState<boolean>(false);

  const handleSelectForAll = (index: number) => {
    const selectedTiming = timings[index];
    setTimings(
      timings.map((timing: any) => ({
        ...timing,
        startTime: selectedTiming.startTime,
        endTime: selectedTiming.endTime,
        others: selectedTiming.others,
      }))
    );
  };
  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setApplySameTime(e.target.checked);

    if (e.target.checked) {
      // Apply the first timing to all days
      const firstTiming = timings[0];
      const newTimings = Array.from({ length: 7 }, (_, idx) => ({
        day: [
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday",
          "Saturday",
          "Sunday",
        ][idx],
        startTime: firstTiming.startTime,
        endTime: firstTiming.endTime,
        others: firstTiming.others,
      }));
      setTimings(newTimings);
    }
  };
  return (
    <Card>
      <CardContent>
        <Typography
          variant="h6"
          gutterBottom
          fontSize={26}
          fontWeight={600}
          marginX={"40px"}
          className={styles.timing}
        >
          Business Timings
        </Typography>
        <Box className={styles.timingBox}>
          <Box display="flex" justifyContent="flex-end" alignSelf="self-end">
            <CustomButton
              type="button"
              variant="outlined"
              style={{ display: "flex", width: 200 }}
              onClick={addNewTimingField}
            >
              <Box gap={1} display={"flex"} alignItems={"center"}>
                <img src={addIcon} alt="add" height={20} width={20} />
                <span className={styles.addTiming}>Add Timing</span>
              </Box>
            </CustomButton>
          </Box>

          {/* Timings Management */}
          <Box sx={{ marginTop: "20px" }}>
            <Typography variant="h6">Timings:</Typography>
            <Box display="flex" alignItems="center" mb={2}>
              <Checkbox
                checked={applySameTime}
                onChange={handleCheckboxChange}
                inputProps={{ "aria-label": "Apply same time to all days" }}
              />
              <Typography variant="body2" ml={1}>
                Apply same timing for all days
              </Typography>
            </Box>

            {timings.map((timing: any, index: any) => (
              <Box key={index} mt={2}>
                <TimingSelect
                  key={index}
                  timings={timings}
                  onTimingChange={handleTimingChange}
                  onRemove={removeTimingField}
                  availableDays={usedDays}
                  index={index}
                  other={true}
                  isBusiness={true}
                />
                <Box sx={{ width: "300px" }} my={1}>
                  <CustomButton
                    variant="outlined"
                    onClick={() => handleSelectForAll(index)}
                  >
                    Apply Timing to All Days
                  </CustomButton>
                </Box>
                {errors.timings && (
                  <Typography variant="caption" color="error" mx={1.5}>
                    {errors.timings}
                  </Typography>
                )}
              </Box>
            ))}
          </Box>
        </Box>

        {overviewJson && (
          <Box width={"full"}>
            <Box
              mt={4}
              maxWidth={1200}
              display={"flex"}
              justifyContent={"center"}
            >
              <DynamicForm
                jsonData={business ? business?.overviews[0] : null}
                categoryId={categoryId}
                setFieldValue={setFieldValue}
              />
            </Box>
          </Box>
        )}

        <Box display="flex" justifyContent="end" mt={2}>
          <Box marginX={2}>
            <CustomButton onClick={handleBack} variant="outlined">
              Back
            </CustomButton>
          </Box>
          <Box marginX={2}>
            <CustomButton variant="contained" onClick={handleNext}>
              Next
            </CustomButton>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
}

export default BusinessTiming;
