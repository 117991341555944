import React from "react";
import CustomTypography from "src/components/CustomTypography";
import styles from "./AuthHeader.module.scss";

const AuthHeader = ({ headerText = "" }) => {
  return (
    <CustomTypography variant={"labelText"} className={styles.labelContainer}>
      {headerText}
    </CustomTypography>
  );
};

export default AuthHeader;
