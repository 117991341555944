type DebounceFunction = (...args: any[]) => void;

export const debounce = (
  func: DebounceFunction,
  delay: number
): DebounceFunction => {
  let timer: NodeJS.Timeout;
  return (...args: any[]) => {
    clearTimeout(timer);
    timer = setTimeout(() => func(...args), delay);
  };
};
