import React from "react";
import { Box } from "@mui/material";
import { Label } from "./CheckText";

const OverviewLabel: React.FC<any> = ({ checkTextData, className }) => {
  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "repeat(auto-fill, minmax(500px, 1fr))",
        gap: { xs: 2, lg: 3, xl: 4 },
        marginLeft: 2,
        width: "100%",
      }}
      className={className}
    >
      {checkTextData?.map((data: any) => {
        return (
          data?.options?.some((option: any) => option?.isSelect === true) && (
            <Box
              key={data?.display}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "start",
                textAlign: "start",
                gap: 2,
              }}
            >
              <Box
                sx={{
                  height: 10,
                  width: 10,
                  borderRadius: "50%",
                  backgroundColor: "primary.main",
                  flexShrink: 0,
                }}
              />
              <Label name={data?.display} />
            </Box>
          )
        );
      })}
    </Box>
  );
};

export default OverviewLabel;
