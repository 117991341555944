import {
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import CustomButton from "src/components/CustomButton";
import styles from "./Business.module.css";

function BusinessImages({
  values,
  setFieldValue,
  gallaryImages,
  handleDragStart,
  handleDragOver,
  handleBannerImageChange,
  handleDrop,
  handleRemoveBannerImage,
  handleAddBannerImage,
  handleBack,
  loading,
}: any) {
  return (
    <Card>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Gallery & Media
        </Typography>

        {/* Gallery Images Section */}
        <Divider style={{ margin: "16px 0" }} />
        <Typography variant="h6" gutterBottom>
          Gallery Images
        </Typography>
        <Grid container spacing={2}>
          {gallaryImages?.map((banner: any, index: number) => (
            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              key={index}
              draggable
              onDragStart={() => handleDragStart(index)}
              onDragOver={handleDragOver}
              onDrop={() => handleDrop(index)}
            >
              <input
                type="file"
                multiple
                onChange={(e) => {
                  if (e.target.files && e.target.files.length > 0) {
                    handleBannerImageChange(
                      index,
                      Array.from(e.target.files),
                      null,
                      setFieldValue,
                      values?.businessName
                    );
                  }
                }}
                style={{ display: "none" }}
                id={`banner-upload-${index}`}
              />

              <label htmlFor={`banner-upload-${index}`}>
                <Box className={styles.gallerybanner}>
                  {banner ? (
                    <img
                      height={200}
                      width={200}
                      src={banner}
                      alt={`banner-${index}`}
                      className={styles.gallerybannerindividual}
                    />
                  ) : (
                    <Typography color="primary">Upload Images</Typography>
                  )}
                </Box>
              </label>

              {/* TextField for Image URL input */}
              <TextField
                label="Or Enter Image URL"
                name={`gallery.image[${index}]`}
                value={values.gallery?.image || ""}
                onChange={(e) => {
                  const imageUrl = e.target.value;
                  handleBannerImageChange(
                    index,
                    null,
                    imageUrl,
                    setFieldValue,
                    values?.businessName
                  );
                }}
                fullWidth
                margin="normal"
              />

              <Box mt={1}>
                <CustomButton
                  variant="outlined"
                  color="secondary"
                  onClick={() => handleRemoveBannerImage(index)}
                  style={{ width: "100%" }}
                >
                  Remove Image
                </CustomButton>
              </Box>
            </Grid>
          ))}
        </Grid>

        <Box mt={4}>
          <CustomButton variant="outlined" onClick={handleAddBannerImage}>
            Add Gallery Image
          </CustomButton>
        </Box>

        <Box display="flex" justifyContent="end" mt={2}>
          <Box marginX={2}>
            <CustomButton onClick={handleBack} variant="outlined">
              Back
            </CustomButton>
          </Box>
          <Box marginX={2}>
            <CustomButton
              type="submit"
              variant="contained"
              color="primary"
              disabled={loading}
            >
              Submit
            </CustomButton>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
}

export default BusinessImages;
