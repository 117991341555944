import React, { useState } from "react";
import { IconButton, Box, Typography } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { toast } from "react-toastify";
import { useMutation } from "@apollo/client";
import { useParams } from "react-router";
import { ADD_TAG, DELETE_TAG, UPDATE_TAG } from "src/graphql/mutations";
import CustomTable from "src/components/CustomTable";
import CustomButton from "src/components/CustomButton";
import TagModal from "./TagModal";
import { Tag, TagProps } from "./SubCategory";
import DeleteModel from "src/components/Common/DeleteModel";
import styles from "./SubCategory.module.css"; // Import the CSS module

const TagManagementTable: React.FC<TagProps> = ({ initialTags, reload }) => {
  const [tags, setTags] = useState<Tag[]>(initialTags);
  const [newTag, setNewTag] = useState<Omit<Tag, "_id">>({
    key: "",
    value: "",
    subCategoryId: "",
  });
  const [editTagId, setEditTagId] = useState<string | null>(null);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openDeleteModel, setOpenDeleteModel] = useState<boolean>(false);
  const [deleteTagId, setDeleteTagId] = useState<string | null>(null);
  const { id } = useParams<{ id: string }>();
  const [AddTag] = useMutation(ADD_TAG);
  const [DeleteTag] = useMutation(DELETE_TAG);
  const [UpdateTag] = useMutation(UPDATE_TAG);
  const [pagination, setPagination] = useState({ page: 1, pageSize: 50 });
  const [editMode, setEditMode] = useState<boolean>(false);

  // Columns for the table
  const COLUMNS = [
    { headerName: "Key", field: "key", flex: 1 },
    { headerName: "Value", field: "value", flex: 1 },
    {
      headerName: "Actions",
      flex: 1,
      renderCell: ({ row }: { row: { _id: string } }) => (
        <div>
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              handleEditTag(row?._id);
              setEditMode(true);
              setOpenModal(true);
            }}
            aria-label="edit"
            className={styles.editIcon}
          >
            <EditIcon />
          </IconButton>
          <IconButton
            aria-label="delete"
            className={styles.deleteIcon}
            onClick={(e) => {
              setDeleteTagId(row._id);
              setOpenDeleteModel(true);
            }}
          >
            <DeleteIcon />
          </IconButton>
        </div>
      ),
    },
  ];

  const handleAddTag = async () => {
    const variables = {
      input: {
        key: newTag.key,
        value: newTag.value,
        subCategoryId: id,
      },
    };
    try {
      const res = await AddTag({ variables });
      if (res?.data?.addTag?.data) {
        toast.success(res?.data?.addTag?.message ?? "Tag added successfully");
      } else {
        throw new Error(res?.data?.addTag?.message);
      }
    } catch (error: any) {
      toast.error(error?.message ?? "Failed to add tag");
    } finally {
      reload();
      handleCloseModal();
    }
  };

  const handleDeleteTag = async (id: string) => {
    setTags(tags.filter((tag) => tag._id !== id));
    const res = await DeleteTag({ variables: { _id: id } });
    if (res?.errors) {
      toast.error("Internal Error");
      return;
    }
    toast.success("Tag deleted successfully!");
    reload();
  };

  const handleEditTag = (id: string) => {
    const tagToEdit = tags.find((tag) => tag._id === id);
    if (tagToEdit) {
      setEditTagId(tagToEdit._id);
      setNewTag({
        key: tagToEdit.key,
        value: tagToEdit.value,
        subCategoryId: tagToEdit.subCategoryId,
      });
    }
  };

  const handleSaveEdit = async () => {
    const variables = {
      _id: editTagId,
      input: {
        key: newTag.key,
        value: newTag.value,
        subCategoryId: newTag.subCategoryId,
      },
    };
    const res = await UpdateTag({ variables });
    if (res?.errors) {
      toast.error("Internal Error");
      return;
    }
    handleCloseModal();
    toast.success("Tag updated successfully!");
    setEditTagId(null);
    reload();
  };

  const handleCloseModal = () => {
    setNewTag({ key: "", value: "", subCategoryId: "" });
    setEditTagId(null);
    setOpenModal(false);
  };

  const handleConfirmDelete = async () => {
    if (deleteTagId) {
      await handleDeleteTag(deleteTagId);
      setDeleteTagId(null);
      setOpenDeleteModel(false);
    }
  };

  return (
    <Box sx={{ marginTop: "" }}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        gap={2}
      >
        <Typography variant="h6" className={styles.header}>
          Tag Management
        </Typography>
        <Box>
          <CustomButton
            onClick={() => {
              setEditMode(false);
              setOpenModal(true);
            }}
            variant="contained"
          >
            Create
          </CustomButton>
        </Box>
      </Box>

      <Box mt={2}>
        <CustomTable
          columns={COLUMNS}
          data={tags}
          paginationModel={{
            page: pagination.page - 1,
            pageSize: pagination.pageSize,
          }}
          totalCount={tags.length}
          onPageChange={(newPage) =>
            setPagination({ ...pagination, page: newPage })
          }
          onPageSizeChange={(newPageSize) =>
            setPagination({ ...pagination, pageSize: newPageSize })
          }
          onRowClick={(id: string) => handleEditTag(id)}
        />
      </Box>

      <TagModal
        open={openModal}
        newTag={newTag}
        setNewTag={setNewTag}
        handleClose={handleCloseModal}
        handleSaveEdit={handleSaveEdit}
        handleAddTag={handleAddTag}
        isEditTag={editMode}
      />

      {openDeleteModel && (
        <DeleteModel
          open={openDeleteModel}
          onClose={() => setOpenDeleteModel(false)}
          onConfirm={handleConfirmDelete}
          message="Are you sure you want to delete this tag?"
        />
      )}
    </Box>
  );
};

export default TagManagementTable;
