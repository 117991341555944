import React, { useEffect, useState, useCallback } from "react";
import {
  Box,
  TextField,
  Typography,
  Divider,
  IconButton,
  Drawer,
  InputAdornment,
  Checkbox,
  Tooltip,
} from "@mui/material";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import { DELETE_DEAL } from "src/graphql/mutations";
import { GET_DEALS, GET_DEAL } from "src/graphql/query";
import CustomTable from "src/components/CustomTable";
import Loader from "src/components/Loader";
import ErrorComponent from "src/components/ErrorComponent";
import DeleteIcon from "@mui/icons-material/Delete";
import Select from "react-select";
import {
  DealCategory,
  DealTypes,
  FilterDeals,
  dealCategoryOptions,
  dealFilterOptions,
} from "./dealCategory";
import CustomButton from "src/components/CustomButton";
import CreateDeal from "./CreateDeal";
import MenuIcon from "@mui/icons-material/Menu";
import EditIcon from "@mui/icons-material/Edit";
import DeleteModel from "src/components/Common/DeleteModel";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import DealDetailPage from "./DealDetailModal";
import styles from "./Deal.module.css";
import { ClearIcon } from "@mui/x-date-pickers";
import { debounce } from "src/utils/debounce";
import {
  DELETE_All_DEALS,
  DELETE_MULTIPLE_DEALS,
} from "src/graphql/DeleteMutation";
import DeleteSweepIcon from "@mui/icons-material/DeleteSweep";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { toast } from "react-toastify";
import { getRole, hasPermissionPage } from "src/components/Common/Utils";
import ActionMenu from "../Business/ActionMenu";

const Deal: React.FC = () => {
  const [list, setList] = useState<any>([]);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [selectedCategories, setSelectedCategories] = useState<DealCategory[]>(
    []
  ); // Updated to array
  const [filterOptions, setFilterOptions] = useState<FilterDeals[]>([]); // Updated to array
  const [pagination, setPagination] = useState({ page: 0, pageSize: 50 });
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openViewModal, setOpenViewModal] = useState<boolean>(false);
  const [total, setTotal] = useState(0);
  const [DeleteDeals] = useMutation(DELETE_DEAL);
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const [dealData, setDealData] = useState<any>(null);
  const [deleteDealId, setDeleteDealId] = useState<string | null>(null);
  const [openDeleteModel, setOpenDeleteModel] = useState<boolean>(false);
  const [openDeleteMultipleModel, setOpenDeleteMultipleModel] =
    useState<boolean>(false);
  const [openDeleteAllModel, setOpenDeleteAllModel] = useState<boolean>(false);
  const [DeleteMultipleDeals] = useMutation(DELETE_MULTIPLE_DEALS);
  const [DeleteAllDeals] = useMutation(DELETE_All_DEALS);
  const [selectedRows, setSelectedRows] = useState([]);
  const isSuperAdmin = getRole() === "SuperAdmin";

  const COLUMNS = [
    ...(isSuperAdmin
      ? [
          {
            headerName: (
              <Box display={"flex"} alignItems={"center"}>
                <Checkbox
                  checked={
                    selectedRows.length === list.length && list.length > 0
                  }
                  indeterminate={
                    selectedRows.length > 0 && selectedRows.length < list.length
                  }
                  onChange={(event) => handleSelectAll(event.target.checked)}
                />
              </Box>
            ),
            field: "select",
            flex: 0.5,
            sortable: false,
            filterable: false,
            disableClickEventBubbling: true,
            renderCell: ({ row }: any) => (
              <Checkbox
                checked={selectedRows.includes(row?._id as never)}
                onChange={() => handleRowSelect(row._id)}
              />
            ),
          },
        ]
      : []),
    {
      headerName: "Title",
      field: "title",
      flex: 1,
      renderCell: ({ row }: any) => (
        <Box sx={{ cursor: "pointer" }} onClick={() => handleView(row)}>
          {row?.title}
        </Box>
      ),
    },
    { headerName: "Category", field: "category", flex: 1 },
    { headerName: "Area", field: "area", flex: 1 },
    { headerName: "Offer", field: "offer", flex: 1 },
    { headerName: "Offer Type", field: "offerType", flex: 1 },
    {
      headerName: "Actions",
      flex: 1,
      renderCell: ({ row }: { row: { _id: string } }) => (
        <Box>
          {hasPermissionPage("Deals", "update") && (
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                handleEdit(row?._id);
              }}
              aria-label="edit"
              className={styles.editButton}
            >
              <EditIcon />
            </IconButton>
          )}
          {hasPermissionPage("Deals", "delete") && (
            <IconButton
              onClick={() => handleOpenDeleteModel(row._id)}
              aria-label="delete"
              className={styles.editButton}
            >
              <DeleteIcon />
            </IconButton>
          )}
        </Box>
      ),
    },
  ];

  const { loading, error, data, refetch } = useQuery(GET_DEALS, {
    variables: {
      page: pagination.page + 1,
      limit: pagination.pageSize,
      selectedCategory: selectedCategories.length
        ? selectedCategories
        : undefined,
      filter: filterOptions.length ? filterOptions : undefined,
    },
  });

  const [GetDeal] = useLazyQuery(GET_DEAL);

  const handleRowSelect = (id: any) => {
    setSelectedRows((prevSelectedRows: any) =>
      prevSelectedRows.includes(id)
        ? prevSelectedRows.filter((rowId: any) => rowId !== id)
        : [...prevSelectedRows, id]
    );
  };
  const handleSelectAll = (isSelected: boolean) => {
    if (isSelected) {
      const allRowIds = list.map((row: any) => row._id);
      setSelectedRows(allRowIds as any);
    } else {
      setSelectedRows([]);
    }
  };

  const handleMultipleDelete = async () => {
    try {
      const res = await DeleteMultipleDeals({
        variables: {
          _id: selectedRows,
        },
      });
      if (res?.data?.deleteMultipleDeals?.statusCode === 200) {
        toast.success(res?.data?.deleteMultipleDeals?.message);
        refetch();
      } else {
        throw new Error(res?.data?.deleteMultipleDeals?.message);
      }
    } catch (errors: any) {
      console.error(error);
      toast.error(errors?.message);
    } finally {
      setOpenDeleteMultipleModel(false);
    }
  };

  const handleAllDelete = async () => {
    try {
      const res = await DeleteAllDeals();
      if (res?.data?.deleteAllDeals?.statusCode === 200) {
        toast.success(res?.data?.deleteAllDeals?.message);
        refetch();
      } else {
        throw new Error(res?.data?.deleteAllDeals?.message);
      }
    } catch (error: any) {
      console.error(error);
      toast.error(error?.message);
    } finally {
      setOpenDeleteAllModel(false);
    }
  };

  useEffect(() => {
    if (data?.getDeals?.data) {
      setList(data.getDeals.data);
      setTotal(data.getDeals.totalDealsCount);
    }
  }, [data]);

  const handleView = async (deal: { _id: string }) => {
    try {
      const response = await GetDeal({ variables: { _id: deal._id } });
      setDealData(response.data?.getDeal?.data);
      setOpenViewModal(true);
    } catch (err) {
      console.error("Error fetching deal data:", err);
    }
  };

  const handleEdit = async (dealId: string) => {
    try {
      const response = await GetDeal({ variables: { _id: dealId } });
      setDealData(response.data?.getDeal?.data);
      setOpenModal(true);
    } catch (err) {
      console.error("Error fetching deal data:", err);
    }
  };

  const handleDelete = async () => {
    if (!deleteDealId) return;
    try {
      await DeleteDeals({ variables: { _id: deleteDealId } });
      refetch();
    } catch (error: any) {
      console.error("Failed to delete deal", error);
    } finally {
      setOpenDeleteModel(false);
      setDeleteDealId(null);
    }
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchTerm(value);
    debouncedRefetch(value);
  };

  const handleCategoryChange = (selectedOptions: any) => {
    const selectedValues = selectedOptions
      ? selectedOptions.map((option: any) => option.value.trim())
      : [];
    setSelectedCategories(selectedValues);
    refetch();
  };

  const handleFilterChange = (selectedOptions: any) => {
    const selectedValues = selectedOptions
      ? selectedOptions.map((option: any) => option.value.trim())
      : [];
    setFilterOptions(selectedValues);
    refetch();
  };
  const debouncedRefetch = useCallback(
    debounce((term: string) => {
      refetch({
        search: term,
        page: pagination.page + 1,
        limit: pagination.pageSize,
        selectedCategory: selectedCategories.length
          ? selectedCategories.map((category) => category)
          : undefined,
        filter: filterOptions.length
          ? filterOptions.map((filter) => filter)
          : undefined,
      });
    }, 1000),
    [
      refetch,
      pagination.page,
      pagination.pageSize,
      selectedCategories,
      filterOptions,
    ]
  );

  const handlePageChange = (newPage: number) => {
    setPagination((prev) => ({ ...prev, page: newPage }));
  };

  const handlePageSizeChange = (newPageSize: number) => {
    setPagination((prev) => ({ ...prev, pageSize: newPageSize }));
  };

  const handleOpenDeleteModel = (dealId: string) => {
    setDeleteDealId(dealId);
    setOpenDeleteModel(true);
  };

  const handleClearSearch = () => {
    setSearchTerm("");
    setPagination((prev) => ({ ...prev, page: 1 }));
    refetch({ search: "", page: 1, limit: pagination.pageSize });
  };

  if (loading) return <Loader />;
  if (error) return <ErrorComponent />;

  if (openViewModal) {
    return (
      <DealDetailPage
        dealData={dealData}
        onClose={() => setOpenViewModal(false)}
      />
    );
  }

  return openModal ? (
    <CreateDeal
      reload={() => refetch()}
      dealData={dealData}
      onClose={() => {
        setDealData(null);
        setOpenModal(false);
      }}
    />
  ) : (
    <>
      <Box p={2}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={2}
        >
          <Typography variant="h4" style={{ color: "#00C5B9" }}>
            Deals
          </Typography>
          <Box display="flex" alignItems="center" gap={2}>
            <Box width={200}>
              <TextField
                label="Search"
                variant="outlined"
                size="small"
                value={searchTerm}
                onChange={handleSearchChange}
                InputProps={{
                  endAdornment: searchTerm && (
                    <InputAdornment position="end">
                      <IconButton onClick={handleClearSearch}>
                        <ClearIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>

            <ActionMenu
              isSuperAdmin={isSuperAdmin}
              setOpenDeleteMultipleModel={setOpenDeleteMultipleModel}
              setOpenDeleteAllModel={setOpenDeleteAllModel}
            />
            {hasPermissionPage("Deals", "add") && (
              <Box>
                <CustomButton
                  onClick={() => setOpenModal(true)}
                  variant="contained"
                >
                  Create
                </CustomButton>
              </Box>
            )}
            <IconButton onClick={() => setOpenDrawer(true)} aria-label="menu">
              <MenuIcon />
            </IconButton>
          </Box>
        </Box>

        <CustomTable
          columns={COLUMNS as any}
          data={list}
          totalCount={total}
          paginationModel={pagination}
          onPageChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
        />

        <Drawer
          anchor="right"
          open={openDrawer}
          onClose={() => setOpenDrawer(false)}
        >
          <Box p={2} width="250px">
            <Typography variant="h6">Filters</Typography>
            <Divider />
            <Box mt={2}></Box>
          </Box>
        </Drawer>

        <DeleteModel
          open={openDeleteModel}
          onClose={() => setOpenDeleteModel(false)}
          onConfirm={handleDelete}
        />
      </Box>
      <Drawer
        anchor="right"
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
        sx={{ width: 250, flexShrink: 0 }}
      >
        <Box p={2} width={250} mt={10}>
          <Typography variant="h4" gutterBottom style={{ color: "#00C5B9" }}>
            Filters
          </Typography>
          <Box>
            <Typography variant="h6" gutterBottom>
              Category
            </Typography>
            <Select
              isMulti
              options={dealCategoryOptions}
              value={dealCategoryOptions.filter((option) =>
                selectedCategories.includes(option.value)
              )}
              onChange={handleCategoryChange}
              placeholder="Select Categories"
            />
          </Box>
          <Box>
            <Typography variant="h6" gutterBottom>
              Filter
            </Typography>
            <Select
              isMulti
              options={dealFilterOptions}
              value={dealFilterOptions.filter((option) =>
                filterOptions.includes(option.value)
              )}
              onChange={handleFilterChange}
              placeholder="Select Filters"
            />
          </Box>
        </Box>
      </Drawer>
      {openDeleteMultipleModel && (
        <DeleteModel
          open={openDeleteMultipleModel}
          onClose={() => {
            setOpenDeleteMultipleModel(false);
          }}
          onConfirm={handleMultipleDelete}
          message={`Are you sure you want to delete ${
            selectedRows?.length ?? 0
          } deals?`}
        />
      )}
      {openDeleteAllModel && (
        <DeleteModel
          open={openDeleteAllModel}
          onClose={() => {
            setOpenDeleteAllModel(false);
          }}
          onConfirm={handleAllDelete}
          message="Are you sure you want to delete all deals?"
        />
      )}
    </>
  );
};

export default Deal;
