export const GET_PINCODE_ACTION = {
  GET_PINCODE_ACTION_REQUEST: "GET_PINCODE_ACTION_REQUEST",
  GET_PINCODE_ACTION_SUCCESS: "GET_PINCODE_ACTION_SUCCESS",
  GET_PINCODE_ACTION_FAILURE: "GET_PINCODE_ACTION_FAILURE",
};

export const SET_PINCODE_ACTION = {
  SET_PINCODE_ACTION_REQUEST: "SET_PINCODE_ACTION_REQUEST",
  SET_PINCODE_ACTION_SUCCESS: "SET_PINCODE_ACTION_SUCCESS",
  SET_PINCODE_ACTION_FAILURE: "SET_PINCODE_ACTION_FAILURE",
};

export const DELETE_PINCODE_ACTION = {
  DELETE_PINCODE_ACTION_REQUEST: "DELETE_PINCODE_ACTION_REQUEST",
  DELETE_PINCODE_ACTION_SUCCESS: "DELETE_PINCODE_ACTION_SUCCESS",
  DELETE_PINCODE_ACTION_FAILURE: "DELETE_PINCODE_ACTION_FAILURE",
};

export const EDIT_PINCODE_ACTION = {
  EDIT_PINCODE_ACTION_REQUEST: "EDIT_PINCODE_ACTION_REQUEST",
  EDIT_PINCODE_ACTION_SUCCESS: "EDIT_PINCODE_ACTION_SUCCESS",
  EDIT_PINCODE_ACTION_FAILURE: "EDIT_PINCODE_ACTION_FAILURE",
};
