import React from "react";
import {
  Dialog,
  DialogActions,
  DialogTitle,
  Box,
  FormControl,
  TextField,
  Typography,
} from "@mui/material";
import Select from "react-select";
import CustomButton from "src/components/CustomButton";
import { TopBusinessModalProps } from "./SubCategory";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import styles from "./SubCategory.module.css";

const TopTenBusinessModal: React.FC<TopBusinessModalProps> = ({
  open,
  handleClose,
  newBusiness,
  businessProfiles,
  handleAddBusiness,
  isEditBusiness = false,
  usedRanks = [],
}) => {
  const rankOptions = Array.from({ length: 10 }, (_, i) => {
    const rankValue = i + 1;
    return {
      value: rankValue,
      label: rankValue.toString(),
      isDisabled: usedRanks.includes(rankValue),
    };
  });

  const businessOptions =
    businessProfiles?.map((business: any) => ({
      value: business._id,
      label: `${business.businessName}`,
    })) || [];

  const initialValues = {
    businessProfileId: newBusiness?.businessProfileId || "",
    title: newBusiness?.title || "",
    description: newBusiness?.description || "",
    startDate: newBusiness?.startDate || "",
    endDate: newBusiness?.endDate || "",
    rank: newBusiness?.rank || "",
  };

  // Validation schema using Yup
  const validationSchema = Yup.object().shape({
    businessProfileId: Yup.string().required("Business Profile is required"),
    title: Yup.string()
      .required("Title is required")
      .min(3, "Title must be at least 3 characters")
      .max(100, "Title cannot exceed 100 characters"),
    description: Yup.string()
      .required("Description is required")
      .min(10, "Description must be at least 10 characters")
      .max(500, "Description cannot exceed 500 characters"),
    startDate: Yup.date()
      .required("Start Date is required")
      .typeError("Invalid date format"),
    endDate: Yup.date()
      .required("End Date is required")
      .typeError("Invalid date format")
      .min(Yup.ref("startDate"), "End Date cannot be before Start Date"),
    rank: Yup.number()
      .required("Rank is required")
      .min(1, "Rank must be at least 1")
      .max(10, "Rank cannot exceed 10"),
  });

  const handleFormSubmit = (values: any) => {
    handleAddBusiness && handleAddBusiness(values);
    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        <Typography variant="h6" className={styles.header}>
          {isEditBusiness
            ? "Update Business Profile"
            : "Create Top 10 Business Profile"}
        </Typography>
      </DialogTitle>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleFormSubmit}
        enableReinitialize
      >
        {({ values, setFieldValue, errors, touched }) => (
          <Form>
            <Box
              display={"flex"}
              flexDirection={"column"}
              gap={0.5}
              padding={3}
              borderRadius={2}
            >
              <FormControl fullWidth sx={{ zIndex: "9999" }}>
                <Select
                  value={businessOptions.find(
                    (option: any) => option.value === values.businessProfileId
                  )}
                  maxMenuHeight={200}
                  placeholder="Select Business"
                  options={businessOptions}
                  onChange={(selectedOption) =>
                    setFieldValue("businessProfileId", selectedOption?.value)
                  }
                />
                {errors.businessProfileId && touched.businessProfileId && (
                  <Typography color="error">
                    {errors?.businessProfileId as any}
                  </Typography>
                )}
              </FormControl>

              <Field
                name="title"
                as={TextField}
                fullWidth
                label="Title"
                variant="outlined"
                error={touched.title && Boolean(errors.title)}
                helperText={touched.title && errors.title}
              />

              <Field
                name="description"
                as={TextField}
                fullWidth
                label="Description"
                variant="outlined"
                multiline
                rows={4}
                error={touched.description && Boolean(errors.description)}
                helperText={touched.description && errors.description}
              />

              <Box marginTop={2}>
                <Field
                  name="startDate"
                  as={TextField}
                  margin="dense"
                  label="Start Date"
                  type="date"
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  error={touched.startDate && Boolean(errors.startDate)}
                  helperText={touched.startDate && errors.startDate}
                />
              </Box>
              <Box marginTop={2}>
                <Field
                  name="endDate"
                  as={TextField}
                  margin="dense"
                  label="End Date"
                  type="date"
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  error={touched.endDate && Boolean(errors.endDate)}
                  helperText={touched.endDate && errors.endDate}
                />
              </Box>
              <FormControl
                fullWidth
                sx={{ zIndex: "9999", position: "relative" }}
              >
                <Select
                  value={rankOptions.find(
                    (option) => option.value === values.rank
                  )}
                  options={rankOptions}
                  placeholder="Select Rank"
                  maxMenuHeight={150}
                  onChange={(selectedOption) =>
                    setFieldValue("rank", selectedOption?.value)
                  }
                  isOptionDisabled={(option) => option.isDisabled}
                />
                {errors.rank && touched.rank && (
                  <Typography color="error">{errors?.rank as any}</Typography>
                )}
              </FormControl>
            </Box>

            <DialogActions sx={{ p: 3, justifyContent: "space-between" }}>
              <CustomButton onClick={handleClose} variant="outlined">
                Cancel
              </CustomButton>
              <CustomButton type="submit" variant="contained">
                {isEditBusiness ? "Update" : "Add"}
              </CustomButton>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default TopTenBusinessModal;
