import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import { useMutation } from "@apollo/client";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import CustomButton from "src/components/CustomButton";
import { ADD_DEALS_ENUM } from "src/graphql/mutations";
import { toast } from "react-toastify";
import { UPDATE_DEALS_ENUM } from "src/graphql/mutations";

interface DealsModalProps {
  open: boolean;
  handleClose: () => void;
  refetchDeals: () => void;
  selectedDealEnum?: any;
}

const CreateDealEnum: React.FC<DealsModalProps> = ({
  open,
  handleClose,
  refetchDeals,
  selectedDealEnum,
}) => {
  const [addDealsEnum] = useMutation(ADD_DEALS_ENUM);
  const [updateDealsEnum] = useMutation(UPDATE_DEALS_ENUM);
  const initialValues = {
    name: selectedDealEnum?.name || "",
    description: selectedDealEnum?.description || "",
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    description: Yup.string().required("Description is required"),
  });

  const handleSubmit = async (
    values: typeof initialValues,
    { setErrors }: any
  ) => {
    try {
      const variables = {
        input: {
          name: values.name,
          description: values.description,
        },
      };

      let res;
      if (selectedDealEnum) {
        // Update existing deal
        res = await updateDealsEnum({
          variables: { _id: selectedDealEnum?._id, ...variables },
        });
      } else {
        // Add new deal
        res = await addDealsEnum({ variables });
      }

      if (
        res?.data?.addDealsEnum?.statusCode === 200 ||
        res?.data?.updateDealsEnum?.statusCode === 200
      ) {
        toast.success(
          res?.data?.addDealsEnum?.message ||
            res?.data?.updateDealsEnum?.message ||
            "Operation successful"
        );
        handleClose();
        refetchDeals();
      } else {
        throw new Error(res?.errors as any);
      }
    } catch (err: any) {
      toast.error(err.message || "An error occurred during the operation.");
    }
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>
        {selectedDealEnum ? "Edit Deal Enum" : "Add Deal Enum"}
      </DialogTitle>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({ errors, touched }) => (
          <Form>
            <DialogContent>
              <Field
                name="name"
                as={TextField}
                margin="dense"
                label="Name"
                fullWidth
                error={!!errors.name && touched.name}
                helperText={<ErrorMessage name="name" />}
              />

              <Field
                name="description"
                as={TextField}
                margin="dense"
                label="Description"
                fullWidth
                error={!!errors.description && touched.description}
                helperText={<ErrorMessage name="description" />}
              />
            </DialogContent>
            <DialogActions>
              <CustomButton variant="outlined" onClick={handleClose}>
                Cancel
              </CustomButton>
              <CustomButton type="submit" variant="contained">
                {selectedDealEnum ? "Update Deal Enum" : "Add Deal Enum"}
              </CustomButton>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default CreateDealEnum;
