export enum TypeEnum {
  Deal = "DEAL",
  BusinessProfile = "BUSINESS_PROFILE",
  Blog = "BLOG",
  News = "NEWS",
  Tag = "TAG",
  Review = "REVIEW",
  PopularLocation = "POPULARLOCATION",
}

// Display Name Mappings
const typeEnumDisplayNames = {
  [TypeEnum.Deal]: "Deal",
  [TypeEnum.BusinessProfile]: "Business Profile",
  [TypeEnum.Blog]: "Blog",
  [TypeEnum.News]: "News",
  [TypeEnum.Tag]: "Tag",
  [TypeEnum.Review]: "Review",
  [TypeEnum.PopularLocation]: "Popular Location",
};

// Options Generation
export const typeEnumOptions = Object.keys(TypeEnum).map((key) => ({
  value: TypeEnum[key as keyof typeof TypeEnum],
  label: typeEnumDisplayNames[TypeEnum[key as keyof typeof TypeEnum]],
}));
