import React, { useState, useEffect } from "react";
import {
  Box,
  TextField,
  Typography,
  IconButton,
  Grid,
  CircularProgress,
} from "@mui/material";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { useFormik } from "formik";
import CustomButton from "src/components/CustomButton";
import { useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import styles from "./AboutSurat.module.css";
import { uploadImages } from "src/components/Common/Utils";
import CloseIcon from "@mui/icons-material/Close";
import "react-datepicker/dist/react-datepicker.css";
import RichTextEditor from "src/components/Common/TextEditor";
import { CREATE_ABOUT_SURAT, UPDATE_ABOUT_SURAT } from "src/graphql/mutations";
import JsonValidator from "src/components/JsonValidator/JsonValidator";
const ROUTES = {
  ABOUT_SURAT_ADD: "/aboutsurat/add",
  ABOUT_SURAT: "/aboutsurat",
};
interface Image {
  imageUrl: string;
  file?: File;
}
interface AboutSuratModalProps {
  open: boolean;
  handleClose: () => void;
  aboutsurat?: any;
  refetchAboutSurat: () => void;
}
const AboutSuratModal: React.FC<AboutSuratModalProps> = ({
  aboutsurat,
  handleClose,
  refetchAboutSurat,
}) => {
  const { pathname } = window.location;
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState<any>({});
  const [draggingIndex, setDraggingIndex] = useState<any>(null);
  const [images, setImages] = useState<Image[]>([]);
  const [AddAboutSurat] = useMutation(CREATE_ABOUT_SURAT);
  const [UpdateAboutSurat] = useMutation(UPDATE_ABOUT_SURAT);
  const [isValidJson, setIsValidJson] = useState<boolean | null>(null);

  useEffect(() => {
    if (pathname === ROUTES.ABOUT_SURAT_ADD) {
      setIsLoading(false);
    }
  }, [pathname]);

  const validationSchema = Yup.object().shape({
    title: Yup.string().required("Title is required"),
    description: Yup.string()
      .required("Description is required")
      .min(10, "Description should be at least 10 characters long"),
    metaTitle: Yup.string().required("Meta title is required"),
    metaDescription: Yup.string().required("Meta description is required"),
    focus: Yup.string().required("Focus keyword is required"),
  });

  const formik = useFormik({
    initialValues: {
      title: aboutsurat?.title || "",
      description: aboutsurat?.description || "",
      metaTitle: aboutsurat?.metaTitle || "",
      metaDescription: aboutsurat?.metaDescription || "",
      metaKeywords: aboutsurat?.metaKeywords || "",
      focus: aboutsurat?.focus || "",
      additionalData: aboutsurat?.additionalData || [],
      otherData: aboutsurat?.otherData || [],
    },

    enableReinitialize: true,
    onSubmit: async (values, actions) => {
      await handleSubmit(values, actions);
    },
  });
  const handleDragStart = (index: any) => {
    setDraggingIndex(index);
  };

  // Handle drag over
  const handleDragOver = (event: any) => {
    event.preventDefault();
  };

  const handleDrop = (index: number) => {
    if (draggingIndex === null) return;

    const updatedImages = [...images];
    const [movedImage] = updatedImages.splice(draggingIndex, 1);
    updatedImages.splice(index, 0, movedImage);

    updatedImages.forEach((image) => {
      if (image.imageUrl) {
        URL.revokeObjectURL(image.imageUrl);
      }
    });
    setImages(updatedImages);

    setDraggingIndex(null);
  };
  useEffect(() => {
    if (aboutsurat) {
      setImages(
        aboutsurat?.images?.map((img: string) => ({ imageUrl: img })) || []
      );
    } else {
      formik.resetForm();
      setImages([{ imageUrl: "" }, { imageUrl: "" }, { imageUrl: "" }]);
    }
  }, [aboutsurat]);
  const handleImageChange = (index: number, file: File | null) => {
    const updatedImages = [...images];
    if (file) {
      updatedImages[index] = { imageUrl: URL.createObjectURL(file), file };
    } else {
      updatedImages[index] = { imageUrl: "" };
    }
    setImages(updatedImages);
  };

  const handleRemoveImage = (index: number) => {
    const updatedImages = [...images];
    const removedImage = updatedImages[index];
    if (removedImage.imageUrl.startsWith("blob:")) {
      URL.revokeObjectURL(removedImage.imageUrl);
    }
    updatedImages.splice(index, 1);
    setImages(updatedImages);
  };

  const addNewImageField = () => {
    setImages([...images, { imageUrl: "" }]);
  };

  const handleSubmit = async (values: typeof formik.values, actions: any) => {
    setIsLoading(true);

    try {
      // Validate the form values
      await validationSchema.validate(values, { abortEarly: false });

      // Prepare images for upload
      const filesToUpload = images
        .filter((image) => image.file)
        .map((image) => image.file);

      // Upload images if any exist
      const uploadedImages = filesToUpload?.length
        ? await uploadImages(
            filesToUpload as any,
            "aboutsurat",
            formik.values?.title
          )
        : [];
      // Extract URLs of uploaded images
      const uploadedImageUrls =
        uploadedImages?.uploadMultipleImages?.map((img: any) => img.url) ||
        uploadedImages?.map((img: any) => img.url);

      // Get existing image URLs
      const existingImageUrls = images
        .filter((image) => !image.file)
        .map((image) => image.imageUrl);

      // Combine existing and uploaded images
      const updatedImages = [...existingImageUrls, ...uploadedImageUrls];

      // Prepare variables for mutation
      const variables = {
        input: {
          ...formik.values,
          images: updatedImages,
        },
      };

      // Perform mutation (create or update)
      const res = aboutsurat
        ? await UpdateAboutSurat({
            variables: { _id: aboutsurat?._id, input: variables?.input },
          })
        : await AddAboutSurat({ variables });

      // Handle response
      if (res?.data) {
        toast.success(
          res.data.addAboutSurat?.message ||
            res.data.updateAboutSurat?.message ||
            "About surat updated successfully!"
        );
        if (typeof refetchAboutSurat === "function") {
          await refetchAboutSurat();
        }
        if (typeof refetchAboutSurat === "function") {
          await handleClose();
        }
        navigate(ROUTES.ABOUT_SURAT);
      }
    } catch (error: any) {
      // Handle validation and other errors
      if (error.inner) {
        const validationErrors: Record<string, string> = {};
        error.inner.forEach((err: any) => {
          validationErrors[err.path] = err.message;
        });
        setErrors(validationErrors); // Set errors state
      } else {
        toast.error(error.message || "Error submitting data.");
      }
    } finally {
      setIsLoading(false); // Reset loading state
    }
  };

  return (
    <Box overflow={"scroll"} paddingX={20}>
      <Box className={styles.container}>
        <Typography variant="h4" className={styles.header}>
          {aboutsurat ? "Update About Surat" : "Create About Surat"}
        </Typography>
      </Box>

      <form onSubmit={formik.handleSubmit}>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          <TextField
            fullWidth
            label="Title"
            variant="outlined"
            name="title"
            value={formik.values.title}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.title && Boolean(formik.errors.title)}
            helperText={formik.touched.title && (formik.errors.title as string)}
          />
          <Box width={"full"}>
            <Typography fontSize={16}>{"Additional Data"}</Typography>
            <JsonValidator
              isValidJson={isValidJson}
              jsonInput={formik.values.additionalData}
              setIsValidJson={setIsValidJson}
              setJsonInput={(newJson) =>
                formik.setFieldValue("additionalData", newJson)
              }
            />
          </Box>
          <Box mb={2}>
            <Typography>Description:</Typography>
            <RichTextEditor
              value={formik?.values?.description}
              onChange={(content: string) =>
                formik.setFieldValue("description", content)
              }
              className={styles.descriptionWrapper}
            />
            {formik.touched.description && formik.touched.description && (
              <Typography color="error">
                {formik.touched.description &&
                  Boolean(formik.errors.description)}
              </Typography>
            )}
          </Box>

          <Box mb={3}>
            <TextField
              label="Meta Title"
              name="metaTitle"
              value={formik?.values?.metaTitle}
              onChange={formik.handleChange}
              fullWidth
              variant="outlined"
              error={
                formik.touched.metaTitle && Boolean(formik.errors.metaTitle)
              }
              helperText={
                formik.touched.metaTitle && (formik.errors.metaTitle as string)
              }
            />
          </Box>
          <Box mb={3}>
            <TextField
              type="text-area"
              label="Meta Description"
              name="metaDescription"
              value={formik?.values?.metaDescription}
              onChange={formik.handleChange}
              fullWidth
              variant="outlined"
              // multiline
              rows={4}
              error={
                formik.touched.metaDescription &&
                Boolean(formik.errors.metaDescription)
              }
              helperText={
                formik.touched.metaDescription &&
                (formik.errors.metaDescription as string)
              }
            />
          </Box>

          <Box mb={3}>
            <TextField
              type="text-area"
              label="Meta Keywords"
              name="metaKeywords"
              value={formik?.values?.metaKeywords}
              onChange={formik.handleChange}
              fullWidth
              variant="outlined"
              // multiline
              rows={4}
              error={
                formik.touched.metaKeywords &&
                Boolean(formik.errors.metaKeywords)
              }
              helperText={
                formik.touched.metaKeywords &&
                (formik.errors.metaKeywords as string)
              }
            />
          </Box>
          <Box mb={3}>
            <TextField
              type="text-area"
              label="Focus Keywords"
              name="focus"
              value={formik?.values?.focus}
              onChange={formik.handleChange}
              fullWidth
              variant="outlined"
              // multiline
              rows={4}
              error={formik.touched.focus && Boolean(formik.errors.focus)}
              helperText={
                formik.touched.focus && (formik.errors.focus as string)
              }
            />
          </Box>
          <Box mb={3}>
            <Grid container spacing={2}>
              {images.map((image, index) => (
                <Grid
                  item
                  xs={4}
                  key={index}
                  draggable
                  onDragStart={() => handleDragStart(index)}
                  onDragOver={handleDragOver}
                  onDrop={() => handleDrop(index)}
                >
                  <Box position="relative">
                    <img
                      src={
                        image.imageUrl ||
                        "https://via.placeholder.com/150x150?text=Add+Image"
                      }
                      alt={`Image ${index + 1}`}
                      style={{
                        width: "100%",
                        height: "150px",
                        objectFit: "cover",
                        borderRadius: "8px",
                      }}
                    />
                    <IconButton
                      size="small"
                      style={{
                        position: "absolute",
                        top: "5px",
                        right: "5px",
                        backgroundColor: "rgba(255, 255, 255, 0.7)",
                      }}
                      onClick={() => handleRemoveImage(index)}
                    >
                      <CloseIcon />
                    </IconButton>
                    <CustomButton
                      variant="contained"
                      component="label"
                      style={{ marginTop: "10px", width: "100%" }}
                    >
                      {image.imageUrl ? "Change Image" : "Add Image"}
                      <input
                        type="file"
                        accept="image/*"
                        multiple
                        hidden
                        onChange={(e) =>
                          handleImageChange(
                            index,
                            e.target.files ? e.target.files[0] : null
                          )
                        }
                      />
                    </CustomButton>
                  </Box>
                </Grid>
              ))}
            </Grid>
            <Box mt={2}>
              <CustomButton onClick={addNewImageField} variant="outlined">
                Add New Image
              </CustomButton>
            </Box>
          </Box>
          <Box width={"full"}>
            <Typography fontSize={16}>{"Other Data"}</Typography>
            <JsonValidator
              isValidJson={isValidJson}
              jsonInput={formik.values.otherData}
              setIsValidJson={setIsValidJson}
              setJsonInput={(newJson) =>
                formik.setFieldValue("otherData", newJson)
              }
            />
          </Box>
          <Box mb={3}>
            <CustomButton
              color="primary"
              variant="contained"
              type="submit"
              style={{ marginLeft: "10px" }}
            >
              {isLoading ? (
                <CircularProgress size={24} color="inherit" />
              ) : aboutsurat ? (
                "Update"
              ) : (
                "Create"
              )}
            </CustomButton>
          </Box>
        </Box>
      </form>
    </Box>
  );
};

export default AboutSuratModal;
