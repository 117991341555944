import { useQuery } from "@apollo/client";
import { FormControl } from "@mui/material";
import React, { useEffect, useState } from "react";
import Select from "react-select";
import { GET_ALL_SUBCATEGORIES } from "src/graphql/query";
import { SubCategoryOption, SubCategoryDropDownProps } from "./Business";

const SubCategoryDropDown: React.FC<SubCategoryDropDownProps> = ({
  selectedSubCategory,
  handleSubCategoryChange,
}) => {
  const [subCategories, setSubCategories] = useState<SubCategoryOption[]>([]);

  const { data: subCategoryData } = useQuery(GET_ALL_SUBCATEGORIES, {
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    if (subCategoryData) {
      const fetchedSubCategories =
        subCategoryData?.getAllSubCategories?.data?.map(
          (sub: { subCategoryName: string; _id: string }) => ({
            value: sub._id,
            label: sub.subCategoryName,
          })
        );
      setSubCategories(fetchedSubCategories || []);
    }
  }, [subCategoryData]);

  return (
    <FormControl fullWidth sx={{ zIndex: "9999" }}>
      <Select
        value={subCategories.find(
          (option) => option.value === selectedSubCategory
        )}
        maxMenuHeight={300}
        placeholder="Select Sub Category"
        options={subCategories}
        onChange={handleSubCategoryChange}
      />
    </FormControl>
  );
};

export default SubCategoryDropDown;
