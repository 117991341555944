import React from "react";
import {
  Dialog,
  DialogActions,
  DialogTitle,
  Box,
  FormControl,
  TextField,
  Typography,
} from "@mui/material";
import CustomButton from "src/components/CustomButton";
import { TagModalProps } from "./SubCategory";

const TagModal: React.FC<TagModalProps> = ({
  open,
  handleClose,
  newTag,
  setNewTag,
  handleAddTag,
  handleSaveEdit,
  isEditTag = false,
}) => {
  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        <Typography variant="h6" className="modalTitle">
          {isEditTag ? "Update Tag" : "Create New Tag"}
        </Typography>
      </DialogTitle>
      <Box
        display={"flex"}
        flexDirection={"column"}
        gap={0.5}
        padding={1}
        borderRadius={2}
      >
        <FormControl fullWidth>
          <TextField
            label="Key"
            variant="outlined"
            value={newTag?.key || ""}
            onChange={(e) => setNewTag({ ...newTag, key: e.target.value })}
          />
        </FormControl>

        <FormControl fullWidth>
          <TextField
            label="Value"
            variant="outlined"
            value={newTag?.value || ""}
            onChange={(e) => setNewTag({ ...newTag, value: e.target.value })}
          />
        </FormControl>
      </Box>

      <DialogActions className="dialogActions">
        <CustomButton onClick={handleClose} variant="outlined">
          Cancel
        </CustomButton>
        <CustomButton
          onClick={isEditTag ? handleSaveEdit : handleAddTag}
          variant="contained"
        >
          {isEditTag ? "Save Changes" : "Create"}
        </CustomButton>
      </DialogActions>
    </Dialog>
  );
};

export default TagModal;
