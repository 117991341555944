import React, { forwardRef, lazy, Suspense, useCallback } from "react";
import ReactQuill, { ReactQuillProps } from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import the necessary styles
// @ts-ignore
import ImageResize from "quill-image-resize-module-react";
import styles from "./richTextEditor.module.css";
import cn from "classnames";

type RichTextEditorProps = ReactQuillProps & {
  className?: string;
  value?: string;
  onChange?: (content: string) => void;
};

export type RichTextEditorRef = ReactQuill;
ReactQuill.Quill.register("modules/imageResize", ImageResize);
const LazyReactQuill = lazy(() => import("react-quill"));

// Define toolbar options with h1 to h6 header
const toolbarOptions = [
  [{ header: [1, 2, 3, 4, 5, 6] }], // Headers from h1 to h6
  [{ size: ["small", false, "large", "huge"] }],
  ["bold", "italic", "underline", "strike"],
  [{ color: [] }, { background: [] }],
  [{ align: [] }],
  ["link", "image"],
  [{ list: "ordered" }, { list: "bullet" }],
  ["code-block"],
  ["clean"],
];

// Define formats for the editor
const formats = [
  "header", // For headers (h1 to h6)
  "size", // For text size
  "bold",
  "italic",
  "underline",
  "strike", // Basic text styles
  "color",
  "background", // Text color and background
  "align", // Text alignment
  "link",
  "image", // For media insertion
  "list",
  "bullet", // Ordered and unordered lists
  "code-block", // Code block
];

const RichTextEditor = forwardRef<RichTextEditorRef, RichTextEditorProps>(
  ({ className, value, onChange, ...props }, ref) => {
    const handleChange = useCallback(
      (content: string) => {
        // Remove all empty paragraphs, divs, and normalize spaces
        const cleanedContent = content
          .replace(/<p>(<br>|\s|&nbsp;)*<\/p>/g, "") // Remove empty <p> tags
          .replace(/<div>(<br>|\s|&nbsp;)*<\/div>/g, "") // Remove empty <div> tags
          .replace(/(&nbsp;|\s)+/g, " ") // Normalize spaces and non-breaking spaces
          .trim(); // Trim leading and trailing spaces

        if (onChange) {
          onChange(cleanedContent);
        }
      },
      [onChange]
    );

    return (
      <div className={cn(styles.richTextContainer)}>
        <Suspense fallback={<div>Loading...</div>}>
          <LazyReactQuill
            ref={ref}
            value={value}
            onChange={handleChange}
            modules={{
              toolbar: toolbarOptions,
              imageResize: { modules: ["Resize"] },
            }}
            formats={formats}
            className={cn(
              styles.richTextContainer,
              className,
              "border border-secondary-300"
            )}
            theme="snow" /* Make sure the snow theme is applied */
            {...props}
          />
        </Suspense>
      </div>
    );
  }
);

RichTextEditor.displayName = "RichTextEditor";

export default RichTextEditor;
