import { useQuery } from "@apollo/client";
import { GET_SUB_CATEGORY_DATA } from "src/graphql/query";

//Top Ten business table
export type Business = {
  endDate: any;
  startDate: any;
  _id: string;
  businessProfileId: string;
  subCategoryId: string;
  rank: number;
  title: string;
  description: string;
  pinCodeId: string | null;
};

export type TopBusinessProps = {
  initialBusinesses: Business[];
  subCategoryName: string;
  reload: any;
};
export const enum BusinessType {
  TopThree = "TopThree",
  TopTen = "TopTen",
}

//Top business Modal
export interface TopBusinessModalProps {
  open: boolean;
  handleClose: () => void;
  newBusiness?: any;
  isEditBusiness?: boolean;
  businessProfiles?: { _id: string; businessName: string }[];
  setNewBusiness?: (business: any) => void;
  handleAddBusiness?: (values: any) => void;
  handleSaveEdit?: (values: any) => void;
  usedRanks: number[];
}

//BlogModal
export interface BlogModalProps {
  handleClose: () => void;
  newBlog: {
    _id?: string;
    title?: string;
    image?: string;
    publishedDate?: string;
    publishedBy?: string;
    description?: string;
    subCategoryId?: string;
  };
  setNewBlog: React.Dispatch<React.SetStateAction<any>>;
  isEdit?: boolean;
}

//BlogTable
export type Blog = {
  _id?: string;
  title: string;
  image: string;
  publishedDate: string;
  publishedBy: string;
  description: string;
  createdBy: string;
  subCategoryId: string | null;
  blogTitleReadingTime: string | null;
  blogDescriptionReadingTime: string | null;
};

//Faq Modal Props
export type FaqModalProps = {
  open: boolean;
  faqData: { question: string; answer: string };
  setFaqData: React.Dispatch<
    React.SetStateAction<{ question: string; answer: string }>
  >;
  handleClose: () => void;
  handleSaveEdit: () => void;
  isEditMode: boolean;
};

//Faq Table
export type Faq = {
  _id: string;
  question: string;
  answer: string;
};

export type FaqProps = {
  initialFaqs: Faq[];
  reload: any;
};

//Main Page
export type TabData = {
  [key: string]: React.ReactNode;
};

//SubCategoryModal
export interface CategoryModalTypes {
  open: boolean;
  handleClose: () => void;
  category?: any;
  refetchCategory: () => void;
}

//TagModal
export interface TagModalProps {
  open: boolean;
  handleClose: any;
  newTag?: any;
  isEditTag?: boolean;
  setNewTag?: any;
  handleAddTag?: any;
  handleSaveEdit?: any;
}

//Tag Table
export type Tag = {
  _id: string;
  key: string;
  value: string;
  subCategoryId: string;
};

export type TagProps = {
  initialTags: Tag[];
  reload: any;
};
