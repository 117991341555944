import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  TextField,
  Typography,
  Divider,
  debounce,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { useQuery } from "@apollo/client";
import moment from "moment";
import CustomTable from "src/components/CustomTable";
import Loader from "src/components/Loader";
import ErrorComponent from "src/components/ErrorComponent";
import { GET_ALL_TRACKING_DATA } from "src/graphql/query";
import Select from "react-select";
import { typeEnumOptions } from "./TrackingData";
import { ClearIcon } from "@mui/x-date-pickers";

interface TrackingData {
  _id: string;

  entityId: string;
  entityType: string;
  actionType: string;
  timestamp: string;
  entityDetails: {
    businessProfile?: { businessName: string; brandLogo: string };
    deal?: { title: string; image: string };
    popularLocation?: { title: string; images: string[] };
    event?: { title: string; images: string[] };
  };
}

const TrackingTable: React.FC = () => {
  const [list, setList] = useState<TrackingData[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [pagination, setPagination] = useState({ page: 0, pageSize: 50 });
  const [total, setTotal] = useState(0);
  const [filterValue, SetFilterValue] = useState<any>();

  const COLUMNS = [
    { headerName: "Entity ID", field: "entityId", flex: 1 },
    { headerName: "Entity Type", field: "entityType", flex: 1 },
    { headerName: "Action Type", field: "actionType", flex: 1 },
    {
      headerName: "Image",
      field: "image",
      flex: 1,
      renderCell: ({ row }: { row: TrackingData }) => {
        const { deal, popularLocation, event, businessProfile } =
          row.entityDetails;
        const imageUrl =
          deal?.image ||
          popularLocation?.images?.[0] ||
          event?.images?.[0] ||
          businessProfile?.brandLogo ||
          "";

        return imageUrl ? (
          <img
            src={imageUrl}
            alt="entity image"
            style={{
              width: 50,
              height: 50,
              objectFit: "cover",
              borderRadius: 4,
            }}
          />
        ) : (
          <span>No Image</span>
        );
      },
    },
    {
      headerName: "Title",
      field: "title",
      flex: 1,
      renderCell: ({ row }: { row: TrackingData }) => {
        const { deal, popularLocation, event, businessProfile } =
          row.entityDetails;
        const title =
          deal?.title ||
          popularLocation?.title ||
          event?.title ||
          businessProfile?.businessName ||
          "";

        return title ? (
          <Box display={"flex"} alignItems={"center"}>
            <Typography my={1}>{title}</Typography>
          </Box>
        ) : (
          <span>No Title</span>
        );
      },
    },
    {
      headerName: "Timestamp",
      field: "timestamp",
      flex: 1,
      renderCell: ({ value }: { value: string }) => (
        <span>{moment(value).format("DD-MM-YYYY HH:mm:ss")}</span>
      ),
    },
  ];

  const { loading, error, data, refetch } = useQuery(GET_ALL_TRACKING_DATA, {
    variables: {
      page: pagination.page + 1,
      limit: pagination.pageSize,
      searchTerm,
    },
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    if (data?.getAllTrackingData?.data) {
      setList(data.getAllTrackingData.data);
    }
    setTotal(data?.getAllTrackingData?.count);
  }, [data]);

  const debouncedRefetch = useCallback(
    debounce((term: string) => {
      setSearchTerm(term);
      refetch({
        search: term,
        page: pagination.page + 1,
        limit: pagination.pageSize,
      });
    }, 1000),
    [refetch, pagination.page, pagination.pageSize]
  );
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchTerm(value);
    debouncedRefetch(value);
  };

  const handlePageChange = (newPage: number) => {
    setPagination((prev) => ({ ...prev, page: newPage }));
    refetch({
      search: searchTerm,
      page: newPage + 1,
      limit: pagination.pageSize,
    });
  };

  const handlePageSizeChange = (newPageSize: number) => {
    setPagination((prev) => ({ ...prev, pageSize: newPageSize }));
    refetch({
      search: searchTerm,
      page: pagination.page + 1,
      limit: newPageSize,
    });
  };

  const handleCategoryChange = (option: any) => {
    SetFilterValue(option ? option.value : null);
    refetch({
      entityType: option ? option.value : null,
    });
  };

  const handleClearSearch = () => {
    setSearchTerm("");
    setPagination((prev) => ({ ...prev, page: 1 }));
    refetch({ search: "", page: 1, limit: pagination.pageSize });
  };

  if (loading) return <Loader />;
  if (error) return <ErrorComponent />;

  return (
    <Box p={2}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
        paddingRight={4}
      >
        <Typography variant="h4" style={{ color: "#00C5B9" }}>
          Tracking Data
        </Typography>
        <Box display="flex" alignItems="center" gap={2}>
          <Box width={200}>
            <TextField
              label="Search"
              variant="outlined"
              size="small"
              value={searchTerm}
              onChange={handleSearchChange}
              InputProps={{
                endAdornment: searchTerm && (
                  <InputAdornment position="end">
                    <IconButton onClick={handleClearSearch}>
                      <ClearIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Box my={4} width={150}>
            <Select
              options={typeEnumOptions}
              value={typeEnumOptions.find(
                (option) => option.value === filterValue
              )}
              onChange={handleCategoryChange}
              placeholder="Filter "
              isClearable
            />
          </Box>
        </Box>
      </Box>
      <Divider />
      <Box mt={2}>
        <CustomTable
          columns={COLUMNS}
          data={list}
          paginationModel={{
            page: pagination.page,
            pageSize: pagination.pageSize,
          }}
          totalCount={total}
          onPageChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
        />
      </Box>
    </Box>
  );
};

export default TrackingTable;
