import SignIn from "src/pages/Auth/SignIn";
import Pincode from "src/pages/Pincode";
import Events from "src/pages/Events";
import Category from "src/pages/Category";
import Faq from "src/pages/Faq";
import { ROUTES } from "./constant";
import Blog from "src/pages/Blog";
import Deal from "./pages/Deal";
import TestData from "./components/TestData";
import Popularlocations from "./pages/PopularLocation";
import Advertise from "./pages/Advertise";
import Business from "./pages/Business";
import ContactUs from "./pages/ContactUs";
import Tags from "./pages/Tags";
import SubcategoryTabs from "./pages/SubCategory";
import HomePageSettings from "./pages/HomePageSettings";
import TrackingData from "./pages/TrackingData";
import ClaimBusiness from "./pages/ClaimBusiness";
import Permission from "./pages/Permission";
import AddEditPermissionPage from "./pages/Permission/AddEditPermissionPage";
import Users from "src/pages/Users";
import {
  getIsEnvAdmin,
  getPermissions,
  getRole,
  hasPermission,
} from "./components/Common/Utils";
import ConfigDataList from "./pages/Config/ConfigDataList";
import BusinessProfileView from "./pages/Business/BusinessProfile";
import BusinessModal from "./pages/Business/BusinessModal";
import BusinessSuperDetails from "./pages/Business/BusinessSuperDetails";
import AllEnum from "./pages/Enum";
import BlogPage from "./pages/SubCategory/BlogModal";
import HistoryDataList from "./pages/History";
import HistoryModal from "./pages/History/HIstoryModal";
import AboutDataList from "./pages/AboutSurat";
import AboutSuratModal from "./pages/AboutSurat/AboutSuratModal";
import SubcategoryRank from "./pages/SubcategoryRank";

// Check user's role and permissions
const isSuperAdmin = getRole() === "SuperAdmin";
const isEnvAdmin = getIsEnvAdmin();

const permissions = getPermissions();

// Define Route Type
type Route = {
  path: string;
  name: string;
  component: React.ComponentType<any>;
  isPrivate: boolean;
  layout: string;
};

// Utility function to build routes based on permissions
const buildRoute = (
  path: string,
  name: string,
  component: React.ComponentType<any>,
  requiredType?: string,
  requiredAction?: any
): Route | null => {
  const canAccess =
    !requiredType || hasPermission(permissions, requiredType, requiredAction);

  return canAccess
    ? {
        path,
        name,
        component,
        isPrivate: true,
        layout: "private",
      }
    : null;
};

// Define Routes

export const AuthRoutesList: Route[] = [
  // Auth Route

  {
    path: ROUTES.LOGIN,
    name: "SignIn",
    component: SignIn,
    isPrivate: false,
    layout: "auth",
  },
];
export const RoutesList: Route[] = [
  // Public Route

  // Routes with Permission Checks
  buildRoute(ROUTES.PINCODES, "Pincode", Pincode, "PinCode", "view"),
  buildRoute(ROUTES.EVENTS, "Events", Events, "Event", "view"),
  buildRoute(ROUTES.CATEGORY, "Category", Category, "Category", "view"),
  buildRoute(
    ROUTES.CATEGORY_DETAILS,
    "SubCategory",
    TestData,
    "Category",
    "view"
  ),
  buildRoute(
    ROUTES.SUB_CATEGORY_DETAILS,
    "SubCategory",
    SubcategoryTabs,
    "SubCategory",
    "view"
  ),
  buildRoute(
    ROUTES.POPULAR_LOCATION,
    "PopularLocation",
    Popularlocations,
    "PopularLocation",
    "view"
  ),
  buildRoute(
    ROUTES.BUSSINESS_DETAILS,
    "Business",
    BusinessProfileView,
    "Business",
    "view"
  ),
  buildRoute(
    ROUTES.BUSSINESS_CREATE,
    "Business Create",
    BusinessModal,
    "Business",
    "add"
  ),
  buildRoute(
    ROUTES.BUSSINESS_EDIT,
    "Business Edit",
    BusinessModal,
    "Business",
    "update"
  ),
  buildRoute(
    ROUTES.BUSSINESS_SUPER_DETAILS,
    "Business Super details",
    BusinessSuperDetails,
    "Business",
    "view"
  ),
  buildRoute(ROUTES.FAQ, "Faq", Faq, "Faq", "view"),
  buildRoute(ROUTES.BLOGS, "Blog", Blog, "Blog", "view"),
  buildRoute(ROUTES.BLOGS_ADD, "Blog", BlogPage, "Blog", "add"),
  buildRoute(ROUTES.BLOGS_UPDATE, "Blog", BlogPage, "Blog", "update"),
  buildRoute(ROUTES.DEALS, "Deal", Deal, "Deals", "view"),
  buildRoute(ROUTES.ADVERTISE, "Advertise", Advertise, "AdsWithUs", "view"),
  buildRoute(ROUTES.BUSINESS, "Business", Business, "Business", "view"),
  buildRoute(
    ROUTES.CLAIM_BUSINESS,
    "Claim Business",
    ClaimBusiness,
    "ClaimBusiness",
    "view"
  ),
  buildRoute(ROUTES.CONTACT_US, "Contact Us", ContactUs, "ContactUs", "view"),
  buildRoute(ROUTES.TAGS, "Tags", Tags, "Tags", "view"),
  buildRoute(
    ROUTES.HOME_PAGE_SETTINGS,
    "Home Page Settings",
    HomePageSettings,
    "HomePageSetting",
    "view"
  ),
  buildRoute(
    ROUTES.TRACKING_DATA,
    "Tracking Data",
    TrackingData,
    "TrackingData",
    "view"
  ),
  buildRoute(ROUTES.ENUM, "Enum", AllEnum, "DealsEnum", "view"),
  buildRoute(ROUTES.CONFIG, "Config", ConfigDataList, "ConfigData", "view"),
  buildRoute(ROUTES.HISTORY, "History", HistoryDataList, "History", "view"),
  buildRoute(ROUTES.HISTORY_ADD, "History", HistoryModal, "History", "add"),
  buildRoute(
    ROUTES.HISTORY_UPDATE,
    "History",
    HistoryModal,
    "History",
    "update"
  ),
  buildRoute(
    ROUTES.ABOUT_SURAT,
    "AboutSurat",
    AboutDataList,
    "AboutSurat",
    "view"
  ),
  buildRoute(
    ROUTES.ABOUT_SURAT_ADD,
    "AboutSurat",
    AboutSuratModal,
    "AboutSurat",
    "add"
  ),
  buildRoute(
    ROUTES.ABOUT_SURAT_UPDATE,
    "AboutSurat",
    AboutSuratModal,
    "AboutSurat",
    "update"
  ),
  buildRoute(
    ROUTES.SUB_CATEGORY_RANK,
    "SubcategoryRank",
    SubcategoryRank,
    "SubcategoryRank",
    "view"
  ),

  // Super Admin Routes
  ...(isSuperAdmin || isEnvAdmin
    ? [
        {
          path: ROUTES.PERMISSION,
          name: "Permission",
          component: Permission,
          isPrivate: true,
          layout: "private",
        },
        {
          path: ROUTES.ADD_PERMISSION,
          name: "Add Permission",
          component: AddEditPermissionPage,
          isPrivate: true,
          layout: "private",
        },
        {
          path: ROUTES.UPDATE_PERMISSION,
          name: "Edit Permission",
          component: AddEditPermissionPage,
          isPrivate: true,
          layout: "private",
        },
        {
          path: ROUTES.USERS,
          name: "Users",
          component: Users,
          isPrivate: true,
          layout: "private",
        },
      ]
    : []),
].filter((route): route is Route => route !== null);
