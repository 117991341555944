import React, { useEffect, useState, useCallback } from "react";
import {
  Box,
  TextField,
  Typography,
  Divider,
  InputAdornment,
  Checkbox,
  Drawer,
} from "@mui/material";
import { useQuery, useMutation } from "@apollo/client";
import UserModal from "./UserModal";
import {
  DELETE_USER,
  RESTORE_USER,
  UPDATE_PASSWORD,
} from "src/graphql/mutations";
import { GET_USERS } from "src/graphql/query";
import { debounce } from "src/utils/debounce";
import CustomTable from "src/components/CustomTable";
import Loader from "src/components/Loader";
import ErrorComponent from "src/components/ErrorComponent";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ClearIcon from "@mui/icons-material/Clear";
import LockResetIcon from "@mui/icons-material/LockReset";
import CustomButton from "src/components/CustomButton";
import { toast } from "react-toastify";
import { filterOptions, platformOptions, roleOptions, UserTypes } from "./User";
import DeleteModel from "src/components/Common/DeleteModel";
import {
  DELETE_ALL_USER,
  DELETE_MULTIPLE_USER,
} from "src/graphql/DeleteMutation";
import RestoreIcon from "@mui/icons-material/Restore";
import { getRole, hasPermissionPage } from "src/components/Common/Utils";
import moment from "moment";
import MenuIcon from "@mui/icons-material/Menu";
import Select from "react-select";
import ActionMenu from "../Business/ActionMenu";
import ResetPasswordDialog from "./ResetPasswordDialog";
import ToggleSwitch from "src/components/Common/ToggleSwitch";

const User: React.FC = () => {
  const [list, setList] = useState<UserTypes[]>([]);
  const [selectedUser, setSelectedUser] = useState<UserTypes | null>(null);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [openConfirmDialog, setOpenConfirmDialog] = useState<boolean>(false);
  const [userToDelete, setUserToDelete] = useState<UserTypes | null>(null);
  const [pagination, setPagination] = useState({ page: 1, pageSize: 50 });
  const [total, setTotal] = useState(0);
  const [deleteUser] = useMutation(DELETE_USER);
  const [openDeleteMultipleModel, setOpenDeleteMultipleModel] =
    useState<boolean>(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [openDeleteAllModel, setOpenDeleteAllModel] = useState<boolean>(false);
  const [DeleteMultipleUsers] = useMutation(DELETE_MULTIPLE_USER);
  const [DeleteAllUser] = useMutation(DELETE_ALL_USER);
  const [RestoreUser] = useMutation(RESTORE_USER);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedRole, setSelectedRole] = useState<string | null>(null);
  const isSuperAdmin = getRole() === "SuperAdmin";
  const [updatePasswordByAdmin] = useMutation(UPDATE_PASSWORD);
  const [openDialog, setOpenDialog] = useState(false);
  const [userIdForReset, setUserIdForReset] = useState(null);
  const [selectedFilter, setSelectedFilter] = useState<string | null>(null);
  const [selectedPlatform, setSelectedPlatform] = useState<string | null>(null);
  const [isDeletedAt, setIsDeletedAt] = useState<boolean>(false);
  const COLUMNS = [
    ...(isSuperAdmin
      ? [
          {
            headerName: (
              <Box display={"flex"} alignItems={"center"}>
                <Checkbox
                  checked={
                    selectedRows.length === list.length && list.length > 0
                  }
                  indeterminate={
                    selectedRows.length > 0 && selectedRows.length < list.length
                  }
                  onChange={(event) => handleSelectAll(event.target.checked)}
                />
              </Box>
            ),
            field: "select",
            flex: 0.5,
            sortable: false,
            filterable: false,
            renderCell: ({ row }: any) => (
              <Checkbox
                checked={selectedRows?.includes(row?._id as never)}
                onChange={() => handleRowSelect(row._id)}
              />
            ),
            headerClassName: "no-hover",
          },
        ]
      : []),
    {
      headerName: "Name",
      field: "firstName",
      flex: 1,
      renderCell: ({ row }: any) => (
        <Box>
          {row?.firstName} {row?.lastName}
        </Box>
      ),
    },
    { headerName: "Email", field: "email", flex: 1 },
    { headerName: "Role", field: "role", flex: 1 },
    { headerName: "Mobile Number", field: "mobileNumber", flex: 1 },
    { headerName: "Profession", field: "profession", flex: 1 },
    {
      headerName: "Listed",
      field: "createdAt",
      flex: 1,
      renderCell: ({ row }: any) => (
        <Box>{moment(row?.createdAt).format("DD-MM-YYYY")}</Box>
      ),
    },
    {
      headerName: "Login Flow",
      field: "loginFlow",
      flex: 1,
      renderCell: ({ row }: any) => {
        let loginFlow = "Manual";
        let platform = "None";

        if (row.googleId) {
          loginFlow = "Google";
          platform = `Google ID: ${row.googleId}`;
        } else if (row.appleId) {
          loginFlow = "Apple";
          platform = `Apple ID: ${row.appleId}`;
        }

        return (
          <Box>
            <Typography fontWeight="bold" color="primary" mt={2}>
              {loginFlow}
            </Typography>
          </Box>
        );
      },
    },
    {
      headerName: "PlatFrom",
      field: "platFrom",
      flex: 1,
    },
    {
      headerName: "Status",
      field: "deletedAt",
      flex: 1,
      renderCell: ({ row }: any) => {
        if (row.deletedAt) {
          return (
            <div
              title={`Deleted on: ${new Date(row.deletedAt).toLocaleString()}`}
            >
              <span style={{ color: "red", fontWeight: "bold" }}>Deleted</span>
            </div>
          );
        } else {
          return (
            <div title="Active">
              <span style={{ color: "green", fontWeight: "bold" }}>Active</span>
            </div>
          );
        }
      },
    },
    {
      headerName: "Reset",
      field: "reset",
      flex: 0.5,
      renderCell: ({ row }: { row: UserTypes }) => (
        <Box>
          {/* Reset Password Button */}
          <IconButton
            onClick={() => handleResetPassword(row._id)}
            aria-label="reset-password"
            style={{ color: "#FF9800", marginLeft: "8px" }}
          >
            <LockResetIcon />
          </IconButton>
        </Box>
      ),
    },
    {
      headerName: "Actions",
      flex: 1,
      renderCell: ({ row }: { row: UserTypes }) => (
        <Box>
          {hasPermissionPage("user", "update") && (
            <IconButton
              onClick={() => handleEdit(row)}
              aria-label="edit"
              style={{ marginRight: "8px", color: "#00C5B9" }}
            >
              <EditIcon />
            </IconButton>
          )}
          {hasPermissionPage("user", "delete") && (
            <IconButton
              onClick={() => handleDelete(row)}
              aria-label="delete"
              style={{ color: "#00C5B9" }}
            >
              <DeleteIcon />
            </IconButton>
          )}
          <IconButton
            onClick={() => handleRestore(row)}
            aria-label="Restore"
            style={{ color: "#7CB342" }}
          >
            <RestoreIcon />
          </IconButton>
        </Box>
      ),
    },
  ];

  const handleRestore = async (data: any) => {
    try {
      const res = await RestoreUser({
        variables: {
          _id: data?._id,
        },
      });
      if (res?.data?.restoreUser?.statusCode === 200) {
        toast.success(res?.data?.restoreUser?.message);
        refetch();
      } else {
        throw new Error(res?.data?.restoreUser?.message);
      }
    } catch (error: any) {
      console.error(error);
      toast.error(error.message);
    }
  };

  const { loading, error, data, refetch } = useQuery(GET_USERS, {
    variables: {
      page: pagination.page,
      limit: pagination.pageSize,
      role: selectedRole ?? "",
      filter: selectedFilter ?? "",
      platFrom: selectedPlatform ?? "",
      deletedAt: isDeletedAt ?? false,
    },
  });
  const handleResetPasswordSubmit = async (newPassword: any) => {
    try {
      // Call the mutation to reset the password
      const { data } = await updatePasswordByAdmin({
        variables: {
          _id: userIdForReset,
          newPassword: newPassword,
        },
      });

      if (data?.updatePasswordByAdmin?.statusCode === 200) {
        toast.success("Password reset successful!");
      } else {
        toast.success("Failed to reset password.");
      }
    } catch (error) {
      console.error("Error resetting password:", error);
      toast.error("Error occurred while resetting the password.");
    }
  };

  const handleResetPassword = (userId: any) => {
    setUserIdForReset(userId);
    setOpenDialog(true);
  };

  const handleRowSelect = (id: any) => {
    setSelectedRows((prevSelectedRows: any) =>
      prevSelectedRows.includes(id)
        ? prevSelectedRows.filter((rowId: any) => rowId !== id)
        : [...prevSelectedRows, id]
    );
  };
  const handleSelectAll = (isSelected: boolean) => {
    if (isSelected) {
      const allRowIds = list.map((row: any) => row._id);
      setSelectedRows(allRowIds as any);
    } else {
      setSelectedRows([]);
    }
  };

  const handleMultipleDelete = async () => {
    try {
      const res = await DeleteMultipleUsers({
        variables: {
          _id: selectedRows,
        },
      });
      if (res?.data?.deleteMultipleUsers?.statusCode === 200) {
        toast.success(res?.data?.deleteMultipleUsers?.message);
        refetch();
      } else {
        throw new Error(res?.data?.deleteMultipleUsers?.message);
      }
    } catch (error: any) {
      console.error(error);
      toast.error(error?.message);
    } finally {
      setOpenDeleteMultipleModel(false);
    }
  };

  const handleAllDelete = async () => {
    try {
      const res = await DeleteAllUser();
      if (res?.data?.deleteAllUser?.statusCode === 200) {
        toast.success(res?.data?.deleteAllUser?.message);
        refetch();
      } else {
        throw new Error(res?.data?.deleteAllUser?.message);
      }
    } catch (error: any) {
      console.error(error);
      toast.error(error?.message);
    } finally {
      setOpenDeleteAllModel(false);
    }
  };

  const debouncedRefetch = useCallback(
    debounce((term: string) => {
      refetch({
        search: term,
        page: 1,
        limit: pagination.pageSize,
      });
    }, 1000),

    [refetch]
  );

  useEffect(() => {
    if (data?.getUsers?.data) {
      setList(data.getUsers.data);
    }
    setTotal(data?.getUsers?.count);
  }, [data]);

  const handleEdit = (rowData: any) => {
    setSelectedUser(rowData);
    setOpenModal(true);
  };

  const handleDelete = (rowData: UserTypes) => {
    setUserToDelete(rowData);
    setOpenConfirmDialog(true);
  };

  const handleConfirmDelete = async () => {
    try {
      if (userToDelete) {
        const res = await deleteUser({
          variables: { _id: userToDelete._id },
        });

        if (res.errors && res.errors.length > 0) {
          const errorMessage = res.errors[0].message;
          throw new Error(`Error: ${errorMessage}`);
        }

        toast.success("User deleted successfully!");
        setUserToDelete(null);
        setOpenConfirmDialog(false);
        refetch();
      }
    } catch (error: any) {
      toast.error(
        error?.message || "An error occurred while deleting the user."
      );
    }
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedUser(null);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchTerm(value);
    setPagination((prev) => ({ ...prev, page: 1 }));
    debouncedRefetch(value);
  };

  const handleClearSearch = () => {
    setSearchTerm("");
    setPagination((prev) => ({ ...prev, page: 1 }));
    refetch({ search: "", page: 1, limit: pagination.pageSize });
  };

  const handlePageChange = (newPage: number) => {
    setPagination((prev) => ({ ...prev, page: newPage + 1 }));
  };

  const handlePageSizeChange = (newPageSize: number) => {
    setPagination((prev) => ({ ...prev, pageSize: newPageSize }));
  };

  const handleRoleChange = (option: any) => {
    setSelectedRole(option?.value ?? "");
    refetch({
      role: option.value,
    });
  };
  const handleFilterChange = (option: any) => {
    setSelectedFilter(option?.value ?? "");
    refetch({
      role: option.value,
    });
  };
  const handlePlatformFilterChange = (option: any) => {
    setSelectedPlatform(option?.value ?? "");
    refetch({
      platFrom: option.value,
    });
  };
  useEffect(() => {
    refetch({
      deletedAt: isDeletedAt,
    });
  }, [isDeletedAt]);
  if (loading) return <Loader />;

  if (error) return <ErrorComponent />;
  return (
    <Box p={2}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
        paddingRight={4}
      >
        <Typography variant="h4" style={{ color: "#00C5B9" }}>
          Users
        </Typography>
        <Drawer
          anchor="right"
          open={openDrawer}
          onClose={() => setOpenDrawer(false)}
          sx={{ width: 250, flexShrink: 0 }}
        >
          <Box p={2} width={250} mt={10}>
            <Typography variant="h4" gutterBottom style={{ color: "#00C5B9" }}>
              Filters
            </Typography>
            <Box my={4}>
              <Typography variant="h6" gutterBottom>
                Role
              </Typography>
              <Select
                options={roleOptions}
                value={
                  roleOptions?.find(
                    (option: any) => option?.value === selectedRole
                  ) || null
                }
                onChange={handleRoleChange}
                placeholder="Filter by Role"
                isClearable
              />
            </Box>
            <Box my={4}>
              <Typography variant="h6" gutterBottom>
                Login Flow
              </Typography>
              <Select
                options={filterOptions}
                value={
                  filterOptions?.find(
                    (option: any) => option?.value === selectedFilter
                  ) || null
                }
                onChange={handleFilterChange}
                placeholder="Filter by login flow"
                isClearable
              />
            </Box>
            <Box my={4}>
              <Typography variant="h6" gutterBottom>
                Platform
              </Typography>
              <Select
                options={platformOptions}
                value={
                  platformOptions?.find(
                    (option: any) => option?.value === selectedPlatform
                  ) || null
                }
                onChange={handlePlatformFilterChange}
                placeholder="Filter by Platform"
                isClearable
              />
            </Box>
            <Box my={4}>
              <ToggleSwitch
                mode={isDeletedAt}
                setMode={setIsDeletedAt}
                label={"Deleted AT"}
              />
            </Box>
          </Box>
        </Drawer>
        <Box display="flex" alignItems="center" gap={2}>
          <Box width={200}>
            <TextField
              label="Search"
              variant="outlined"
              size="small"
              value={searchTerm}
              onChange={handleSearchChange}
              InputProps={{
                endAdornment: searchTerm && (
                  <InputAdornment position="end">
                    <IconButton onClick={handleClearSearch}>
                      <ClearIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <ActionMenu
            isSuperAdmin={isSuperAdmin}
            setOpenDeleteMultipleModel={setOpenDeleteMultipleModel}
            setOpenDeleteAllModel={setOpenDeleteAllModel}
          />
          {hasPermissionPage("user", "add") && (
            <Box>
              <CustomButton
                onClick={() => setOpenModal(true)}
                variant="contained"
                className="width: 200px"
              >
                Create
              </CustomButton>
            </Box>
          )}
          <Box>
            <IconButton onClick={() => setOpenDrawer(true)} aria-label="menu">
              <MenuIcon />
            </IconButton>
          </Box>
        </Box>
      </Box>
      <Divider />
      <Box mt={2}>
        <CustomTable
          columns={COLUMNS}
          data={list}
          paginationModel={{
            page: pagination.page - 1,
            pageSize: pagination.pageSize,
          }}
          totalCount={total}
          onPageChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
        />
      </Box>
      <UserModal
        open={openModal}
        handleClose={() => handleCloseModal()}
        user={selectedUser}
        refetchUsers={() => refetch()}
      />
      <DeleteModel
        open={openConfirmDialog}
        onClose={() => setOpenConfirmDialog(false)}
        onConfirm={handleConfirmDelete}
        title="Confirm Deletion"
        message="Are you sure you want to delete this user?"
      />
      {openDeleteMultipleModel && (
        <DeleteModel
          open={openDeleteMultipleModel}
          onClose={() => {
            setOpenDeleteMultipleModel(false);
          }}
          onConfirm={handleMultipleDelete}
          message={`Are you sure you want to delete ${
            selectedRows?.length ?? 0
          } users?`}
        />
      )}
      {openDeleteAllModel && (
        <DeleteModel
          open={openDeleteAllModel}
          onClose={() => {
            setOpenDeleteAllModel(false);
          }}
          onConfirm={handleAllDelete}
          message="Are you sure you want to delete all users?"
        />
      )}
      <ResetPasswordDialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        onSubmit={handleResetPasswordSubmit}
      />
    </Box>
  );
};

export default User;
