import React from "react";
import {
  Box,
  Typography,
  Divider,
  Grid,
  Card,
  CardMedia,
  CardContent,
  CardActions,
  Chip,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CustomButton from "src/components/CustomButton";

interface DealDetailPageProps {
  dealData: any;
  onClose: () => void;
}

const DealDetailPage: React.FC<DealDetailPageProps> = ({
  dealData,
  onClose,
}) => {
  if (!dealData) {
    return (
      <Box
        sx={{
          width: "100%",
          maxWidth: "1200px",
          margin: "auto",
          mt: "10%",
          bgcolor: "background.paper",
          borderRadius: 2,
          p: 3,
          textAlign: "center",
        }}
      >
        <Typography variant="h5" color="#00C5B9" fontWeight="bold" mb={2}>
          Deal Details
        </Typography>
        <Typography>No deal data available.</Typography>
        <Box mt={3}>
          <CustomButton onClick={onClose} variant="outlined" color="primary">
            Go Back
          </CustomButton>
        </Box>
      </Box>
    );
  }

  const {
    title,
    category,
    subCategoryId,
    area,
    offer,
    offerType,
    description,
    termsAndConditions,
    duration,
    discountBracket,
    repeatDuration,
    image,
  } = dealData;

  return (
    <Box
      sx={{
        width: "100%",
        maxWidth: "1200px",
        margin: "auto",
        mt: "5%",
        p: 2,
        borderRadius: 3,
        bgcolor: "background.paper",
        boxShadow: 3,
      }}
    >
      <Box
        sx={{
          mb: 2,
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
        }}
        onClick={onClose}
      >
        <Typography variant="h4" style={{ color: "#00C5B9" }}>
          {" "}
          Deal Detail
        </Typography>
        <Box sx={{ cursor: "pointer" }}>
          <CloseIcon />
        </Box>
      </Box>
      <Card sx={{ boxShadow: "none" }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <CardMedia
              component="img"
              height="100%"
              image={image}
              alt={title}
              sx={{
                borderRadius: 2,
                objectFit: "cover",
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CardContent>
              <Typography variant="h4" fontWeight="bold" gutterBottom>
                {title}
              </Typography>
              <Typography variant="subtitle1" color="textSecondary">
                Category: {category} | Subcategory ID: {subCategoryId}
              </Typography>
              <Chip
                label={offer}
                color="primary"
                sx={{ mt: 2, fontSize: "1.1rem", fontWeight: "bold" }}
              />
              <Typography variant="body2" color="textSecondary" sx={{ mt: 2 }}>
                Offer Type: {offerType}
              </Typography>
              <Typography variant="body1" sx={{ mt: 2 }}>
                <strong>Description:</strong> {description}
              </Typography>
              <Typography variant="body2" color="textSecondary" sx={{ mt: 2 }}>
                <strong>Area:</strong> {area}
              </Typography>
              <Typography variant="body2" color="textSecondary" sx={{ mt: 2 }}>
                <strong>Terms & Conditions:</strong> {termsAndConditions}
              </Typography>
            </CardContent>
          </Grid>
        </Grid>
        <Divider sx={{ mt: 2, mb: 2 }} />
        <CardActions>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Typography variant="body2">
                <strong>Duration:</strong>{" "}
                {new Date(duration?.startDate).toLocaleDateString()} -{" "}
                {new Date(duration?.endDate).toLocaleDateString()}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="body2">
                <strong>Discount Bracket:</strong> {discountBracket?.minimum}% -{" "}
                {discountBracket?.maximum}%
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="body2">
                <strong>Repeat:</strong> {repeatDuration?.repeat}
              </Typography>
            </Grid>
          </Grid>
        </CardActions>
        <Box mt={2} display="flex" justifyContent="flex-end">
          <CustomButton onClick={onClose} variant="contained" color="primary">
            Close
          </CustomButton>
        </Box>
      </Card>
    </Box>
  );
};

export default DealDetailPage;
