import React, { useState } from "react";
import { Box, Tabs, Tab, Typography, Divider } from "@mui/material";
import PopularLocationEnum from "../PopularLocationEnum";
import EventEnum from "../EventEnum";
import DealEnum from "../DealEnum";

function TabPanel({ children, value, index, ...other }: any) {
  return (
    <div role="tabpanel" hidden={value !== index} {...other}>
      {value === index && <Box p={2}>{children}</Box>}
    </div>
  );
}

function AllEnum() {
  const [tabIndex, setTabIndex] = useState(0);

  const handleTabChange = (event: any, newIndex: any) => {
    setTabIndex(newIndex);
  };

  return (
    <Box p={2}>
      <Typography variant="h4" style={{ color: "#00C5B9", marginBottom: 2 }}>
        Manage Enums
      </Typography>
      <Divider />
      <Tabs
        value={tabIndex}
        onChange={handleTabChange}
        aria-label="content tabs"
      >
        <Tab label="Events" />
        <Tab label="Deals" />
        <Tab label="Popular Location" />
      </Tabs>
      <Divider />

      <TabPanel value={tabIndex} index={0}>
        <EventEnum />{" "}
      </TabPanel>
      <TabPanel value={tabIndex} index={1}>
        <DealEnum />{" "}
      </TabPanel>
      <TabPanel value={tabIndex} index={2}>
        <PopularLocationEnum />{" "}
      </TabPanel>
    </Box>
  );
}

export default AllEnum;
