import { FormControl } from "@mui/material";
import React from "react";
import Select, { SingleValue } from "react-select";

// Define types for props
interface BusinessOption {
  value: string;
  label: string;
}

interface BusinessSelectDropDownProps {
  selectedBusiness: string | null;
  handleBusinessChange: (selectedOption: SingleValue<BusinessOption>) => void;
  businessOptions: BusinessOption[];
  setSearchTerm?: (term: string) => void;
}

const BusinessSelectDropDown: React.FC<BusinessSelectDropDownProps> = ({
  selectedBusiness,
  handleBusinessChange,
  businessOptions,
  setSearchTerm,
}) => {
  const handleInputChange = (inputValue: string) => {
    setSearchTerm && setSearchTerm(inputValue);
    return inputValue;
  };

  const onChange = (selectedOption: SingleValue<BusinessOption>) => {
    handleBusinessChange(selectedOption);

    // Clear search term only if no selection is made
    if (!selectedOption && setSearchTerm) {
      setSearchTerm("");
    }
  };
  return (
    <FormControl fullWidth sx={{ zIndex: "9999" }}>
      <Select
        name="businessProfileId"
        value={
          businessOptions.find((option) => option.value === selectedBusiness) ||
          null
        }
        maxMenuHeight={300}
        placeholder="Select Business"
        options={businessOptions}
        onChange={onChange}
        onInputChange={handleInputChange}
        isClearable
        openMenuOnClick
      />
    </FormControl>
  );
};

export default BusinessSelectDropDown;
