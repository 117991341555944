import { ApolloProvider } from "@apollo/client";
import React, { useEffect, useState } from "react";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import "./App.css";
import client from "./apolloClient";
import AuthLayout from "./layout/AuthLayout";
import PrivateLayout from "./layout/PrivateLayout";
import { AuthRoutesList, RoutesList } from "./routes";
import { useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getToken } from "./components/Common/Utils";
import { ROUTES } from "./constant";
import NotFound from "./404";
import Loader from "./components/Loader";

const App = () => {
  const userDataSelector = useSelector((state: any) => state.authReducer);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const user = userDataSelector.users;
  const isLogin = Object.keys(user);
  const tokenFromStorage = getToken();
  const getRoute: any = localStorage.getItem("permissionRoute");
  const getRouteList = tokenFromStorage ? RoutesList : AuthRoutesList;

  const renderRoutes = () => {
    const renderRoute = (
      Component: React.FC,
      layout: string,
      isPrivate: boolean
    ) => {
      if (Component) {
        switch (layout) {
          case "private":
            return isLogin && isPrivate && tokenFromStorage ? (
              <PrivateLayout>
                <Component />
              </PrivateLayout>
            ) : (
              <Navigate to={ROUTES.EVENTS} />
            );
          case "auth":
          default:
            return (
              <AuthLayout>
                <Component />
              </AuthLayout>
            );
        }
      }
      return null;
    };

    return getRouteList?.map((route: any) => (
      <Route
        key={route.name}
        path={route.path}
        element={renderRoute(route.component, route.layout, route.isPrivate)}
      />
    ));
  };

  useEffect(() => {
    // Simulate token retrieval delay
    setTimeout(() => {
      if (!tokenFromStorage) {
        navigate("/login");
      }
      setIsLoading(false);
    }, 1000); // Adjust delay as needed
  }, [navigate]);

  if (isLoading) return <Loader />;

  return (
    <div className="App">
      <ApolloProvider client={client}>
        <Routes>
          {renderRoutes()}
          <Route path="*" element={<NotFound />} />
          <Route path={getRoute} element={<Navigate to={getRoute} replace />} />
        </Routes>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          style={{
            zIndex: 999999999,
          }}
        />
      </ApolloProvider>
    </div>
  );
};

export default App;
