import React from "react";
import {
  Modal,
  Box,
  Typography,
  TextField,
  IconButton,
  DialogActions,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CustomButton from "src/components/CustomButton";
import { FaqModalProps } from "./SubCategory";
import styles from "./SubCategory.module.css";
const FaqModal: React.FC<FaqModalProps> = ({
  open,
  faqData,
  setFaqData,
  handleClose,
  handleSaveEdit,
  isEditMode,
}) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFaqData((prev) => ({ ...prev, [name]: value }));
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box className={styles.modalBox}>
        {" "}
        <Box className={styles.modalHeader}>
          {" "}
          <Typography variant="h6">
            {isEditMode ? "Edit FAQ" : "Add FAQ"}
          </Typography>
        </Box>
        <Box mt={2}>
          <TextField
            fullWidth
            label="Question"
            name="question"
            value={faqData.question}
            onChange={handleChange}
            margin="normal"
          />
          <TextField
            fullWidth
            label="Answer"
            name="answer"
            value={faqData.answer}
            onChange={handleChange}
            margin="normal"
            multiline
            rows={4}
          />
        </Box>
        <DialogActions className={styles.dialogActions}>
          {" "}
          {/* Styled with CSS */}
          <CustomButton onClick={() => handleClose()} variant="outlined">
            Cancel
          </CustomButton>
          <CustomButton onClick={handleSaveEdit} variant="contained">
            {isEditMode ? "Save Changes" : "Add FAQ"}
          </CustomButton>
        </DialogActions>
      </Box>
    </Modal>
  );
};

export default FaqModal;
