import React, { useEffect, useState } from "react";
import CheckText from "./CheckText";
import OverviewLabel from "./OverviewLabel";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Box,
  Button,
} from "@mui/material";
import CustomButton from "src/components/CustomButton";

const WrapperComponent: React.FC<{
  children: React.ReactNode;
  heading?: string;
}> = ({ children, heading }) => (
  <Box
    sx={{
      display: "flex",
      flexDirection: "column",
      gap: 2,
      padding: 2,
    }}
    data-cy="overViewDetail"
  >
    {heading && (
      <Typography
        variant="h5"
        sx={{
          fontWeight: 600,
          fontFamily: "Primary-Font",
        }}
        data-cy="overViewHeading"
      >
        {heading}
      </Typography>
    )}
    {children}
  </Box>
);

const Overview: React.FC<any> = ({ overview }) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);

  return (
    <>
      {overview?.[0]?.about && (
        <WrapperComponent heading="About">
          <Typography
            variant="body1"
            sx={{
              lineHeight: 1.5,
            }}
            dangerouslySetInnerHTML={{ __html: overview?.[0]?.about }}
            data-cy="aboutDescription"
          />
        </WrapperComponent>
      )}

      {overview?.[0]?.dynamicOptions?.length > 0 && (
        <WrapperComponent heading="Services">
          <OverviewLabel checkTextData={overview?.[0]?.dynamicOptions} />
          <CustomButton
            variant="outlined"
            onClick={handleOpenModal}
            sx={{
              bgcolor: "#E5E7EB",
              color: "#141414",
              fontSize: "16px",
              textTransform: "none",
              fontWeight: 600,
              width: "100%",
              "&:hover": {
                bgcolor: "#D1D5DB",
              },
            }}
          >
            View more
          </CustomButton>
        </WrapperComponent>
      )}

      <Dialog
        open={isModalOpen}
        onClose={handleCloseModal}
        fullWidth
        maxWidth="md"
        sx={{ marginY: 20 }}
      >
        <DialogTitle>
          <Typography variant="h5" sx={{ fontWeight: 500 }}>
            Services
          </Typography>
        </DialogTitle>
        <DialogContent>
          <CheckText checkTextData={overview?.[0]?.dynamicOptions} />
        </DialogContent>
        <DialogActions>
          <CustomButton onClick={handleCloseModal} color="primary">
            Close
          </CustomButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Overview;
