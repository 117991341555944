import { getToken, setToken } from "src/components/Common/Utils";
import { AUTHACTIONProps } from "../interfaces/authInterface";
import { SET_USER_ACTION } from "../types/auth";

const {
  SET_USER_ACTION_REQUEST,
  SET_USER_ACTION_SUCCESS,
  SET_USER_ACTION_FAILURE,
} = SET_USER_ACTION;

const initialState: AUTHACTIONProps = {
  users: {},
  // token: "",
  error: null,
  loading: false,
};

const AuthReducer = (state = initialState, action: any): AUTHACTIONProps => {
  switch (action.type) {
    case SET_USER_ACTION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case SET_USER_ACTION_SUCCESS:
      return {
        ...state,
        users: { ...state.users, ...action.payload.signInByAdmin },
        error: null,
        loading: false,
      };
    case SET_USER_ACTION_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default AuthReducer;
