import React, { useEffect, useState, useCallback } from "react";
import {
  Box,
  TextField,
  Typography,
  Divider,
  InputAdornment,
} from "@mui/material";
import { useQuery, useMutation } from "@apollo/client";
import { GET_HISTORY } from "src/graphql/query";
import { debounce } from "src/utils/debounce";
import CustomTable from "src/components/CustomTable";
import Loader from "src/components/Loader";
import ErrorComponent from "src/components/ErrorComponent";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteModel from "src/components/Common/DeleteModel";
import { toast } from "react-toastify";
import CustomButton from "src/components/CustomButton";
import { ClearIcon } from "@mui/x-date-pickers";
import { getRole, hasPermissionPage } from "src/components/Common/Utils";
import { useNavigate } from "react-router";
import { ROUTES } from "src/constant";
import EditIcon from "@mui/icons-material/Edit";
import { DELETE_HISTORY } from "src/graphql/mutations";

interface History {
  _id?: string;
  description?: string;
  title?: string;
  metaTitle?: string;
  metaDescription?: string;
}

const HistoryDataList: React.FC = () => {
  const [list, setList] = useState<History[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [pagination, setPagination] = useState({ page: 0, pageSize: 50 });
  const [total, setTotal] = useState(0);
  const [deleteHistory] = useMutation(DELETE_HISTORY);
  const [openDeleteModel, setOpenDeleteModel] = useState<boolean>(false);
  const [deleteHistoryId, setDeleteHistoryId] = useState<string | null>(null);
  const isSuperAdmin = getRole() === "SuperAdmin";
  const navigate = useNavigate();
  const COLUMNS = [
    ...(isSuperAdmin ? [] : []),
    { headerName: "Title", field: "title", flex: 1 },
    { headerName: "Description", field: "description", flex: 1 },

    {
      headerName: "Actions",
      flex: 1,
      renderCell: ({ row }: { row: { _id: string } }) => (
        <Box display="flex" alignItems="center">
          {hasPermissionPage("History", "update") && (
            <IconButton
              onClick={() => navigate(`/history/update/${row?._id}`)}
              aria-label="edit"
              style={{ marginRight: "8px", color: "#00C5B9" }}
            >
              <EditIcon />
            </IconButton>
          )}
          {hasPermissionPage("History", "delete") && (
            <IconButton
              onClick={() => {
                setDeleteHistoryId(row._id);
                setOpenDeleteModel(true);
              }}
              aria-label="delete"
              style={{ color: "#00C5B9" }}
            >
              <DeleteIcon />
            </IconButton>
          )}
        </Box>
      ),
    },
  ];

  const { loading, error, data, refetch } = useQuery(GET_HISTORY, {});

  useEffect(() => {
    if (data?.gethistoryData) {
      setList(data.gethistoryData.data);
      setTotal(data.gethistoryData.count);
    }
  }, [data]);

  const debouncedRefetch = useCallback(
    debounce((term: string) => {
      setSearchTerm(term);
      refetch({
        search: term,
        page: 1,
        limit: pagination.pageSize,
      });
    }, 1000),
    [refetch, pagination.page, pagination.pageSize]
  );

  const handleRefetch = (search?: string) => {
    refetch({
      variables: {
        page: pagination.page,
        limit: pagination.pageSize,
        search: search ?? searchTerm,
      },
    });
  };

  const handleDelete = async () => {
    if (!deleteHistoryId) return;
    try {
      await deleteHistory({ variables: { _id: deleteHistoryId } });
      setOpenDeleteModel(false);
      handleRefetch();
      toast.success("History deleted successfully.");
    } catch (error) {
      toast.error("Error deleting history.");
    }
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchTerm(value);
    debouncedRefetch(value);
  };

  const handlePageChange = (newPage: number) => {
    setPagination({ page: newPage, pageSize: pagination.pageSize });
    refetch({
      search: searchTerm,
      page: newPage + 1,
      limit: pagination.pageSize,
    });
  };

  const handlePageSizeChange = (newPageSize: number) => {
    setPagination((prev) => ({ ...prev, pageSize: newPageSize }));
    refetch({
      search: searchTerm,
      page: pagination.page + 1,
      limit: newPageSize,
    });
  };

  const handleClearSearch = () => {
    setSearchTerm("");
    setPagination((prev) => ({ ...prev, page: 1 }));
    refetch({ search: "", page: 1, limit: pagination.pageSize });
  };

  if (loading) return <Loader />;
  if (error) return <ErrorComponent />;

  return (
    <Box p={2}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
        paddingRight={4}
      >
        <Typography variant="h4" color="#00C5B9">
          History
        </Typography>

        <Box display="flex" alignItems="center" gap={2}>
          <TextField
            label="Search"
            variant="outlined"
            size="small"
            value={searchTerm}
            onChange={handleSearchChange}
            sx={{ width: 200 }}
            InputProps={{
              endAdornment: searchTerm && (
                <InputAdornment position="end">
                  <IconButton onClick={handleClearSearch}>
                    <ClearIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          {hasPermissionPage("History", "add") && (
            <Box>
              <CustomButton
                onClick={() => navigate(`${ROUTES.HISTORY_ADD}`)}
                variant="contained"
                className="width: 200px"
              >
                Create
              </CustomButton>
            </Box>
          )}
        </Box>
      </Box>
      <Divider />
      <Box mt={2}>
        <CustomTable
          columns={COLUMNS}
          data={list}
          paginationModel={pagination}
          totalCount={total}
          onPageChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
        />
      </Box>
      {openDeleteModel && (
        <DeleteModel
          open={openDeleteModel}
          onClose={() => setOpenDeleteModel(false)}
          onConfirm={handleDelete}
          message="Are you sure you want to delete this history?"
        />
      )}
    </Box>
  );
};

export default HistoryDataList;
