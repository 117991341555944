import React, { useState, useEffect } from "react";
import { Box, TextField, Typography, Grid, IconButton } from "@mui/material";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { useFormik } from "formik";
import CustomButton from "src/components/CustomButton";
import { useMutation, useQuery } from "@apollo/client";
import { ADD_HISTORY, UPDATE_HISTORY } from "src/graphql/mutations";
import { useParams, useNavigate } from "react-router-dom";
import { GET_HISTORY_BY_ID } from "src/graphql/query";
import Loader from "src/components/Loader";
import styles from "./HIstory.module.css";
import DatePicker from "react-datepicker";
import { GridCloseIcon } from "@mui/x-data-grid";
import { uploadImage } from "src/components/Common/Utils";
import CloseIcon from "@mui/icons-material/Close";
import "react-datepicker/dist/react-datepicker.css";
import RichTextEditor from "src/components/Common/TextEditor";
const ROUTES = {
  HISTORY_ADD: "/history/add",
  HISTORY_UPDATE: "/history/update/:id",
};

const HistoryModal: React.FC = () => {
  const { pathname } = window.location;
  const isEdit = pathname.startsWith("/history/update");
  const { id } = useParams();
  const navigate = useNavigate();
  const [imageUrl, setImageUrl] = useState<string | null>(null);
  const [imageFile, setImageFile] = useState<File | null>(null);
  const currentYear = new Date().getFullYear();
  const [mainImageFile, setMainImageFile] = useState<File | null>(null);
  const [mainImageUrl, setMainImageUrl] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [AddHistory] = useMutation(ADD_HISTORY);
  const [UpdateHistory] = useMutation(UPDATE_HISTORY);

  const removeTypename = (obj: any): any => {
    if (Array.isArray(obj)) {
      return obj.map(removeTypename);
    } else if (obj && typeof obj === "object") {
      const { __typename, ...rest } = obj;
      return Object.keys(rest).reduce((acc: any, key: any) => {
        acc[key] = removeTypename(rest[key]);
        return acc;
      }, {});
    }
    return obj;
  };

  const { data: historyData, loading: fetchingHistroy } = useQuery(
    GET_HISTORY_BY_ID,
    {
      variables: { _id: id },
      skip: !isEdit || !id,
      onCompleted: (data) => {
        if (data?.getHistoryById?.data) {
          const {
            images,
            title,
            description,
            metaTitle,
            metaDescription,
            metaKeywords,
            focus,
            yearsData,
          } = data?.getHistoryById?.data;
          setMainImageUrl(images);
          const cleanedYearsData = removeTypename(yearsData);

          formik.setValues({
            title,
            description,
            metaTitle,
            metaDescription,
            metaKeywords,
            focus,
            yearsData: cleanedYearsData || [
              {
                year: "",
                yearDescription: "",
                image: "",
              },
            ],
          });
          setLoading(false);
        }
      },
      onError: () => {
        toast.error("Failed to fetch history details.");
        setLoading(false);
      },
    }
  );

  useEffect(() => {
    if (pathname === ROUTES.HISTORY_ADD) {
      setLoading(false);
    }
  }, [pathname]);

  const validationSchema = Yup.object().shape({
    title: Yup.string().required("Title is required"),

    description: Yup.string()
      .required("Description is required")
      .min(10, "Description should be at least 10 characters long"),
    metaTitle: Yup.string().required("Meta title is required"),
    metaDescription: Yup.string().required("Meta description is required"),
    focus: Yup.string().required("Focus keyword is required"),
    yearsData: Yup.array()
      .of(
        Yup.object().shape({
          year: Yup.string()
            .required("Year is required")
            .matches(/^\d{4}$/, "Year must be a valid 4-digit number"),
          yearDescription: Yup.string().required(
            "Year description is required"
          ),
          image: Yup.string().required("Year image URL is required"),
        })
      )
      .min(1, "At least one year entry is required"),
  });
  const formik = useFormik({
    initialValues: {
      title: "",
      description: "",
      metaTitle: "",
      metaDescription: "",
      metaKeywords: "",
      focus: "",
      yearsData: [
        {
          year: "",
          yearDescription: "",
          image: "",
        },
      ],
    },
    validationSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      const updatedYearsData = await Promise.all(
        values.yearsData.map(async (yearData: any, index: number) => {
          let finalImageUrl = yearData.image;
          if (imageFile) {
            const res = await uploadImage(imageFile, "history", values.title);
            finalImageUrl = res?.uploadImage?.url;
          }
          return {
            ...yearData,
            year: yearData.year.toString(),
            image: finalImageUrl,
          };
        })
      );

      const cleanedYearsData = removeTypename(updatedYearsData);

      values.yearsData = cleanedYearsData;
      let historyMainImageUrl = mainImageUrl;
      if (mainImageFile) {
        const res = await uploadImage(mainImageFile, "history", values.title);
        historyMainImageUrl = res?.uploadImage?.url;
      }
      setLoading(true);
      try {
        const variables = {
          _id: isEdit ? id : undefined,
          input: {
            ...values,
            images: historyMainImageUrl,
            yearsData: cleanedYearsData,
          },
        };

        const mutation = isEdit ? UpdateHistory : AddHistory;
        await mutation({ variables });

        toast.success(
          isEdit
            ? "History updated successfully!"
            : "History added successfully!"
        );
        navigate("/history");
      } catch (error: any) {
        toast.error(
          error.message || "An error occurred while saving the history."
        );
      } finally {
        setLoading(false);
      }
    },
  });

  if (loading || fetchingHistroy) {
    return <Loader />;
  }

  const addYearData = () => {
    const newEntry = { year: "", yearDescription: "", image: "" };
    formik.setFieldValue("yearsData", [...formik.values.yearsData, newEntry]);
  };

  const removeYearData = (index: number) => {
    const updatedYearsData = formik.values.yearsData.filter(
      (_, i) => i !== index
    );
    formik.setFieldValue("yearsData", updatedYearsData);
  };

  const handleImageChange = async (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const file = e.target.files?.[0] || null;
    if (file) {
      const previewUrl = URL.createObjectURL(file);
      formik.setFieldValue(`yearsData[${index}].image`, previewUrl);

      try {
        const res = await uploadImage(file, "history", formik.values.title);
        const uploadedImageUrl = res?.uploadImage?.url;
        formik.setFieldValue(`yearsData[${index}].image`, uploadedImageUrl);
      } catch (error) {
        console.error("Image upload failed", error);
        toast.error("Failed to upload image. Please try again.");
      }
    }
  };

  const handleMainImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0] || null;
    if (file) {
      setMainImageFile(file);
      setMainImageUrl(URL.createObjectURL(file));
    }
  };

  return (
    <Box overflow={"scroll"} paddingX={20}>
      <Box className={styles.container}>
        <Typography variant="h4" className={styles.header}>
          {isEdit ? "Update History" : "Create History"}
        </Typography>
      </Box>

      <form onSubmit={formik.handleSubmit}>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          <TextField
            fullWidth
            label="Title"
            variant="outlined"
            name="title"
            value={formik.values.title}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.title && Boolean(formik.errors.title)}
            helperText={formik.touched.title && (formik.errors.title as string)}
          />
          <Box className={styles.imageContainer}>
            <input
              type="file"
              onChange={handleMainImageChange}
              style={{ display: "none" }}
              id="blog-image-upload"
            />
            <label htmlFor="blog-image-upload">
              {mainImageUrl ? (
                <>
                  <img
                    src={mainImageUrl}
                    alt="Blog"
                    className={styles.imagePreview}
                  />
                  <IconButton
                    className={styles.closeIcon}
                    onClick={() => {
                      setMainImageUrl(null);
                      setMainImageFile(null);
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </>
              ) : (
                <Typography color="primary">Upload Image</Typography>
              )}
            </label>
          </Box>
          <Box mb={3}>
            <Typography>Description:</Typography>
            <RichTextEditor
              value={formik.values.description}
              onChange={(content: string) =>
                formik.setFieldValue("description", content)
              }
              className={styles.descriptionWrapper}
            />
            {formik.touched.description && formik.errors.description && (
              <Typography color="error">{formik.errors.description}</Typography>
            )}
          </Box>

          <Box mb={3}>
            <TextField
              label="Meta Title"
              name="metaTitle"
              value={formik?.values?.metaTitle}
              onChange={formik.handleChange}
              fullWidth
              variant="outlined"
              error={
                formik.touched.metaTitle && Boolean(formik.errors.metaTitle)
              }
              helperText={
                formik.touched.metaTitle && (formik.errors.metaTitle as string)
              }
            />
          </Box>
          <Box mb={3}>
            <TextField
              type="text-area"
              label="Meta Description"
              name="metaDescription"
              value={formik?.values?.metaDescription}
              onChange={formik.handleChange}
              fullWidth
              variant="outlined"
              multiline
              rows={4}
              error={
                formik.touched.metaDescription &&
                Boolean(formik.errors.metaDescription)
              }
              helperText={
                formik.touched.metaDescription &&
                (formik.errors.metaDescription as string)
              }
            />
          </Box>
          <Box mb={3}>
            <TextField
              type="text-area"
              label="Meta Keywords"
              name="metaKeywords"
              value={formik?.values?.metaKeywords}
              onChange={formik.handleChange}
              fullWidth
              variant="outlined"
              multiline
              rows={4}
              error={
                formik.touched.metaKeywords &&
                Boolean(formik.errors.metaKeywords)
              }
              helperText={
                formik.touched.metaKeywords &&
                (formik.errors.metaKeywords as string)
              }
            />
          </Box>
          <Box mb={3}>
            <TextField
              type="text-area"
              label="Focus Keywords"
              name="focus"
              value={formik?.values?.focus}
              onChange={formik.handleChange}
              fullWidth
              variant="outlined"
              multiline
              rows={4}
              error={formik.touched.focus && Boolean(formik.errors.focus)}
              helperText={
                formik.touched.focus && (formik.errors.focus as string)
              }
            />
          </Box>
          <Typography variant="h4" gutterBottom>
            Add Historical Years
          </Typography>

          {formik.values.yearsData.map((yearData: any, index: any) => (
            <Box
              key={index}
              mb={3}
              sx={{ borderBottom: "1px solid #ddd", pb: 2 }}
            >
              <Box mb={2}>
                <Typography>Description:</Typography>
                <RichTextEditor
                  value={yearData.yearDescription}
                  onChange={(content: string) =>
                    formik.setFieldValue(
                      `yearsData[${index}].yearDescription`,
                      content
                    )
                  }
                  className={styles.descriptionWrapper}
                />
                {formik.touched.yearsData?.[index]?.yearDescription &&
                  formik.touched.yearsData?.[index]?.yearDescription && (
                    <Typography color="error">
                      {formik.touched.yearsData?.[index]?.yearDescription}
                    </Typography>
                  )}
              </Box>
              <Grid container spacing={2} columns={16}>
                <Grid item xs={8}>
                  <Box className={styles.imageContainer}>
                    <input
                      type="file"
                      onChange={(e) => handleImageChange(e, index)}
                      style={{ display: "none" }}
                      id={`year-image-upload-${index}`}
                    />
                    <label htmlFor={`year-image-upload-${index}`}>
                      {yearData.image ? (
                        <>
                          <img
                            src={yearData.image || imageUrl}
                            alt={`Year ${index + 1}`}
                            className={styles.imagePreview}
                          />
                          <IconButton
                            className={styles.closeIcon}
                            onClick={() => {
                              formik.setFieldValue(
                                `yearsData[${index}].image`,
                                ""
                              );
                            }}
                          >
                            <GridCloseIcon />
                          </IconButton>
                        </>
                      ) : (
                        <Typography color="primary">Upload Image</Typography>
                      )}
                    </label>
                  </Box>
                </Grid>
                <Grid item xs={8}>
                  <Box>
                    <Typography gutterBottom>Select Year</Typography>
                    <DatePicker
                      selected={
                        yearData.year ? new Date(yearData.year, 0) : null
                      }
                      onChange={(date: any) => {
                        formik.setFieldValue(
                          `yearsData[${index}].year`,
                          date ? date.getFullYear() : ""
                        );
                      }}
                      showMonthDropdown={false}
                      dateFormat="yyyy"
                      showYearPicker
                      maxDate={new Date(currentYear, 11, 31)}
                      className={
                        formik.touched.yearsData?.[index]?.year
                          ? "error-class"
                          : ""
                      }
                    />
                  </Box>
                </Grid>
              </Grid>

              <Box display="flex" justifyContent="flex-end">
                <button
                  type="button"
                  onClick={() => removeYearData(index)}
                  disabled={formik.values.yearsData.length === 1}
                  style={{
                    color: "red",
                    border: "none",
                    background: "none",
                    cursor: "pointer",
                    padding: 0,
                  }}
                >
                  Remove
                </button>
              </Box>
            </Box>
          ))}

          <Box>
            <button
              type="button"
              onClick={addYearData}
              style={{
                color: "#007bff",
                border: "1px solid #007bff",
                background: "none",
                cursor: "pointer",
                padding: "6px 12px",
                borderRadius: "4px",
              }}
            >
              Add Year
            </button>
          </Box>

          <Box>
            <CustomButton type="submit">
              {isEdit ? "Update History" : "Add History"}
            </CustomButton>
          </Box>
        </Box>
      </form>
    </Box>
  );
};

export default HistoryModal;
